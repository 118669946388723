<main id="main">
    <section class="congrats membership-flow membership-review">
      <div class="container">
        <h2 class="heading-3">
          Congratulations!<span>Your membership is official. Welcome to the Silver Mirror family!</span>
        </h2>
  
        <div class="grid details">
          <!--Membership-->
          <div>
            <h3 class="eyebrow">Membership</h3>
  
            <div class="cart-info membership-info">
              <div class="flex-container amount">
                <span>
                  <strong>{{cart?.selectedItems[0]?.item?.name}}</strong>
                  <span class="pill">Monthly Payment (Due Today)</span>
                </span>
  
                <span class="cost">${{cart?.summary?.subtotal / 100}}</span>
              </div>
  
              <div class="flex-container cart-tax cart-total">
                <span class="eyebrow small">Tax</span>
                <p class="small">${{cart?.summary?.taxAmount / 100}}</p>
              </div>
  
              <div *ngIf="cart?.summary?.discountAmount > 0" class="flex-container tax">
                <span class="eyebrow small">Discount</span>
                <p class="small">${{cart?.summary?.discountAmount / 100}}</p>
              </div>
  
              <div class="flex-container cart-total">
                <span class="eyebrow small">Total</span>
                <p class="small">${{cart?.summary?.total / 100}}</p>
              </div>
  
              <!-- <div class="next-billing-date">
                Next billing date: {{nextBilling}}
              </div> -->
            </div>
          </div>
        </div>
  
        <!--Carousel-->
  
        <section class="main-cta">
          <article>
            <h3 class="heading-4">
              Now for the fun part, let's set up your facial appointment!
            </h3>
            <!-- <a class="btn black"  (click)="downloadPDF()">Download PDF</a> &nbsp;&nbsp;&nbsp; -->
            <a class="btn black" href="/booking">Book an Appointment</a>
          </article>
  
          <div class="images-container">
            <div class="images">
              <img
                src="assets/images/DSC02564.jpg"
                alt="An entry into the studio"
              />
              <img
                src="assets/images/membership-purchased-2.png"
                alt="Woman smiling while receiving a dermaplaning facial"
              />
              <img
                src="assets/images/0032-interior.jpg"
                alt="A white room with spa chairs in it"
              />
              <img
                src="assets/images/4Z4A2000.jpg"
                alt="Woman lying on her back, receiving a facial"
              />
            </div>
          </div>
        </section>
  
        <ul class="grid ctas">
          <li>
            <h3 class="heading-6 small icon-first">
              <span class="eyebrow small">SILVER MIRROR SHOP</span>
              The ultimate skincare shop curated by Silver Mirror's industry leading facialists.
            </h3>
  
            <a class="btn-primary" href="https://shop.silvermirror.com/">SHOP NOW</a>
          </li>
  
          <li>
            <h3 class="heading-6 small icon-second">
              <span class="eyebrow small">ASK AIDA</span>
              Tell us your skincare dreams and we'll show you where to glow.
            </h3>
  
            <a class="btn-primary" href="https://silvermirror.com/facial-quiz/">Take the quiz</a>
          </li>
  
          <li>
            <h3 class="heading-6 small icon-third">
              <span class="eyebrow small">SILVER MIRROR BLOG</span>
              Read about skincare advice, routines, product comparison and much more!
            </h3>
  
            <a class="btn-primary" href="https://silvermirror.com/blog/">Learn More</a>
          </li>
        </ul>
      </div>
    </section>
  </main>
  