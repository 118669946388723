import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'locationGroup'
})
export class LocationGroupPipe implements PipeTransform {

  transform(collection: any[], property: string): any[] {
    let groupedCollection = Object.entries(collection.reduce((previous, current)=> {
      const city = current.node.address.city;
      if (previous[city]) {
        previous[city].push(current.node);
      } else {
        previous[city] = [current.node];
      }

        return previous;
    }, {})).map(([key, value]) => ({ key, value }));

    if (groupedCollection.length >= 2) {
      const nyIndex = groupedCollection.findIndex(group => group.key.toLowerCase() === "new york city");
      const dcIndex = groupedCollection.findIndex(group => group.key.toLowerCase() === "washington, dc");
      const miamiIndex = groupedCollection.findIndex(group => group.key.toLowerCase() === "miami");
      
  
      if (nyIndex !== -1 && dcIndex !== -1 && miamiIndex !== -1) {
         
          groupedCollection = [
              groupedCollection[nyIndex],   
              groupedCollection[dcIndex],   
              groupedCollection[miamiIndex] 
          ];
      }
  
      groupedCollection.forEach(group => {
          (group.value as any[]).sort((a, b) => a.name.localeCompare(b.name));
      });
  
      console.log("groupedCollection00", groupedCollection);
  }
  
 
  
    return groupedCollection;
  }
}
