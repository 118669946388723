<main id="main">
  <section class="membership-flow membership-form">
    <div class="container grid">
      <!--Breadcrumbs-->
      <div class="breadcrumbs-container">
        <a class="back-btn" href="#"><span class="accessibility">Back</span></a>

        <ul class="flex-container breadcrumbs">
          <li>
            <a class="eyebrow small" (click)="navigateToOptions()"
              >Select Membership</a
            >
          </li>
          <li class="active">
            <a class="eyebrow small" href="">Sign Agreement</a>
          </li>
          <li><a class="eyebrow small" href="">Review Purchase</a></li>
        </ul>
      </div>
      <!--Hero-->
      <h1 class="heading-4">Membership Form</h1>

      <!--Form-->
      <form [formGroup]="form">
        <!-- <div class="initial underline">
          <p class="x-small">
            I understand that the
            <strong
              >30-Minute and 50-Minute Memberships offered by Silver Mirror
              Facial Bar require a (6) month commitment. Premium Memberships
              require a twelve (12) month commitment</strong
            >. If I cancel before the end of my required commitment or fail to utilize the membership, I will still be charged for the remaining months in my commitment period.
          </p>
          <label class="pill" for="waiver1">
            Initial Here *
            <input
              id="waiver1"
              style="text-transform: uppercase;"
              type="text"
              formControlName="waiver1"
              [ngClass]="{
                'is-invalid': submitted && form.controls.waiver1.errors
              }"
            />
            <div
              class="required"
              *ngIf="submitted && form.controls?.waiver1?.errors"
              class="invalid-feedback"
            >
              <div *ngIf="form.controls?.waiver1?.errors?.required">
                Initials are required
              </div>
            </div>
          </label>
          <p class="xx-small">
            By initialing you recognize the above statement and agree to the
            stated terms.
          </p>
        </div> -->

        <div class="initial underline">
          <p class="x-small">
            I understand that if I do not use a membership facial
            <strong>within 90 days of payment</strong>, the unused facial and its value 
            <strong>will not roll over to any subsequent months</strong>
            and will be forfeited.
          </p>
          <label class="pill" for="waiver2">
            Initial Here *
            <input
              id="waiver2"
              style="text-transform: uppercase;"
              type="text"
              formControlName="waiver2"
              [ngClass]="{
                'is-invalid': submitted && form.controls.waiver2.errors
              }"
            />
            <div
              class="required"
              *ngIf="submitted && form.controls?.waiver2?.errors"
              class="invalid-feedback"
            >
              <div *ngIf="form.controls?.waiver2?.errors?.required">
                Initials are required
              </div>
            </div>
          </label>
          <p class="xx-small">
            By initialing, you recognize the above statement and agree to the
            stated terms.
          </p>
        </div>

        <div class="initial underline">
          <p class="x-small">
            I understand that additional discounts and promotions being run by
            Silver Mirror
            <strong>cannot be applied to the value of the facial</strong>.
            However, they may be applied towards add-ons.
          </p>
          <label class="pill" for="waiver3">
            Initial Here *
            <input
              id="waiver3"
              style="text-transform: uppercase;"
              type="text"
              formControlName="waiver3"
              [ngClass]="{
                'is-invalid': submitted && form.controls.waiver3.errors
              }"
            />
            <div
              class="required"
              *ngIf="submitted && form.controls?.waiver3?.errors"
              class="invalid-feedback"
            >
              <div *ngIf="form.controls?.waiver3?.errors?.required">
                Initials are required
              </div>
            </div>
          </label>
          <p class="xx-small">
            By initialing, you recognize the above statement and agree to the
            stated terms.
          </p>
        </div>

        <div class="initial">
          <p class="x-small">
            I understand that I can
            <strong
              >cancel at any time with a 30-day written notice to SilverMirror</strong
            >
            , submitted through our contact form, prior to my next payment date. (Premium Memberships have different commitment restrictions, as outlined in Exhibit B.)<br/>
          </p>
          <label class="pill" for="waiver4">
            Initial Here *
            <input
              id="waiver4"
              style="text-transform: uppercase;"
              type="text"
              formControlName="waiver4"
              [ngClass]="{
                'is-invalid': submitted && form.controls.waiver4.errors
              }"
            />
            <div
              class="required"
              *ngIf="submitted && form.controls?.waiver4?.errors"
              class="invalid-feedback"
            >
              <div *ngIf="form.controls?.waiver4?.errors?.required">
                Initials are required
              </div>
            </div>
          </label>
          <p class="xx-small">
            By initialing, you recognize the above statement and agree to the
            stated terms.
          </p>
        </div>

        <div class="terms-and-conditions">
          <span class="pill">Terms and Conditions *</span>
          <div class="scroll-container">
            <p class="xx-small">
              By signing below, you (the “Member”) are purchasing a membership in the Silver Mirror Facial Bar Membership Program (the “Program”) offered by Silver Mirror Operations, LLC (“Silver Mirror”) and agree to be bound by this Agreement, including the Terms and Conditions set forth below as Exhibit A, the Description of Services set forth below as Exhibit B, and the rules and regulations of the facilities where the Program is offered (each a “Participating Silver Mirror Facial Bar”), and any other terms and conditions concerning the Program and/or Participating Silver Mirror Facial Bars, which may be included on Silver Mirror’s website at <a href="https://www.silvermirror.com"> www.silvermirror.com</a> during the term of your membership, each of which is incorporated herein by reference and made a material part hereof.
              <br />
              <br />
              For 30-Minute and 50-Minute Memberships, the fee for the Program is $79 and $129, respectively, depending on the type of facial membership you select (the “Monthly Fee”) for the first twelve (12) months, as further described in Exhibit B. 50-Minute Facials are limited to the Acne-Fighting, Anti-Aging, Hyperpigmentation, Sensitive Skin, and Seasonal Hydrating facials. Prices exclude sales tax.
              <br />
              <br />
              For Premium Memberships, the fee for the Program is two hundred ninety-nine dollars ($299) per month. This price excludes sales tax.
              <br />
              <br />
              You hereby authorize Silver Mirror to charge the credit card account you have on file with your Participating Silver Mirror Facial Bar, and then on 1st or 15th each month of your membership as selected above beginning in the next month of your membership in the Program and continuing for the minimum commitment term of your membership and then for each month thereafter until canceled in accordance with this Agreement.
              <br />
              <br />
              You acknowledge receiving and reading a copy of this Agreement together with all exhibits and schedules attached hereto, before signing, and accept and agree to its terms as of the date set forth above. Subject to the Terms and Conditions, this Agreement is for a term of one year and will automatically renew for one additional year until canceled in accordance with this Agreement.  
            </p>
            <br />
            <br />
            <span class="pill">Exhibit A - Terms and Conditions</span>
            <ol>
              <li>
                <p class="x-small"><b>Membership Fee</b></p>
                <p class="xx-small">
                  Your credit card number on file with Silver Mirror will be charged the Monthly Fee at the initiation of your membership, and then on either the 1st or the 15th of each month thereafter based on your selection. You may change the credit card at any time, provided, however, that it is your responsibility to make sure that there is always a valid credit card number on file with Silver Mirror for payment of the Monthly Fee. If there is not a valid credit card number on file with Silver Mirror at the time the Monthly Fee is charged to that card and one is not provided within ten days after the regularly scheduled date for payment, Silver Mirror may exercise any and all rights available to it, whether under this Agreement or under applicable law, which may include, but are not limited to, suspension of your membership in the Program until such payment is made, assessing a late payment fee of $25.00, and/or terminating this Agreement, upon which all unused facials will be forfeited.
                  <br />
                  <br />
                  Neither gift cards nor any promotion or offer may be redeemed to pay any portion of the Membership Fee. The Membership Fee cannot be combined with any other promotion or discounts.
                  <br />
                  <br />
                  If you “no-show” or are over 15 minutes late for an appointment, Silver Mirror has the right to charge your credit card 50% of all services booked, including your scheduled membership facial, at full non-member rates. No-show fees are subject to change. However, you can still use your Monthly Membership Facial during the specified time period. All other policies that apply to non-members, such as the cancellation notice, are also applicable to members.
                  <br />
                  <br />
                </p>
              </li>
              <li>
                <p class="x-small"><b>Membership Term</b></p>
                <p class="xx-small">
                  The initial term of your membership is one year from the Commencement Date (the “Initial Membership Period”); however, you may cancel prior to the end of a Membership Period in accordance with Section 3, below. Your membership will automatically renew for additional one-year periods on the anniversary of the Commencement Date (each an “Additional Membership Period”). 
                  <br />
                  <br />
                </p>
              </li>
              <li>
                <p class="x-small"><b>Cancellation</b></p>
                <p class="xx-small">
                  You may cancel your membership in the Program after the applicable commitment period associated with your membership level (if any) at least thirty (30) days prior to your next Membership Fee payment day by providing written notice of cancellation at <a href="https://silvermirror.com/faq/memberships">silvermirror.com/faq/memberships</a>. Cancellation will take effect in the month following your written notice and your current Monthly Fee will not be refunded. 
                  <br />
                  <br />
                  Any and all notices of cancellation must be accompanied by the membership cards and any other documents or evidence of membership previously delivered to you. No cancellation will eliminate any financial obligations owed by you to Silver Mirror prior to the effective date of such cancellation. By canceling your membership, you are not entitled to a refund of any amounts previously paid to Silver Mirror under this Agreement. No refunds will be given for any benefits which you have accrued but have not used as of the time your cancellation becomes effective, including by way of example, but not of limitation, any Monthly Membership Facials, sessions, or services that you would otherwise be entitled to as a Member.
                  <br />
                  <br />
                  Notwithstanding the foregoing, if you are offered and elect to accept a “stay offer,” such as three free add-ons or a switch to an every-other-month billing cycle, you agree that you may not cancel your membership in the Program until you have received and paid in full three bills following such acceptance. 
                  <br />
                  <br />
                </p>
              </li>
              <li>
                <p class="x-small"><b>Closings</b></p>
                <p class="xx-small">
                  From time to time, a part of, or the entirety of, a Participating Silver Mirror Facial Bar may be temporarily unavailable to Members for reasons including but not limited to repairs, renovations or special events. Silver Mirror will make every effort to minimize any disruption to Members during these periods. Some or all of the Participating Silver Mirror Facial Bars may be closed for holidays. Hours of operation are displayed in each Participating Silver Mirror Facial Bar and may be modified from time to time. Such closures or unavailability will not entitle you to a refund.
                  <br />
                  <br />
                </p>
              </li>
              <li>
                <p class="x-small"><b>Rules and Regulations</b></p>
                <p class="xx-small">
                  You agree to follow all rules and regulations of each Participating Silver Mirror Facial Bar you visit (collectively, the “Rules”). Violation of the Rules may result in suspension or cancellation of your membership. Silver Mirror, in its sole discretion, has the right to refuse your entry into a Participating Silver Mirror Facial Bar or to discontinue service at any time, whether for the day or permanently if, in Silver Mirror’s sole judgment, you have failed to observe the terms of this Agreement, the Rules or if you have acted in a manner contrary to the best interests or safety of Silver Mirror or its members. You agree to comply with all applicable laws in connection with your use of each Participating Silver Mirror Facial Bar. You also agree not to use the Participating Silver Mirror Facial Bar, services or equipment in such a way as to endanger the health or safety of yourself or others. You are responsible for any and all property damage or personal injury caused by you, your family or your guests.
                  <br />
                  <br />
                </p>
              </li>
              <li>
                <p class="x-small"><b>Transferability; Temporary Hold; Conversion</b></p>
                <p class="xx-small">
                  Your membership in the Program is personal to you but a facial credit may be transferred or otherwise assigned to any other individual twice per each year of your membership. In addition, Members may, no more often than once in any given Membership Period, place their membership on hold for a period now to exceed two billing cycles by making a written request at <a href="https://silvermirror.com/faq/memberships">silvermirror.com/faq/memberships</a> — a hold will not be active until confirmed in writing by Silver Mirror. You may convert a membership voucher for a facial into store credit for the same value you paid for the voucher for the sole purpose of purchasing retail products in a Silver Mirror store; provided, however, that such converted funds will not receive any Program or Member’s discounts on products.
                  <br />
                  <br />
                </p>
              </li>
              <li>
                <p class="x-small"><b>Modifications</b></p>
                <p class="xx-small">
                  SUBJECT TO YOUR RIGHTS TO CANCEL THIS AGREEMENT SET FORTH HEREIN, SILVER MIRROR MAY MODIFY THE SERVICES OFFERED AT A PARTICIPATING SILVER MIRROR FACIAL BAR OR THE TERMS AND CONDITIONS OF THIS AGREEMENT AT ANY TIME WITHOUT NOTICE AND SUCH MODIFICATIONS WILL BE DEEMED EFFECTIVE IMMEDIATELY UPON MAKING SUCH CHANGES. THE AMOUNT OF THE MONTHLY FEE WILL NOT CHANGE DURING THE INITIAL MEMBERSHIP PERIOD.
                  <br />
                  <br />
                </p>
              </li>
              <li>
                <p class="x-small"><b>Contact Information</b></p>
                <p class="xx-small">
                  For purposes of identification and billing, you agree to provide us with current, accurate, complete and updated information including your name, address, telephone number, and email. You agree to notify Silver Mirror promptly of any changes to any of this information by giving written notice in the manner described below. Failure to provide updated contact information may result in you not receiving important notices. 
                  <br />
                  <br />
                </p>
              </li>
              <li>
                <p class="x-small"><b>Notices</b></p>
                <p class="xx-small">
                  Except notices regarding your cancellation of this Agreement, which notices must be provided in the manner described in Section 3 of this Agreement, all notices required to be made or otherwise made under this Agreement by you to Silver Mirror should be notified via the contact form at <a href="https://silvermirror.com/faq/memberships">silvermirror.com/faq/memberships</a>. To the maximum extent permitted by applicable law, you consent to receiving notices from Silver Mirror by electronic mail. Silver Mirror will use the email address, <a href="mailto:memberships@silvermirror.com">memberships&#64;silvermirror.com</a>, to send any and all such notices. Silver Mirror will send notices to the email address you provided when you signed up for membership. It is your responsibility to ensure that the email address provided to Silver Mirror is at all times operational for you to receive notices.
                  <br />
                  <br />
                </p>
              </li>
              <li>
                <p class="x-small"><b>Agreement to Arbitrate and Waiver of Class or Representative Proceedings; Governing Law; Venue; Jury Trial Waiver; Limitation of Liability</b></p>
                <p class="xx-small">
                  Any dispute or claim between you and Silver Mirror or any affiliate or parent company of Silver Mirror relating in any way to this Agreement or any services or products provided by Silver Mirror, whether through this Agreement or otherwise, shall be resolved by individual binding arbitration by a single arbitrator, except that you or Silver Mirror may assert claims in small claims court if those respective claims qualify. The arbitration shall be administered by the American Arbitration Association in accord with its Commercial Arbitration Rules and Mediation Procedures. The arbitration will take place in New York, NY. You and Silver Mirror agree to waive any right to pursue any dispute relating to this Agreement or any services or products provided by Silver Mirror in any class, private attorney general, or other representative action or proceeding and the arbitrator shall not have the power to conduct a class action arbitration or to arbitrate any claim in which Member or Silver Mirror serve as a private attorney general or in any other representative capacity. If any portion of this arbitration agreement is determined to be invalid or unenforceable, the remainder of the arbitration agreement remains in full force and effect. The arbitrator may award damages or other relief, including equitable or injunctive relief, provided for under applicable law. Judgment on the arbitrator’s award may be entered in any court of competent jurisdiction. The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve any dispute relating to the interpretation, applicability, enforceability, or formation of this Agreement.
                  <br />
                  <br />
                  The interpretation, validity and enforcement of this Agreement and all transactions hereunder as to interpretation, enforcement, validity, construction, effect and in all other respects, shall be governed by the laws of the State of New York without regard to its choice of law provisions; provided, however, that the Federal Arbitration Act (“FAA”), to the extent inconsistent, will supersede the laws of such State and govern with respect to the agreement to arbitrate contained herein. The agreement to arbitrate will survive termination of this Agreement. IN THE EVENT THIS AGREEMENT IS FOUND NOT TO BE SUBJECT TO ARBITRATION, YOU AND SILVER MIRROR AGREE AND CONSENT TO THE EXCLUSIVE JURISDICTION OF THE FEDERAL AND STATE COURTS LOCATED IN NEW YORK, NY AND WAIVE ANY OBJECTION BASED ON VENUE OR FORUM NON CONVENIENS WITH RESPECT TO ANY ACTION INSTITUTED THEREIN, AND YOU AND SILVER MIRROR AGREE TO WAIVE ANY RIGHT TO A JURY TRIAL. IN NO EVENT IS SILVER MIRROR LIABLE FOR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, PUNITIVE, OR INCIDENTAL DAMAGES WHATSOEVER ARISING OUT OF THIS AGREEMENT, EVEN IF IT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING IN THIS AGREEMENT, IN NO EVENT WILL SILVER MIRROR’S AGGREGATE LIABILITY ARISING UNDER, WITH RESPECT TO, OR IN CONNECTION WITH THIS AGREEMENT EXCEED THE TOTAL AMOUNTS PAID BY YOU TO SILVER MIRROR DURING THE TWELVE MONTHS PRECEDING THE ACCRUAL OF THE CLAIM. MULTIPLE CLAIMS WILL NOT INCREASE THIS LIMIT. YOU WILL NOT BRING ANY CLAIM BASED ON A PRODUCT OR SERVICE MORE THAN SIX (6) MONTHS AFTER THE CAUSE OF ACTION ACCRUES.
                  <br />
                  <br />
                </p>
              </li>
              <li>
                <p class="x-small"><b>Non-Waiver; Remedies Cumulative </b></p>
                <p class="xx-small">
                  Failure or delay by any party hereto to enforce any of the provisions of this Agreement or any rights with respect hereto, or the failure to exercise any option provided hereunder, will in no way be considered a waiver of such provisions, rights or options, or to in any way affect the validity of this Agreement. Silver Mirror’s rights and remedies under this Agreement are cumulative with and in addition to any other rights and remedies provided by applicable law.
                </p>
              </li>
            </ol>
            <span class="pill">Exhibit B - Description of Services: Membership Benefits</span>
            <ol>
              <li>
                <p class="x-small"><b>Membership Benefits</b></p>
                <p class="xx-small">
                  During the term of your membership in the Program, you will be entitled to receive the following benefits:
                </p>
                <ul>
                  <li>
                    <p class="xx-small">
                      As of the Commencement Date and upon payment of the first Monthly Membership Fee, and each Monthly Membership Fee thereafter described below, (plus applicable sales tax), you will be entitled to receive one facial each calendar month. Your credit card will be charged the Monthly Membership Fee based on the Membership you selected. That charge will entitle you to receive one facial per calendar month, based on your Membership type.  
                    </p>
                  </li>
                  <li>
                    <p class="xx-small">Monthly Membership Facial Fee:</p>
                    <ul>
                      <li><p class="xx-small">Signature Facial Membership: $79 / month (Normally $99)</p></li>
                      <li><p class="xx-small">Acne-Fighting Facial Membership: $129 / month (Normally $159) </p></li>
                      <li><p class="xx-small">Anti-Aging Facial Membership: $129 / month (Normally $159) </p></li>
                      <li><p class="xx-small">Hyperpigmentation Facial Membership: $129 / month (Normally $159)</p></li>
                      <li><p class="xx-small">Sensitive Skin Facial Membership: $129 / month (Normally $159)</p></li>
                      <li><p class="xx-small">Seasonal Hydrating Facial Membership: $129 / month (Normally $159)</p></li>
                    </ul>
                  </li>
                  <li>
                    <p class="xx-small">
                      A credit (i.e., non-discounted, non-Member facial credit) for any Silver Mirror facial within your designated membership category is available at a Participating Silver Mirror Facial Bar. Facials received as facial credits through the Monthly Membership Fee are exempt from additional discounts. This discount can be used for in-store facials only.
                    </p>
                  </li>
                  <li>
                    <p class="xx-small">
                      A discount of 10% off the regular, retail price (i.e., non-discounted) of any non-Silver Mirror Brand skincare products purchased at a Participating Silver Mirror Facial Bar, excluding any single product over three hundred dollars ($300). Specifically, PureLift devices are not included in this offer. This discount can be used for in-store purchases only. Online product purchases from <a href="https://www.silvermirror.com"> www.silvermirror.com</a>, <a href="https://shop.silvermirror.com"> shop.silvermirror.com</a>, or any other website are excluded from member discounts.   
                    </p>
                  </li>
                  <li>
                    <p class="xx-small">
                      A discount of 20% off the regular, retail price (i.e., non-discounted) of any Silver Mirror Brand skincare products purchased at a Participating Silver Mirror Facial Bar.  This discount can be used for in-store purchases only. Online product purchases from <a href="https://www.silvermirror.com"> www.silvermirror.com</a>, <a href="https://shop.silvermirror.com"> shop.silvermirror.com</a>, or any other website are excluded from member discounts.  
                    </p>
                  </li>
                  <li>
                    <p class="xx-small">
                      A discount of 20% off the regular price (as listed in the menu of prices and services at the participating Silver Mirror Facial Bar) for any add-on services in addition to your Monthly Membership Facial or any other facial category (30-Minute or 50-Minute Facials) that the Member receives.
                    </p>
                  </li>
                  <li>
                    <p class="xx-small">
                      Members will be offered gifts, upgrades, and other promotions at the sole discretion of Silver Mirror. Examples include but are not limited to in-store treatment upgrades or add-ons and first notice on upcoming promotions.   
                    </p>
                  </li>
                </ul>
                <p class="x-small"><b>For the avoidance of doubt, in no event will an unused Monthly Membership Facial roll over into the next Monthly Membership Period after 90 days have elapsed from your payment for the Monthly Membership Facial. Upon cancellation of Membership, all facial credits become void.</b></p>
                <br />
                <br />
              </li>
              <li>
                <p class="x-small"><b>Premium Membership Benefits</b></p>
                <p class="xx-small">
                  During the term of your membership in the Program, you will be entitled to receive the following benefits:
                </p>
                <ul>
                  <li>
                    <p class="xx-small">
                      As of the Commencement Date and upon payment of the first Monthly Membership Fee, and each Monthly Membership Fee thereafter described below, (plus applicable sales tax), you will be entitled to receive one facial each calendar month. Your credit card will be charged two hundred ninety-nine ($299) dollars. That charge will entitle you to receive one 50-Minute facial per calendar month.   
                    </p>
                  </li>
                  <li>
                    <p class="xx-small">
                      The choice of one (1) Microchanneling (in cities where it is available) session OR one (1) AHA/BHA Chemical Peel (also known as Deeper Peel) OR one (1) Hydrafacial Signature (in stores where it is available) session per month. Monthly credits for Microchanneling sessions (in cities where it is available) and AHA/BHA Chemical Peels (also known as Deeper Peesl) and Hydrafacial Signature sessions (in stores where it is available) do not roll over to the next month  
                    </p>
                  </li>
                  <li>
                    <p class="xx-small">
                      A discount of 25% off the regular, retail price (i.e., non-discounted, non-Member facial credit) of any Silver Mirror facial purchased at a Participating Silver Mirror Facial Bar. Facials received as part of the Monthly Membership Fee are exempt from this discount. The 25% discount applies only to additional facials and services not covered by the membership credit. This discount can be used for in-store purchases only   
                    </p>
                  </li>
                  <li>
                    <p class="xx-small">
                      One (1) PureLift Face device at the start of your Membership. The device cannot be returned upon opening the package. The color of the device given to Premium Members is at the sole discretion of Silver Mirror.   
                    </p>
                  </li>
                  <li>
                    <p class="xx-small">
                      One (1) 15-Minute Gua-Sha Massage Add-on during your 50-Minute Facial. You are limited to one 15-Minute Gua-Sha Massage Add-on per month when receiving a 50-Minute Facial. Gua-Sha Massage Add-ons do not rollover.  
                    </p>
                  </li>
                  <li>
                    <p class="xx-small">
                      A discount of 15% off the regular, retail price (i.e., non-discounted) of any skincare products purchased at a Participating Silver Mirror Facial Bar, excluding any single product over three hundred dollars ($300). Specifically, PureLift devices are not included in this offer. This discount can be used for in-store purchases only. Online product purchases from <a href="https://www.silvermirror.com"> www.silvermirror.com</a>, <a href="https://shop.silvermirror.com"> shop.silvermirror.com</a>, or any other website are excluded from member discounts. 
                    </p>
                  </li>
                  <li>
                    <p class="xx-small">
                      A discount of 25% off the regular, retail price (i.e., non-discounted) of any Silver Mirror Brand skincare products purchased at a Participating Silver Mirror Facial Bar.  This discount can be used for in-store purchases only. Online product purchases from <a href="https://www.silvermirror.com"> www.silvermirror.com</a>, <a href="https://shop.silvermirror.com"> shop.silvermirror.com</a>, or any other website are excluded from member discounts.   
                    </p>
                  </li>
                  <li>
                    <p class="xx-small">
                      A discount of 25% off the regular price (as listed in the menu of prices and services at the Participating Silver Mirror Facial Bar) of any add-on services in addition to your Monthly Membership Facial or any other facial the Member receives. 
                    </p>
                  </li>
                  <li>
                    <p class="xx-small">
                      Members will be offered gifts, upgrades, and other promotions at the sole discretion of Silver Mirror. Examples include but are not limited to in-store treatment upgrades or add-ons and first notice on upcoming promotions.   
                    </p>
                  </li>
                  <li>
                    <p class="xx-small">
                      The Premium Membership requires a minimum commitment of 9 months. If a member fails to make payments for more than 30 days, Silver Mirror reserves the right to pursue collection of the outstanding payments. The member agrees that any collection fees and legal fees incurred during the collection process will be the responsibility of the member and must be paid in full.  
                    </p>
                  </li>
                </ul>
                <p class="x-small">
                  <b>For the avoidance of doubt, in no event will an unused Monthly Membership Facial roll over into the next Monthly Membership Period after 90 days have elapsed from your payment for the Monthly Membership Facial. Upon cancellation of Membership, all facial credits become void.</b>
                </p>
              </li>
            </ol>
          </div>
          <label class="pill checkbox" for="accept-terms-and-conditions">
            <input
              id="accept-terms-and-conditions"
              type="checkbox"
              formControlName="terms"
            />
            I accept the terms and conditions
          </label>
          <div class="pill">
            <div
              *ngIf="submitted && form.controls?.terms?.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="form.controls?.terms?.errors?.required"  >
                Accept the terms and conditions  is required.
              </div>
            </div>
          </div>
        </div>

        <div class="acceptance-and-signature">
          <label class="pill" for="acceptance-and-signature">
            Acceptance and Signature *
            <input
              id="acceptance-and-signature"
              type="text"
              formControlName="signature"
              [ngClass]="{
                'is-invalid': submitted && form.controls.signature.errors
              }"
            />
            <div
              class="required"
              *ngIf="submitted && form.controls?.signature?.errors"
              class="invalid-feedback"
            >
              <div *ngIf="form.controls?.signature?.errors?.required">
                Signature is required
              </div>
            </div>
          </label>
          <p class="xx-small">Electronic Signature</p>
          <label class="pill checkbox" for="electronic-signature">
            <input
              id="electronic-signature"
              type="checkbox"
              formControlName="signatureCheck"
            />
            I understand that checking this box constitutes a legal signature
            confirming that I understand and agree to the terms of this
            agreement. *
          </label>
          <div class="pill">
            <div
              *ngIf="submitted && form.controls?.signatureCheck?.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="form.controls?.signatureCheck?.errors?.required">
                Signature confirmation is required
              </div>
            </div>
          </div>
        </div>

        <div class="date-of-signature">
          <label class="pill" for="date-of-signature">
            Date of Signature *
            <input
              type="date"
              placeholder="mm-dd-yyyy"
              formControlName="signatureDate"
            />
          </label>
          <p class="xx-small">Enter date or select from calendar.</p>
        </div>

        <a class="btn black" (click)="submitAgreementForm()">Submit</a>
      </form>
    </div>
  </section>
</main>
