import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'location'
})
export class LocationPipe implements PipeTransform {


  transform(locations: any[]): any[] {

   
    if (locations.length) {
      const returnedLocations = locations.map((location: any) => {
        if (location.node?.address) {
          let state = location.node?.address?.city;
          const name = location.node.name;

          // Update state based on the city
          if (state === "New York City") {
            state = "NY";
          } else if (state === "Washington, DC") {
            state = "DC";
          } else {
            state = "MIA";
          }

          // Prefix state to the location name if not already present
          if (!name.startsWith(state + ' - ')) {
            location.node.name = state + ' - ' + name;
          }
        }
        return location;
      });

      // Custom sort based on desired order and then alphabetically
      const order = ['NY', 'DC', 'MIA'];
      returnedLocations.sort((a, b) => {
        const aState = a.node.name.split(' - ')[0];
        const bState = b.node.name.split(' - ')[0];
        const stateComparison = order.indexOf(aState) - order.indexOf(bState);
        
        // If states are the same, sort alphabetically by the rest of the name
        if (stateComparison === 0) {
          return a.node.name.localeCompare(b.node.name);
        }
        
        return stateComparison;
      });

      //console.log("returnedLocations", returnedLocations);
      return returnedLocations;
    } else {
      return locations;
    }
 }

}
