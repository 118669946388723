

	<a class="back-btn" (click)="navigateBack()"><span class="accessibility">Back</span></a>

	<ul class="flex-container breadcrumbs">
		<li [ngClass]="getRouteState(steps[0].transition)"><a class="eyebrow small" [routerLink]="steps[0].transition > 1 ? '/nutrition/services' : null">Services</a></li>
		<li [ngClass]="getRouteState(steps[1].transition)"><a class="eyebrow small" [routerLink]="steps[1].transition > 1 ? '/nutrition/location' : null">Location</a></li>
		<li [ngClass]="getRouteState(steps[2].transition)"><a class="eyebrow small" [routerLink]="steps[2].transition > 1 ? '/nutrition/schedule' : null">Scheduling</a></li>
		<li [ngClass]="getRouteState(steps[3].transition)"><a class="eyebrow small" [routerLink]="steps[3].transition > 1 ? '/nutrition/review' : null">Review</a></li>
	</ul>
