import { Component, Input } from '@angular/core';
import { NotificationService, Notification } from 'src/app/notification.service';
import { ToastrService } from "ngx-toastr";
import { SharedService } from '../shared.service';
import { DisadleContinueService } from 'src/app/disadle-continue.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  @Input() message!:string;
  @Input() title!:string;
  @Input() toastId!: any
  
  constructor(private toastrService:ToastrService, private disadleContinueService:DisadleContinueService) { }

  closeToast() {
    this.disadleContinueService.setDisableContinue(false); 
    this.toastrService.remove(this.toastId);
  }
}
