import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { AuthService } from "../../auth/auth.service";
import { SharedService } from "src/app/shared-component/shared.service";
import { MembershipService } from "../membership.service";
import { BookingService } from "src/app/booking/booking.service";

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent {

  activeTab = 0;
  form!: FormGroup;
  loading = false;
  submitted = false;
  authUser: {
    firstName?: string;
    lastName?: string;
    email?: string;
    mobilePhone?: string;
  } = {};

  constructor(
    public membershipService: MembershipService,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private router: Router,
    public bookingService:BookingService,
  ) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'  
    });
    membershipService.clientCart$.subscribe((cart: any) => {
      if(!cart){
        this.router.navigateByUrl("/membership")
      }
    });
    this.authService.$AuthUser.subscribe((user: any) => {
      this.authUser = user;
    });
  }
  checkboxRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === true ? null : { required: true };
    }
  } 
  ngOnInit() {
    this.form = this.formBuilder.group({
     /*  waiver1: ["", Validators.required], */
      waiver2: ["", Validators.required],
      waiver3: ["", Validators.required],
      waiver4: ["", Validators.required],
      terms: [false, [Validators.required, this.checkboxRequiredValidator()]],  // Updated
      signature: ["", Validators.required],
      signatureCheck: [false, [Validators.required, this.checkboxRequiredValidator()]],  // Updated
      signatureDate: ["", Validators.required],
    });
    this._patchFormData();
  }

  _patchFormData() {
    let membershipAgreement: any = localStorage.getItem('membershipAgreement');
    if (membershipAgreement) {
      membershipAgreement = JSON.parse(membershipAgreement);
      this.form.patchValue(membershipAgreement);
    }

  }

  navigateToPayment() {
    this.router.navigateByUrl("/membership/payment");
  }
  navigateToOptions() {
    this.router.navigateByUrl("/membership/selection");
  }
  navigateToAgreement() {
    this.router.navigateByUrl("/membership/agreement");
  }

  submitAgreementForm() {
    this.bookingService.ngxUiLoaderService.start();
    this.submitted = true;

    let formValue = this.form.value;

    if (this.form.invalid) {
      this.bookingService.ngxUiLoaderService.stop();
      const message =
        "Please fill the correct information in the agreement form.";
      this.sharedService.showNotification("Invalid Data Provided", message);
      return;
    }

    if (
      /* this.form.value.waiver1.toUpperCase() !=
      this.form.value.waiver2.toUpperCase() || */
      this.form.value.waiver2.toUpperCase() !=
      this.form.value.waiver3.toUpperCase() ||
      this.form.value.waiver3.toUpperCase() !=
      this.form.value.waiver4.toUpperCase()
    ) {
      this.bookingService.ngxUiLoaderService.stop();
      this.sharedService.showNotification(
        "Invalid Data Provided",
        "Your initials do not match to each other"
      );
      return;
    }
    localStorage.setItem('membershipAgreement', JSON.stringify({ ...formValue }));
    this.bookingService.ngxUiLoaderService.stop();
    this.navigateToPayment();
  }

  getFormValidationErrors() {
    let errors: string[] = [];
    Object.keys(this.form.controls).forEach((key) => {
      const controlErrors = this?.form?.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          errors.push(
            "Key control: " +
            key +
            ", keyError: " +
            keyError +
            ", err value: " +
            controlErrors[keyError]
          );
        });
      }
    });
    return errors;
  }
}
