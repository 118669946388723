import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, finalize, forkJoin, map, Observable, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { BookingService } from '../booking/booking.service';
import { SharedService } from '../shared-component/shared.service';
import { Router } from '@angular/router';
import { DashboardService } from './dashboard.service';
import * as moment from "moment";
import { environment } from "src/environments";
import { ADDON_CATEGORIES } from '../constants/constants';

const BASE_URL = environment.apiBaseURL; 

@Injectable({
  providedIn: 'root'
})
export class RebookService {

    $myAppointments:BehaviorSubject<any> = new BehaviorSubject([]);
    $lastAppointment:BehaviorSubject<any> = new BehaviorSubject([]);
    authUser:any;
    
    constructor(private dashboardService:DashboardService, private httpClient: HttpClient, private authService:AuthService, private bookingService:BookingService, private sharedService: SharedService, private router: Router){
      authService.$AuthUser.subscribe(user=>{
        this.authUser = user;
      });
    }

    getLastAppointment(){
      if(!this.dashboardService.$myAppointments.value.length){
        forkJoin({
          services: this.dashboardService.getServices(),
          allAppointments: this.dashboardService.getAllAppointments()
        }).pipe(finalize(() => {
          this.getAppointments();
        }))
        .subscribe((res:any)=>{
          this.dashboardService.$allAppointments.next(res.allAppointments);
          this.dashboardService.$servicesList.next(res.services.data.services.edges);
        })
      }else{
        const value = this.dashboardService.$myAppointments.value;
        const lastAppointment = value.length ? value.filter((val:any)=> {
          // return val.node.locationId != environment.giftcard_location_id && val.node.locationId != environment.nutrition_location_id
          return new Date(val.node.startAt) < new Date() && val.node.appointmentServices[0].staff.role.name != 'Dietician'
        }).sort((a:any, b:any) => moment(b.node['startAt']).diff(moment(a.node['startAt'])))[0] : value;
        lastAppointment ? this.$lastAppointment.next([lastAppointment]) : this.$lastAppointment.next([]);
      }
    }

    getAppointments(){
      this.dashboardService.getAppointmentsList().subscribe((res:any)=>{
        if(!res.errors){
          const value = res.data.myAppointments.edges;
          this.dashboardService.$myAppointments.next(value);
          const lastAppointment = value.length ? value.filter((val:any)=> {
            return new Date(val.node.startAt) < new Date() && val.node.appointmentServices[0].staff.role.name != 'Dietician'  
          }).sort((a:any, b:any) => moment(b.node['startAt']).diff(moment(a.node['startAt'])))[0] : null;
          lastAppointment ? this.$lastAppointment.next([lastAppointment]) : this.$lastAppointment.next([]);
        }else{
          const title = 'Information';
          const message = res.errors[0].message;
          this.sharedService.showNotification(title, message);
        }
      })
    }

    rebookAppointment(appointment:any){
      const addonsCategories = ADDON_CATEGORIES;

      const locationId = appointment.locationId

      const baseServices = appointment.appointmentServices.filter((service:any)=> {
        return !addonsCategories.includes(service.service.category.name)
      })

      let services:any = [
        {
          service: appointment.appointmentServices.filter((service:any)=> !addonsCategories.includes(service.service.category.name)),
          addons: appointment.appointmentServices.filter((service:any)=> addonsCategories.includes(service.service.category.name)),
        }
      ]

      if(appointment.guestsAppointment){
        appointment.guestsAppointment.appointments.map((guestApt:any) => {
          let guestData = {
            service: guestApt.appointmentServices.filter((service:any)=> !addonsCategories.includes(service.service.category.name)),
            addons: guestApt.appointmentServices.filter((service:any)=> addonsCategories.includes(service.service.category.name)),
          }
          services.push(guestData);
        });
      }

      this.createCart(locationId).then(res=>{
        this.createGuest(services).then((res:any)=>{
          services.map((service:any, index:number)=>{
            index != 0 ? service.guestId = res[index - 1] : service.guestId = null;
          });
          this.addServices(services).then((res:any)=>{
            this.addAddons(services, res).then((addonRes:any)=>{
              this.updateCart().then(res=>{
                // navigate to /booking/schedule
                this.router.navigateByUrl("/booking/schedule");
              }).catch(err=>{console.log(err);});
            })
          });
        })
        
      })
    }

    createCart(locationId:string){
      return new Promise((resolve, reject)=>{
        this.bookingService.createCart(locationId).subscribe((res:any)=>{
          if(!res.errors){
            this.sharedService.setLocalStorageItem('selectedLocation', locationId);
            this.sharedService.setLocalStorageItem('cartId', res.data.createCart.cart.id);
            resolve(res)
          }else{
            reject();
          }
        }, err=> reject())
      });
    }

    createGuest(guestsServices:any){
      return new Promise((resolve, reject)=>{
        if(guestsServices.length - 1){
          const requests = [];
          for (let index = 0; index < guestsServices.length - 1; index++) {
              requests.push(this.bookingService.createGuest());
          }
          forkJoin(requests).pipe(finalize(() => {
            resolve(true);
          }), tap(() => {
            this.sharedService.setLocalStorageItem('guestSet', 'true');
          }), map((res:any)=>{
            return res[res.length - 1].data.createCartGuest.cart.guests.map((guest:any)=> guest.id);
          })).subscribe(res=> resolve(res));
        }else{
          resolve(null);
        }
      });
    }

    addServices(services:any){
      let requests:any = [];
      services.forEach((service:any) => {
        const payload = {
          id:service.service[0].service.id,
          staffId:null,
          guestId: service.guestId
        }
        requests.push(this.bookingService.addItemInCart(payload));
      });
      return new Promise((resolve, reject)=>{
        forkJoin(requests).pipe(finalize(() => {
          resolve(true);
          }), map((res:any)=>{
            return res[res.length - 1].data.addCartSelectedBookableItem.cart.selectedItems;
          })).subscribe(res=> resolve(res));
      });
    }

    addAddons(services:any, selectedItems:any){
      selectedItems.forEach((selectedItem:any) => {
        services.map((service:any)=>{
          if(service.guestId == selectedItem.guestId){
            service.addons.map((addon:any)=>{
              addon.service.id = 'urn:blvd:ServiceAddon:' + addon.service.id.replace('urn:blvd:Service:', '') + ':' + selectedItem.id.replace('urn:blvd:Service:', '');
            })
          }
        })
      });
      let requests:any = [];
      services.forEach((service:any) => {
        if(service.addons.length){
          service.addons.forEach((addon:any) => {
            const payload = {
              id:addon.service.id,
              staffId:null,
              guestId: service.guestId
            }
            requests.push(this.bookingService.addItemInCart(payload));
          });
        }
      });
      return new Promise((resolve, reject)=>{
        forkJoin(requests).pipe(finalize(() => {
          resolve(true);
          }), map((res:any)=>{
            return res[res.length - 1].data.addCartSelectedBookableItem.cart.selectedItems;
          })).subscribe(res=> resolve(res));
      });
    }

    updateCart(){
      return new Promise((resolve, reject)=>{
        this.bookingService.getCartDetail().subscribe((res:any)=>{
          if(!res.errors){
            this.bookingService.clientCart$.next(res.data.cart);
            resolve(true);
          }else{
            reject();
          }
        }, err=> reject());
      });
    }
}