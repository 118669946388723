import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(private router:Router){}

  getIcon(){
    if(this.router.url.includes('booking')){
      return 'booking-logo-white.svg'
    }else if(this.router.url.includes('nutrition')){
      return 'nutrition-logo-white.svg'
    }else{
      return 'booking-logo-white.svg';
    }
  }

}
