import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, forkJoin } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { SharedService } from '../shared-component/shared.service';
import { environment } from "src/environments";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';

const BASE_URL = environment.apiBaseURL; 
const PAYMENT_API_BASE_URL = environment.paymentApiBaseURL;

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  [x: string]: any;
  activeServicesByLocation$ = new BehaviorSubject<any>(null);
  activeLocationByService$ = new BehaviorSubject<any>([]);

  constructor(private http:HttpClient, private authService:AuthService, private sharedService:SharedService,
    public ngxUiLoaderService: NgxUiLoaderService,public router: Router) { 
    //this.activeServicesByLocation();
  }

  locationList$: BehaviorSubject<any> = new BehaviorSubject([]);
  staffList$: BehaviorSubject<any> = new BehaviorSubject([]);
  clientCart$: BehaviorSubject<any> = new BehaviorSubject([]);
  checkoutBookingResponse$: BehaviorSubject<any> = new BehaviorSubject(null);
  mobileCartView:BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedLocationTab:number=0;
  toggleMobileCartReview:boolean=false;
  bookableTimeId:any='';


  getLocations() {
    return this.http.get(BASE_URL+'/get_locations');
  }
  /* activeLocationFilterByService(serviceId:any){
    this.activeServicesByLocation$.subscribe((res:any)=>{
      let locationIDs = [];
      for (let category of res) {
        if (category.data.business && category.data.business.menuCategories && category.data.business.menuCategories.length > 0) {
            for (let menuCategory of category.data.business.menuCategories) {
                if (menuCategory.items && menuCategory.items.length > 0) {
                    for (let item of menuCategory.items) {
                        if (item.id === serviceId.substring(17) && item.locationRule && item.locationRule.setBookable === true) 
                          {
                            locationIDs.push(category.locationID);
                        }
                    }
                } 
            }
        }
      }
      this.activeLocationByService$.next(locationIDs);
    })
  }
  activeServicesByLocation() {
    this.getLocations().subscribe(
      (res: any) => {
        if (!res.errors) {
          const locationIds = res.data.locations.edges.map((edge: any) => edge.node.id.substring(18));
          const observables = locationIds.map((id: string) => this.http.post(BASE_URL + '/active_services_by_location', { id }));
          this.ngxUiLoaderService.start();
          forkJoin(observables).subscribe(
            (responses: any) => {
              const dataWithLocationID = responses.map((response: { data: any; }, index: number) => ({
                data: response.data,
                locationID: locationIds[index]
              }));
              this.activeServicesByLocation$.next(dataWithLocationID);
              this.ngxUiLoaderService.stop();
            },
            (error: any) => {
              console.error('Error:', error);
              this.ngxUiLoaderService.stop();
              this.sharedService.showNotification('Error', 'An error occurred while fetching active services.');
            }
          );
        } else {
          this.sharedService.showNotification('Error', res.errors[0].message);
        }
      },
      (error: any) => {
        console.error('Error:', error);
        this.sharedService.showNotification('Error', 'An error occurred while fetching locations.');
      }
    );
}
 */

  createCart(locationId:string, clientMessage?: string, discountCode?: string){
    const payload = {
      locationID:locationId,
      clientMessage:clientMessage,
      discountCode:discountCode,
      client_id:this.authService.$AuthUser.value?.authId
    }; 

    return this.http.post(BASE_URL+'/create_cart', payload);
  }

  getCartDetail(cartID?: string){
    const payload = {
      cartID: cartID || this.sharedService.getLocalStorageItem('cartId'),
      clientId: this.authService.$AuthUser.value?.authId
    }; 
    return this.http.post(BASE_URL+'/get_cart_detail',payload);
  }

  createGuest(){
    const guest = {
      "firstName":"",
      "lastName":"",
      "mobileNumber":"",
      "email":"guest@silvermirror.com"
      }
    const payload = {
      cartID: this.sharedService.getLocalStorageItem('cartId'),
      client: guest,
      client_id: this.authService.$AuthUser.value?.authId
    };
    return this.http.post(BASE_URL+'/create_cart_guest',payload);
  }

  removeGuest(guestId:string){
    let payload = {
      cartId:this.sharedService.getLocalStorageItem('cartId'),
      guestId: guestId,
      clientId:this.authService.$AuthUser.value?.authId
    };
    return this.http.post(BASE_URL+'/remove_cart_guest',payload);
  }

  updateCartDetail(){
    const cartId = this.sharedService.getLocalStorageItem('cartId');
    if(cartId){
      this.getCartDetail().subscribe((res:any)=>{
        if(!res.errors){
          this.clientCart$.next(res.data.cart);
        }
      });
    }
  }

  addItemInCart(item:any){
    const payload = {
      "cartId": this.sharedService.getLocalStorageItem('cartId'),
      "itemGuestId": item.guestId,
      "itemId": item.id,
      "itemStaffVariantId": item.staffId,
      "itemOptionIds": item.itemOptionIds
    }
    return this.http.post(BASE_URL+'/add_item_in_cart',payload);
  }

  addProductToCart(item:any){
    const payload = {
      "cartId": item.id || this.sharedService.getLocalStorageItem('cartId'),
      "itemId": item.itemId,
      "clientId": this.authService.$AuthUser.value?.authId,
      "itemDiscountCode": item.itemDiscountCode
    }
    return this.http.post(BASE_URL+'/add_product_to_cart',payload);
  }

  addAddonInCart(item:any){
    const payload = {
      "cartId": this.sharedService.getLocalStorageItem('cartId'),
      "itemGuestId":item.guestId,
      "itemId": item.id,
      "itemOptionIds": item.optionIds
    }
    return this.http.post(BASE_URL+'/add_service_options_in_cart',payload);
  }

  removeItemInCart(itemId:string){
    const payload = {
      "cartId":this.sharedService.getLocalStorageItem('cartId'),
      "itemId": itemId
    }
    return this.http.post(BASE_URL+'/remove_item_in_cart',payload);
  }

  getScheduleDates(locationId:string, lowerRange:string, upperRange:string, staffIDs:Array<string>){
    const payload = {
      "cartID":this.sharedService.getLocalStorageItem('cartId'),
      "locationID":locationId,
      "timeZone":"America/New_York",
      "searchRangeLower":lowerRange,
      "searchRangeUpper":upperRange,
      "staffVariantIds":staffIDs,
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL + '/get_cart_bookable_dates', payload);
  }

  getScheduleTimes(date:string, staffVariantIds:Array<any>){
    const payload = {
      "cartID":this.sharedService.getLocalStorageItem('cartId'),
      "searchDate":date,
      "timeZone":"America/New_York",
      "clientId": this.authService.$AuthUser.value?.authId,
      "staffVariantIds": staffVariantIds
    }
    return this.http.post(BASE_URL + '/get_cart_bookable_times', payload);
  }

  reserveCartItems(bookableTimeId:string){
    const payload = {
      "cartId":this.sharedService.getLocalStorageItem('cartId'),
      "bookableTimeId":bookableTimeId,
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL + '/reserve_cart_bookable_items', payload);
  }
 //Please check if we can remove [March 8 2024]
  getStaffList(){
    const payload = {
      "locationID":this.sharedService.getLocalStorageItem('selectedLocation'),
    }
    return this.http.post(BASE_URL + '/get_staff_by_location', payload);
  }
  getStaffByLocation(locationId:any) {
    const payload = {
      "locationID":locationId,
    }
    return this.http.post(BASE_URL + '/get_staff_by_location', payload);
  }
  getCartStaffVarients(bookableTimeId:string, serviceId:string, locationId:string){
    const payload = {
      "cartId":this.sharedService.getLocalStorageItem('cartId'),
      "bookableTimeId":bookableTimeId,
      "serviceId":serviceId,
      "locationId":locationId,
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL + '/get_cart_staff_variants', payload);
  }

  updateItemInCart(itemId:any, staffId:string | null, guestId?:string | null){
    const payload = {
      cartId:this.sharedService.getLocalStorageItem('cartId'),
      itemGuestId: guestId,
      itemId: itemId,
      clientId: this.authService.$AuthUser.value?.authId,
      itemStaffVariantId: staffId
    };
    return this.http.post(BASE_URL+'/update_item_in_cart',payload);
  }

  tokenizeCard(card:any){
    const tokenize_url = PAYMENT_API_BASE_URL + "/cards/tokenize";
    const payload = {
      "card": {
        "name": card.name,
        "number": card.number,
        "cvv": card.cvv,
        "exp_month": card.expiry.substring(0,2),
        "exp_year": card.expiry.substring(3,7),
        "address_postal_code": card.postal_code
      }
    }
    return this.http.post(tokenize_url,payload);
  }

  addCartPaymentMethod(token: string, cartId?: string){
    const payload = {
      "cartId": cartId || this.sharedService.getLocalStorageItem('cartId'),
      "select":true,
      "token":token
    }
    return this.http.post(BASE_URL+ '/add_cart_card_payment_method',payload);
  }

  updateClientCartInfo(client: any, cartId?: string){
    const payload = {
      "cartId": cartId || this.sharedService.getLocalStorageItem('cartId'),
      "clientInfo":{
        "email": client.email,
        "firstName":client.firstName,
        "lastName":client.lastName,
        "phoneNumber":client.mobilePhone
      },
      "clientNote":client.note
    }
    return this.http.post(BASE_URL+ '/update_cart_client_info',payload);
  }

  addCartOffer(offerCode:string, cartId?: string){
    const payload = {
      "cartId": cartId || this.sharedService.getLocalStorageItem('cartId'),
      "offerCode":offerCode
    }
    return this.http.post(BASE_URL+ '/add_cart_offer',payload);
  }

  removeCartOffer(offerId:string, cartId?:string){
    const payload = {
      "cartId": this.sharedService.getLocalStorageItem('cartId') || cartId,
      "offerId":offerId
    }
    return this.http.post(BASE_URL+ '/remove_cart_offer',payload);
  }

  sendBookingMail(cart:any){
    return this.http.post(BASE_URL+ '/send_booking_mail',cart);
  }

  checkoutCart(cartId?: string){
    const payload = {
      "cartId": cartId || this.sharedService.getLocalStorageItem('cartId'),
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL+ '/checkout_cart',payload);
  }

  takeCartOwnership(){
    const payload = {
      "cartId": this.sharedService.getLocalStorageItem('cartId'),
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL+ '/take_cart_ownership',payload);
  }

  selectPaymentMethod(paymentMethodId:string){
    const payload = {
      "cartId":this.sharedService.getLocalStorageItem('cartId'),
      "paymentMethodId":paymentMethodId,
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL+ '/select_cart_payment_method',payload);
  }

  getAppointmentDetail(aptId:string, cartId:string)
  {
    const payload = { 
      "cartId": cartId,
      "appointmentId": aptId,
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL+ '/appointment_detail',payload);
  }

  dateAppointments(date:string){
    const payload = {
      "date": date,
      "location": this.sharedService.getLocalStorageItem('selectedLocation'),
    }
    return this.http.post(BASE_URL+ '/date_appontments',payload);
  }

  toggleMobileCart() {
    const mobileCartView = this.mobileCartView.value;
    this.mobileCartView.next(!mobileCartView);
  
    if (!mobileCartView) {
      document.body.classList.add('mobile-cart-active');
    } else {
      document.body.classList.remove('mobile-cart-active');
    }
  }
  toggleMobileCartBeboreReview(){
    const mobileCartView = this.mobileCartView.value;
    this.mobileCartView.next(!mobileCartView);
    if (!mobileCartView) {
      document.body.classList.add('mobile-cart-active');
    } else {
      document.body.classList.remove('mobile-cart-active');
    }
    this.router.navigateByUrl('/booking/review');
  }
  addToKlaviyo(payload:any){
    return this.http.post(BASE_URL+'/add_to_klaviyo',payload);
  }

  getAddonsOrdering(serviceID:any){
    const payload = {
      "serviceId": serviceID,
      "locationId": this.sharedService.getLocalStorageItem('selectedLocation')?.replace('urn:blvd:Location:', ''),
    }
    return this.http.post(BASE_URL+'/get_addons',payload);
  }

  referralPurchase(invoice_amount:number){
    const payload = {
      "first_name": this.authService.$AuthUser.value?.firstName,
      "last_name": this.authService.$AuthUser.value?.lastName,
      "email": this.authService.$AuthUser.value?.email,
      "invoice_amount": invoice_amount,
      "user_agent": "Silvermirror (https://booking.silvermirror.com)"
    }
    return this.http.post(BASE_URL+ '/purchase',payload);
  }

  updateReferralLink(referralcorner_url:any){
    const payload = {
      "clientId": "urn:blvd:Client:"+this.authService.$AuthUser.value?.authId,
      "referralcorner_url": referralcorner_url
    }
    return this.http.post(BASE_URL+ '/update_referrel_link',payload);
  }

  invite(){
    const payload = {
      "email": this.authService.$AuthUser.value?.email
    }
    return this.http.post(BASE_URL+ '/invite',payload);
  }

  isMobile(): boolean {
    return window.innerWidth <= 768; 
  }


  async changeLocation(location: any | string) {
     this.sharedService.setLocalStorageItem('selectedLocation', location);

     const itemsForMe = this.clientCart$.value.selectedItems.filter((item: any) => item.guestId === null);
     const oldCartGuests = this.clientCart$.value.guests;

     const itemsForGuests = this.clientCart$.value.selectedItems.filter((item: any) => item.guestId !== null);

     let tasks:any = [];
 
     if (itemsForMe.length > 0) {
       const cartID = await this.createCartOnlocationChange(location);
      //  tasks.push(this.processItemsForMe(itemsForMe, cartID)); // Process items for 'me'
      this.processItems(itemsForMe, null, null);
     }

     if (oldCartGuests.length > 0) {
      let guestPromises:any = [];
        // create all guests
        oldCartGuests.forEach((guest:any) => {
          guestPromises.push(this.createGuest());
        });
        forkJoin(guestPromises).subscribe((res:any)=>{
          const guests = res[res.length-1].data.createCartGuest.cart.guests

          guests.forEach((guest:any, index:number) => {
            this.processItems(itemsForGuests, guest.id, oldCartGuests[index].id);
          });
        });
     }
     this.ngxUiLoaderService.stop();
   }

   async createCartOnlocationChange(location: any): Promise<string> {
    
     return new Promise((resolve, reject) => {
       this.createCart(location).subscribe((resCreateCart: any) => {
         const cartId = resCreateCart.data.createCart.cart.id;
         this.sharedService.setLocalStorageItem('cartId', cartId);
         resolve(resCreateCart.data.createCart.cart.id);
       }, (error: any) => {
         this.ngxUiLoaderService.stop();
         reject(error);
       });
     });
   }
   async createGuestOnlocationChange(): Promise<string> {
     return new Promise((resolve, reject) => {
       this.createGuest().subscribe((resCreateGuest: any) => {
         resolve(resCreateGuest.data.createCartGuest.cart.guests);
       }, error => {
         this.ngxUiLoaderService.stop();
         reject(error);
       });
     });
   }

   processItems(items:any, guest:any, oldGuestId:any){
    let servicePromises:any = [];
    const services = items.filter((item:any)=> !item.item.optionGroups.length && item.guestId == oldGuestId);
    const addons = items.filter((item:any)=> item.item.optionGroups.length && item.guestId == oldGuestId);

    if(services.length){
      services.forEach((service:any) => {
        const payload = {
            id: service.item.id,
            staffId: null,
            guestId: guest
        };
        servicePromises.push(this.addItemInCart(payload));
      });
      forkJoin(servicePromises).subscribe((res:any)=>{
        servicePromises =[];
        let addonPromises:any = [];
        if(addons.length){
          const availableAddons = res[res.length - 1].data.addCartSelectedBookableItem.cart.selectedItems.filter((item:any)=>{return item.guestId == null && !item.item.optionGroups.length})[0].addons;

          addons.forEach((addon:any) => {
            const findAddon = availableAddons.filter((availableAddon:any)=> availableAddon.name.toLowerCase() == addon.item.name.toLowerCase());
            if(findAddon.length){
              const payload = {
                id: findAddon[0].id,
                staffId: null,
                guestId: guest
              };
              addonPromises.push(this.addItemInCart(payload));
            }
          });
          forkJoin(addonPromises).subscribe(()=>{
            addonPromises = [];
            this.updateCartDetail();
          });
        }else{
          servicePromises =[];
          addonPromises = [];
          this.updateCartDetail();
        }

      })
    }
   }

//    async processItemsForMe(itemsForMe: any[], cartID: any): Promise<void> {
//     try {
//         // Fetch cart details once
//         let cartDetail:any='';
//         cartDetail = await this.getCartDetail(cartID).toPromise();

//         // Extract the first two items
//         const firstTwoItems = cartDetail.data.cart.availableCategories;

//         const processPromises = itemsForMe.map((item: any) => {
//             return new Promise<void>((resolve, reject) => {
//                 let found = false;

//                 for (const category of firstTwoItems) {
//                     for (const availableItem of category.availableItems) {
//                         if (item.id === availableItem.id) {
//                             found = true;
//                             const payload = {
//                                 id: item.id,
//                                 staffId: null,
//                                 guestId: null
//                             };

//                             this.addItemInCart(payload).subscribe(
//                                 () => {
//                                     resolve();
//                                 },
//                                 (error: any) => {
//                                     reject(error);
//                                 }
//                             );

//                             break;
//                         }
//                     }
//                     if (found) break;
//                 }

//                 if (!found) {
//                     resolve();
//                 }
//             });
//         });

//         // Await all promises
//         await Promise.all(processPromises);
//     } catch (error) {
//         console.error("Error processing items:", error);
//     }
// }

//  async processItemsForGuest(itemsForGuests: any, guestId: any): Promise<void> {
//      const cartId: any = this.sharedService?.getLocalStorageItem('cartId');
//      const processPromises = itemsForGuests.map((item: any) => {
//          return new Promise<void>((resolve, reject) => {
//              this.getCartDetail(cartId).subscribe((res: any) => {
//                  const firstTwoItems = res.data.cart.availableCategories;
//                  let found = false;
 
//                  for (const category of firstTwoItems) {
//                      for (const availableItem of category.availableItems) {
//                          if (item.id === availableItem.id) {
//                              found = true;
//                              const payload = {
//                                  id: item.id,
//                                  staffId: null,
//                                  guestId: guestId
//                              };
//                              this.addItemInCart(payload).subscribe(() => {
//                                  resolve();
//                              }, (error: any) => {
//                                  reject(error);
//                              });
//                              break;
//                          }
//                      }
//                      if (found) break;
//                  }
 
//                  if (!found) resolve();
//              }, (error: any) => {
//                  reject(error);
//              });
//          });
//      });
 
//      await Promise.all(processPromises);
//  }
 
getClientByEmail(email: string) {
  return this.http.post(BASE_URL + '/get_client_by_email', { emails: [email] });
}

  
}
 