<app-header mainTitle="Gift Cards"></app-header>

<!--Main Content-->
<main id="main">
    <section class="review-flow gift-card-review">
        <form class="container grid">
            <!--Breadcrumbs-->
            <div class="breadcrumbs-container">
                <a class="back-btn" routerLink="/giftcards"> <span class="accessibility">Back</span></a>

                <ul class="flex-container breadcrumbs">
                    <li class="completed"><a class="eyebrow small" routerLink="/giftcards">Gift Card Details</a></li>
                    <li class="active"><a class="eyebrow small" routerLink="/giftcards/review">Review Purchase</a></li>
                </ul>
            </div>

            <app-mobile-menu class="user-head mobile-head"></app-mobile-menu>

            <div class="content-container">
                <!--Additional Fields-->
			    <h2 class="heading-6">Sender Information</h2>
                <p *ngIf="!isLoggedin" class="xx-small"> Have an account? <a class="text-link" routerLink="/auth/login">Login or create an account</a></p>


                <form [formGroup]="userInfoForm">
                    <ul class="grid additional-info">
                        <li>
                            <label class="pill" for="email">Email Address*</label>
                            <input (blur)="onEmailKeyup(userInfoForm.controls.email.value)"  formControlName="email" type="email" id="email" placeholder="email@address.com"/>
                            <div class="required" *ngIf="userInfoForm.controls.email.invalid && userInfoForm.controls.email.touched">
                                <div *ngIf="userInfoForm.controls.email.errors?.required">Email Address is required</div>
                                <div *ngIf="userInfoForm.controls.email.errors?.email">Email is invalid</div>
                            </div>
                        </li>
                        <li>
                            <label class="pill" for="first-name">First Name*</label>
                            <input formControlName="firstName" type="text" id="first-name" placeholder="First Name"/>
                            <div class="required" *ngIf="userInfoForm.controls.firstName.invalid && userInfoForm.controls.firstName.touched">
                                <div *ngIf="userInfoForm.controls.firstName.errors?.required">First name is required</div>
                            </div>
                        </li>
                        
                        <li>
                            <label class="pill" for="last-name">Last Name*</label>
                            <input formControlName="lastName" type="text" id="last-name" placeholder="Last Name"/>
                            <div class="required" *ngIf="userInfoForm.controls.lastName.invalid && userInfoForm.controls.lastName.touched">
                                <div *ngIf="userInfoForm.controls.lastName.errors?.required">Last name is required</div>
                            </div>
                        </li>
                        
                        <li>
                            <label class="pill" for="phone">Phone Number*</label>
                            <input type="tel" mask='(000) 000-0000' formControlName="mobilePhone" id="phone" placeholder="(555) 555-5555"/>
                            <div class="required" *ngIf="userInfoForm.controls.mobilePhone.invalid && userInfoForm.controls.mobilePhone.touched">
                                <div *ngIf="userInfoForm.controls.mobilePhone.errors?.required">Phone Number is required</div>
                            </div>
                        </li>
                        
                       
                    </ul>
                    <div class="mobile-only flex-container promo-code">
                        <label class="pill small" for="promo-code">Promo Code</label>
                        <input [disabled]="cart?.offers?.length" type="text" id="promo-code" [ngModelOptions]="{standalone: true}" [(ngModel)]="promoCode" placeholder="Enter Code" />
                        <button [disabled]="!(promoCode && promoCode != '')" *ngIf="!cart?.offers?.length" (click)="applyPromoCode()" type="submit" class="text-link large">Apply</button>
                        <button *ngIf="cart?.offers?.length" (click)="removePromoCode()" type="submit" class="text-link large">Remove</button>
                    </div>
                </form>	

                <!--Credit Card-->
                <h2 class="heading-6">Credit Card Payment Details</h2>
                <label class="pill" for="card-detail-options" *ngIf="cart?.availablePaymentMethods?.length">Card Details</label>
                <div class="flex-container credit-card-options" *ngIf="cart?.availablePaymentMethods?.length">
                    <ul id="card-detail-options" class="x-small" *ngFor="let card of cart?.availablePaymentMethods">
                        <li *ngIf="card.id && card.cardLast4" [ngClass]="cart?.selectedItems[0]?.selectedPaymentMethod?.id == card?.id ? 'active' : 'inactive' " class="visa" (click)="selectPaymentMethod(card)">
                            <strong>{{card?.cardBrand}} •••• {{card?.cardLast4}} <span>Expires {{card?.cardExpMonth}}/{{card?.cardExpYear}}</span></strong>
                            <span *ngIf="card?.cardIsDefault" class="pill fill small">Default</span>
                        </li>
                    </ul>
                </div>

                <form [formGroup]="paymentForm">

                    <ul class="grid credit-card-details">
                      <li>
                        <label class="pill" for="name">Name On The Card*</label>
                        <input formControlName="name" type="text" id="name" placeholder="Name"/>
                        <div class="required" *ngIf="paymentForm.controls.name.invalid && paymentForm.controls.name.touched">
                                        <div *ngIf="paymentForm.controls.name.errors?.required">Name is required</div>
                                    </div>
                      </li>
                      <li>
                        <label class="pill" for="card-details">Card Details*</label>
                        <input mask='0000 0000 0000 0000 000' [validation]="false" formControlName="number" type="text" id="card-details" placeholder="Card Number"/>
                        <div class="required" *ngIf="paymentForm.controls.number.invalid && paymentForm.controls.number.touched">
                                        <div *ngIf="paymentForm.controls.number.errors?.required">Card number is required</div>
                                        <div *ngIf="paymentForm.controls.number.errors?.minlength || paymentForm.controls.number.errors?.maxlength || paymentForm.controls.number.errors?.invalidCard">Invalid card</div>
                                    </div>
                      </li>
                      
                      <li>
                        <label class="pill" for="card-exp">Expiration Date*</label>
                        <input  maxLength="7" minLength="7" (input)="onExpiryDateInput()" formControlName="expiry" type="text" id="card-exp" placeholder="MM/YYYY"/>
                        <div class="required" *ngIf="paymentForm.controls.expiry.invalid && paymentForm.controls.expiry.touched">
                                        <div *ngIf="paymentForm.controls.expiry.errors?.required">Expiration date is required</div>
                                        <div *ngIf="!paymentForm.controls.expiry.errors?.required && paymentForm.controls.expiry.errors?.invalidExpiry">Expiration date is invalid. (MM/YYYY)</div>
                                    </div>
                      </li>
                      
                      <li *ngIf="paymentForm.controls.number.valid && paymentForm.controls.expiry.valid">
                        <label class="pill" for="card-cvv">CVV*</label>
                        <input maxLength="4" minLength="3" formControlName="cvv" type="tel" id="card-cvv" placeholder="***"/>
                        <div class="required" *ngIf="paymentForm.controls.cvv.invalid && paymentForm.controls.cvv.touched">
                                        <div *ngIf="paymentForm.controls.cvv.errors?.required">CVV is required</div>
                                        <div *ngIf="!paymentForm.controls.cvv.errors?.required && paymentForm.controls.cvv.errors?.invalidCVV">Invalid CVV</div>
                                    </div>
                      </li>
            
                      <li *ngIf="paymentForm.controls.number.valid && paymentForm.controls.expiry.valid">
                        <label class="pill" for="postal-code">Zip Code*</label>
                        <input maxLength="5" minLength="5" formControlName="postal_code" type="text" id="postal-code" placeholder="Zip Code"/>
                        <div class="required" *ngIf="paymentForm.controls.postal_code.invalid && paymentForm.controls.postal_code.touched">
                                        <div *ngIf="paymentForm.controls.postal_code.errors?.required">Zip code is required</div>
                          <div *ngIf="paymentForm.controls.postal_code.errors?.minlength || paymentForm.controls.postal_code.errors?.maxlength">Zip code must be of 5 digits.</div>
                                    </div>
                      </li>
                    </ul>
                    <!-- <button type="submit" class="btn black cc-btn">Apply</button> -->
                    <h3 style="font-weight: bold; margin: 30px 0 2px; font-size: 0.875rem/1.4285714286 var(--body-font);">Disclaimer</h3>
                    <p class="xx-small">
                      Service Tax: Tax is not charged directly to your order. Instead, it is
                    added to the value of your gift card to cover your recipient's local
                    and state service tax. The tax is calculated based on New York City's
                    local and state service tax rates, which are the highest among our
                    Silver Mirror locations. This ensures that all recipients, regardless
                    of their location, will not have to pay additional tax when checking
                    out after their service.
                    </p>
                </form>  
                <div class="continue-btn mobile-only">
                    <span (click)="easyCheckout()"><a class="btn black active">Purchase Gift Card</a></span>
                </div>              
            </div>

            <!--Sidebar-->
            <aside>
                <button class="mobile-only cart-summary flex-container" (click)="toggleMobileCart = !toggleMobileCart" [ngClass]="toggleMobileCart ? 'active' : ''">
                <span class="eyebrow small">Summary</span>
                <span class="cost">{{sharedService.formatPrice(this.cart?.summary?.subtotal) | currency:'USD':'symbol'}}</span>
                </button>

                <div class="cart-info" [ngClass]="toggleMobileCart ? 'active' : ''">
                <h2 class="eyebrow small flex-container desktop-only">
                    Package                    
                    <button class="edit-gift-card" routerLink="/giftcards">
                    <span class="accessibility">Edit Package Options</span>
                    </button>
                </h2>

                <div class="flex-container amount">
                    <span class="small" style="word-break: break-word;">
                    <strong>{{sharedService.getGiftCardName()}}</strong>
                    <!-- <strong>{{this.cart?.selectedItems[0]?.item?.name}}</strong> -->
                    <!-- <span class="pill">Any 50-minute facial</span> -->
                    </span>

                    <span class="cost">{{sharedService.formatPrice(this.cart?.summary?.subtotal) | currency:'USD':'symbol'}}</span>
                </div>

                <div class="flex-container recipient">
                    <span class="label">To <strong>{{cart?.selectedItems[0].emailFulfillment?.recipientName}}</strong></span>
                    <span class="email small">{{cart?.selectedItems[0].emailFulfillment?.recipientEmail}}</span>
                </div>

                <div class="flex-container sender">
                    <!-- <span class="label">From <strong>{{cart?.selectedItems[0].emailFulfillment?.senderName}}</strong></span> -->
                    <span class="label">From <strong>{{this.userInfoForm.value.firstName}}</strong></span>
                    <span class="email small" *ngIf="user">{{user?.email}}</span>
                </div>

                <div class="flex-container sender">
                    <span class="label">Delivery Date <strong>{{cart?.selectedItems[0].emailFulfillment?.deliveryDate | date}}</strong></span>
                    <!-- <span class="email small">{{cart?.selectedItems[0].emailFulfillment?.senderEmail}}</span> -->
                </div>

                <div class="flex-container message">
                    <span class="label">Message</span>
                    <span class="message small"
                    >{{cart?.selectedItems[0].emailFulfillment?.messageFromSender}}</span
                    >
                </div>

                
                <div class="flex-container cart-subtotal cart-total" *ngIf="(cart?.offers?.length && this.cart?.summary?.discountAmount) || (cart?.summary?.taxAmount && cart?.summary?.taxAmount != 0)">
                    <span class="eyebrow small">Subtotal</span>
                    <p class="small">{{sharedService.formatPrice(this.cart?.summary?.subtotal) | currency:'USD':'symbol'}}</p>
                </div>
                <div class="flex-container cart-discount cart-total" *ngIf="cart?.offers?.length && this.cart?.summary?.discountAmount">
                    <span class="eyebrow small">Discount</span>
                    <p class="small">-{{sharedService.formatPrice(this.cart?.summary?.discountAmount) | currency:'USD':'symbol'}}</p>
                </div>
                <div *ngIf="cart?.summary?.taxAmount && cart?.summary?.taxAmount != 0" class="flex-container cart-subtotal cart-total">
                    <span class="eyebrow small">Tax</span>
                    <p class="small">{{sharedService.formatPrice(this.cart?.summary?.taxAmount) | currency:'USD':'symbol'}}</p>
                </div>
                <div class="flex-container cart-total" *ngIf="cart?.selectedItems?.length">
                    <span class="eyebrow small">Total</span>
                    <p class="small">{{sharedService.formatPrice(this.cart?.summary?.total) | currency:'USD':'symbol'}}</p>
                </div>
                </div>

                <div class="desktop-only flex-container promo-code">
                <label class="pill small" for="promo-code">Promo Code</label>
                <input [disabled]="cart?.offers?.length" type="text" id="promo-code" [ngModelOptions]="{standalone: true}" [(ngModel)]="promoCode" placeholder="Enter Code" />
                <button [disabled]="!(promoCode && promoCode != '')" *ngIf="!cart?.offers?.length" (click)="applyPromoCode()" type="submit" class="text-link large">Apply</button>
                        <button *ngIf="cart?.offers?.length" (click)="removePromoCode()" type="submit" class="text-link large">Remove</button>
                </div>

                <span (click)="easyCheckout()"><a class="btn black active desktop-only">Purchase Gift Card</a></span>
            </aside>
        </form>
    </section>
</main>