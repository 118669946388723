import { Component, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { SharedService } from '../../shared-component/shared.service';
import { BookingService } from '../booking.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { TrackingService } from '../tracking.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-congrats',
  templateUrl: './congrats.component.html',
  styleUrls: ['./congrats.component.scss']
})
export class CongratsComponent implements OnDestroy {

  appointment:any;
  cart:any;
  toggleOptions:boolean = false;
  toggleShareOptions:boolean = false;
  authUser:any;
  staff:any;
  checkoutBookingResponseSubscription$:Subscription;

  constructor(private trackingService:TrackingService, private bookingService:BookingService, public sharedService:SharedService, private authService:AuthService, private router:Router){
    this.authService.$AuthUser.subscribe((user:any)=>{
      this.authUser = user;
    })

    this.bookingService.getCartDetail().subscribe((res:any)=>{
      if(!res.errors){
        if(res.data.cart.selectedItems.length == 0){
          window.location.href="/booking/services";
        }
       
      }
    });
   
    this.checkoutBookingResponseSubscription$ = this.bookingService.checkoutBookingResponse$.subscribe((checkoutCart:any)=>{
      if(checkoutCart){
        const aptId = checkoutCart.appointments[0].appointmentId;
        this.cart = checkoutCart.cart
        this.staff = this.cart.selectedItems.filter((selectedItem:any)=>{
          return selectedItem.guestId == null
        })[0]?.selectedStaffVariant?.staff;
        this.getAppointmentDetail(aptId, this.cart.id);
        aptId ? this.injectGTAG(aptId) : null;
      }else{
        window.location.href="/booking/services";
      }
    })
  }

  ngOnDestroy(): void {
      this.checkoutBookingResponseSubscription$.unsubscribe();
  }

  injectGTAG(aptId:string){
    this.trackingService.setUserDetail(this.cart);
    this.trackingService.purchase(this.cart);

   // this.bookingService.referralPurchase((this.cart.summary.total/100)).subscribe();


   this.bookingService.referralPurchase(this.cart.summary.total / 100).subscribe(
    (response: any) => {
      console.log("RESP:::", response);
      let referralcornerUrl = response?.data?.referralcorner_url;
  
      if (referralcornerUrl) {
        // Ensure the URL is fully qualified
        if (referralcornerUrl.startsWith("//")) {
          referralcornerUrl = `https:${referralcornerUrl}`;
        }
  
        console.log("Referral URL:", referralcornerUrl);
  
        // Call updateReferralLink
        this.bookingService.updateReferralLink(referralcornerUrl).subscribe(
          (updateResponse) => {
            console.log("Referral link updated successfully:", updateResponse);
          },
          (error) => {
            console.error("Error updating referral link:", error);
          }
        );
      } else {
        console.warn("No referral link returned:", response);
      }
    },
    (error) => console.error("Error during referral purchase:", error)
  );
  
    
  




    this.bookingService.invite().subscribe();

    (window as any).gtag('event', 'conversion', {
      'send_to': 'AW-1056511307/uq4qCNDxjpECEMuq5PcD',
      'value': Number(this.sharedService.formatPrice(this.cart?.summary?.total)),
      'currency': 'USD',
      'transaction_id': aptId
      });
  }

  getAppointmentDetail(aptId:string, cartId:string){
    this.bookingService.getAppointmentDetail(aptId, cartId).subscribe((res:any)=>{
      if(!res.errors){
        this.appointment = res.data.appointment;
        const cartAppointment = { ...this.cart, ...this.appointment };
        // Optionally, log the result to check
         console.log('Merged Cart:', this.cart);
         // Send the updated cart (with the appointment details) via email
       this.bookingService.sendBookingMail(cartAppointment).subscribe();
      }
    });
  }

  getServicePrice(selectedService:any){
    let optionsPrice = 0;
    selectedService.selectedOptions.map((option:any)=>{
      optionsPrice = optionsPrice + option.priceDelta;
    });
    return selectedService.price - optionsPrice;
  }

  getServicesCount(){
    return this.appointment.appointmentServiceOptions.length + this.appointment.appointmentServices.length;
  }

  shareViaEmail(ev:any) {
    ev.preventDefault();
    const shareVariables = {
      date: moment(this.appointment.startAt.slice(0, -6)).format('MMMM DD, YYYY @ h:mm A'),
      location: this.appointment.location.address.city,
      address: this.appointment.location.address.line1
    }
    
    const subject = 'Booking - Silver Mirror';
    const body = `Booked my facial at Silver Mirror!\n ${shareVariables.date} ${shareVariables.location} ${shareVariables.address}.\n Join me and get 20% off your first facial with promocode FIRST20.\nURL: https://bookings.silvermirror.com`;
    const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
  }

  shareOnTwitter() {
    const shareVariables = {
      date: moment(this.appointment.startAt.slice(0, -6)).format('MMMM DD, YYYY @ h:mm A'),
      location: this.appointment.location.address.city,
      address: this.appointment.location.address.line1
    }

    const tweetText = `booked my facial at Silver Mirror!\n ${shareVariables.date} ${shareVariables.location} ${shareVariables.address} Join me and get 20% off your first facial with promocode FIRST20.\nURL: https://bookings.silvermirror.com`;
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
    window.open(tweetUrl);
  }

  shareOnFacebook(){
    const shareVariables = {
      date: moment(this.appointment.startAt.slice(0, -6)).format('MMMM DD, YYYY @ h:mm A'),
      location: this.appointment.location.address.city,
      address: this.appointment.location.address.line1
    }

    const shareText = `booked my facial at Silver Mirror!\n ${shareVariables.date} ${shareVariables.location} ${shareVariables.address} Join me and get 20% off your first facial with promocode FIRST20.`;

    const shareUrl = 'https://www.facebook.com/sharer/sharer.php';
    const url = encodeURIComponent('https://bookings.silvermirror.com');
    const title = encodeURIComponent(shareText);
    const shareParams = `?u=${url}&description=${title}`;
  
    window.open(shareUrl + shareParams, '_blank');
  }

  viewAppointment(){
    if(!this.authUser){
      this.router.navigateByUrl("/auth/login");
    }else{
      this.router.navigateByUrl("/dashboard/appointments")
    }
  }

  openLink(ev:any, link:any){
    ev.preventDefault();
    window.open(link, '_blank');
  }

  getSelectedStaffVariant(guest:any){
    let selectedStaff:any;
    if(this.cart && this.cart.selectedItems && this.cart.selectedItems.length){
      let selectedItem = this.cart.selectedItems.filter((selectedItem:any)=>{
        return guest ? selectedItem.guestId == guest.id : selectedItem.guestId == null;
      });
      if(selectedItem[0].selectedStaffVariant){
        selectedStaff = selectedItem[0].selectedStaffVariant.staff.firstName+' '+selectedItem[0].selectedStaffVariant.staff.lastName[0]+'.';
      }
    }
    return selectedStaff;
  }

}
