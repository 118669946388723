import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderCartItems'
})
export class OrderCartItemsPipe implements PipeTransform {

  transform(selectedItems: Array<any>): Array<any> {
    if(selectedItems.length){
      selectedItems.sort((a, b) => {
        if (a.guest === null && b.guest === null) {
          return 0;
        }
        if (a.guest === null) {
          return -1;
        }
        if (b.guest === null) {
          return 1;
        }
        const labelA = a.guest.label.toUpperCase();
        const labelB = b.guest.label.toUpperCase();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
      return this.sortServices(this.groupObjectsById(selectedItems));
      // return this.groupObjectsById(selectedItems);
    }else{
      return selectedItems
    }
  }

  sortServices(groupedItems:any){
    groupedItems.map((group:any)=>{
      group.data.sort((a:any, b:any)=> {
        if (a.addons.length > b.addons.length) {
          return -1;
        }
        if (a.addons.length < b.addons.length) {
          return 1;
        }
        return 0;
      });
    })
    return groupedItems;
    // selectedItems.sort((a:any, b:any) => {
      
    //   const labelA = a.guest.label.toUpperCase();
    //   const labelB = b.guest.label.toUpperCase();
    //   if (labelA < labelB) {
    //     return -1;
    //   }
    //   if (labelA > labelB) {
    //     return 1;
    //   }
    //   return 0;
    // });
  }

  groupObjectsById(array:any) {
    const groups:any = {};
    
    for (const obj of array) {
      if (obj.guestId in groups) {
        groups[obj.guestId].data.push(obj);
      } else {
        groups[obj.guestId] = { guestId: obj.guestId, guest: obj.guest, data: [obj] };
      }
    }
    
    return Object.values(groups);
  }

}
