import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BookingRoutingModule } from '../booking/booking.routing.module';
import { SharedComponentModule } from '../shared-component/shared-component.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ServicesComponent } from './services/services.component';
import { NutritionComponent } from './nutrition.component';
import { NutritionRoutingModule } from './nutrition.routing.module';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ServicesTabsComponent } from './services/services-tabs/services-tabs.component';
import { ServicesListComponent } from './services/services-list/services-list.component';
import { ModalServiceDetailComponent } from './services/services-list/modal-service-detail/modal-service-detail.component';
import { FilterServicePipe } from './services/filter-service.pipe';
import { CartComponent } from './cart/cart.component';
import { OrderCartItemsPipe } from './cart/order-cart-items.pipe';
import { SchedulingComponent } from './scheduling/scheduling.component';
import { TimeFilterPipe } from './scheduling/time-filter.pipe';
import { OrderEstheticianPipe } from './scheduling/order-esthetician.pipe';
import { ReviewComponent } from './review/review.component';
import { CongratsComponent } from './congrats/congrats.component';
import { NutritionServiceOrderPipe } from './services/services-list/order.pipe';
import { LocationComponent } from './location/location.component';
import { SimplebarAngularModule } from 'simplebar-angular';



@NgModule({
  declarations: [
    ServicesComponent,
    LocationComponent,
    SchedulingComponent,
    ReviewComponent,
    CongratsComponent,

    CartComponent,
    ServicesListComponent,
    ModalServiceDetailComponent,
    ServicesTabsComponent,
    BreadcrumbsComponent,
    NutritionComponent,

    //PIPES
    FilterServicePipe,
    TimeFilterPipe,
    OrderEstheticianPipe,
    NutritionServiceOrderPipe,

    //Modals
    OrderCartItemsPipe,
    ModalServiceDetailComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NutritionRoutingModule,
    SharedComponentModule,
    NgxSliderModule,
    NgxMaskDirective, 
    NgxMaskPipe,
    AutocompleteLibModule,
    SimplebarAngularModule
  ],
})
export class NutritionModule { }
