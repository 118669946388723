<ul class="tabs-nav">
    <li><button [ngClass]="activeTab == 1 ? 'pill fill' : 'pill fill outline gray'" (click)="changeTab(1)">Online Purchases</button></li>
    <li><button [ngClass]="activeTab == 2 ? 'pill fill' : 'pill fill outline gray'" (click)="changeTab(2)">In-Store Purchases</button></li>
</ul>
<p *ngIf="!inStorePurchaseOrders.length">No Purchases Found.</p>

    
<!--Orders-->

<ul *ngIf="activeTab == 2" class="orders">
    <li [ngClass]="order.expand ? 'active' : '' " *ngFor="let order of inStorePurchaseOrders">
        <button class="flex-container" (click)="order.expand = !order.expand">
            <span class="icon">
                <img [src]="!order.expand ? '/assets/svgs/icon-accordion-open.svg' : '/assets/svgs/icon-accordion-close.svg' " />
                <!-- <?php 
                    include('svgs/icon-accordion-open.svg');
                    include('svgs/icon-accordion-close.svg');
                ?> -->
            </span>
            
            <div class="details">
                <span class="heading-6">Order {{order.node.number}}</span> <span class="eyebrow small">{{order.orders.length}} items</span>
                <p class="x-small">Purchased <strong>{{order.node.closedAt | date:'MMMM dd, YYYY' }}</strong> at <strong>{{order.node.closedAt | date:'hh:mm a'}}</strong> at <strong>{{order.node.location.businessName + '-' + order.node.location.name}}</strong></p>
            </div>
            
            <span class="eyebrow small price">Total Cost: {{sharedService.formatPrice(order.node.summary.currentTotal) | currency:'USD':'symbol'}}</span>
        </button>
        
        <!--Opened Accordion-->
        
        <div *ngIf="order.expand" class="accordion-content">
            <ul class="small products">
                <li class="flex-container" *ngFor="let product of order.orders">
                    <!-- <img src="	https://silvermirror.com/booking-flow/images/party-prep-facial.jpg" alt="Brightening Facial"/> -->
                    
                    <span class="heading-6 small">
                        {{product.name}}
                        <span class="pill">Quantity: {{product.quantity}}</span>
                    </span>
                    
                    <span class="price">{{sharedService.formatPrice(product.currentSubtotal) | currency:'USD':'symbol'}}</span>
                </li>
            </ul>
            
            <ul class="small totals">
                <li class="flex-container"><strong>Subtotal</strong> {{sharedService.formatPrice(order.node.summary.currentSubtotal) | currency:'USD':'symbol'}}</li>
                <li class="flex-container"><strong>Gratuity</strong> {{sharedService.formatPrice(order.node.summary.currentGratuityAmount) | currency:'USD':'symbol'}}</li>
                <li class="flex-container"><strong>Discount</strong> {{sharedService.formatPrice(order.node.summary.currentDiscountAmount) | currency:'USD':'symbol'}}</li>
                <li class="flex-container"><strong>Taxes</strong> {{sharedService.formatPrice(order.node.summary.currentTaxAmount) | currency:'USD':'symbol'}}</li>
                
                <li class="flex-container cart-total">
                    <span class="eyebrow small">Total</span> 
                    <p>{{sharedService.formatPrice(order.node.summary.currentTotal) | currency:'USD':'symbol'}}</p>
                </li>
            </ul>
        </div>
    </li>
</ul>