import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "src/environments";
import { SharedService } from "../shared-component/shared.service";

const BASE_URL = environment.apiBaseURL; 
@Injectable({
  providedIn: "root",
})
export class AuthService {
  public $AuthUser: BehaviorSubject<any> = new BehaviorSubject(null);
  public $otp: BehaviorSubject<any> = new BehaviorSubject(null);
  public otpResend:boolean=false;

  constructor(private http: HttpClient, private router: Router,private sharedService:SharedService,
  ) {
    const user:any = localStorage.getItem("AuthUser")
    this.$AuthUser.next(JSON.parse(user));
    this.$otp.next(localStorage.getItem("otp"));
  }

  getClientByEmail(email: any, doNotSendOTP? : boolean,reSendOTP?: boolean | undefined) { 
    
    const payload = {
      emails: [email],
    };
    return this.http
      .post(BASE_URL + "/get_client_by_email", payload)
      .subscribe((res: any) => {
        if (!res.erros && res.data.clients.edges.length) {
          const user = res.data.clients.edges[0].node;
          // user.authId = user.id.replace("urn:blvd:Client:", "");
          // localStorage.setItem("AuthUser", JSON.stringify(user));
          localStorage.setItem("userEmail", user.email);
          // this.$AuthUser.next(user);
          if (!doNotSendOTP) {
            const otp = Math.floor(Math.random() * 1000000 + 1).toString();
            this.$otp.next(otp);
            this.sendOTPEmail(user.email, otp, user.firstName,reSendOTP);
          }
        } else {
          const params:any = {
            state:{
              email: email
            }
          }
          this.router.navigateByUrl("/auth/register", params)
        }
      });
  }

  isEmailExist(email: any) {
    const payload = {
      emails: [email],
    };
    return this.http.post(BASE_URL + "/get_client_by_email", payload);
  }


  autoLogin(email: any) {
    const payload = {
      emails: [email],
    };
    return this.http.post(BASE_URL + "/get_client_by_email", payload);
  }

  sendOTPEmail(email: any, otp: string, name: any,resendOTP?:boolean) {
    this.otpResend=false;
    const payload = {
      email: email,
      otp: otp,
      name: name,
    };
    this.http.post(BASE_URL + "/login", payload).subscribe((res: any) => {
      localStorage.setItem("otp", otp);
      if(resendOTP){
        const title = 'OTP Alert';
          const message = 'OTP resend successfully.';
          this.sharedService.showNotification(title, message);
      }
      
    });
  }

  addNewClient(data: any) {
    const payload = {
      client: {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        mobilePhone: data.phone,
        password: data.password
      },
      joinEmail: data.joinEmail
    };
    return this.http
      .post(BASE_URL + "/createClient", payload);
  }

  loginWithPassword(payload:{email:string, password:string}){
    return this.http.post(BASE_URL + "/client_login", payload);
  }
}
