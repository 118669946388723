<!-- <app-alert [message]="greeting"></app-alert> -->
<app-header mainTitle="Book an Appointment"></app-header>

<main>
    <section class="location-flow">
        <div class="container grid">

            <app-breadcrumbs class="breadcrumbs-container"></app-breadcrumbs>
            <app-mobile-menu class="user-head mobile-head"></app-mobile-menu>

            <!-- QUICK REBOOK WIDGET --> 
            <div *ngIf="(authService.$AuthUser | async) && lastAppointment.length" class="flex-container quick-rebook" [class.active]="isActive">
                <h2 class="heading-6 desktop-only">Rebook Last Treatment</h2>
                <button class="mobile-only quick-rebook-accordion-btn eyebrow" (click)="isActive = !isActive">Rebook Last Treatment</button>
          
                
                <ul class="flex-container x-small">
                    <li class="service">{{lastAppointment[0]?.node?.baseService[0]?.service?.name}}</li>
                    <li class="esthetician">Esthetician: {{lastAppointment[0]?.node?.appointmentServices[0]?.staff?.firstName+' '+lastAppointment[0]?.node?.appointmentServices[0]?.staff?.lastName[0]+'.'}}</li>
                    <li *ngFor="let addon of lastAppointment[0]?.node?.addonsServices" class="addon">{{addon?.service?.name}} <span class="pill fill small">Add-on</span></li>
                    <!-- <li class="add-on">Dermaplanning <span class="pill fill small">Add On</span></li> -->
                    <li class="location">{{lastAppointment[0]?.node?.location?.address?.city}} - {{lastAppointment[0]?.node?.location?.name}}</li>
                </ul>
                
                <button class="btn black" (click)="rebookService.rebookAppointment(lastAppointment[0]?.node)">Search Availability</button>
            </div>

    <h2 class="eyebrow">Select Your Location</h2>
   <!-- <ngx-skeleton-loader
    count="5"
    [theme]="{ 
      'border-radius': '5px',
      height: '50px',
      'background-color': '#992929',
      border: '1px solid white'
    }"
  ></ngx-skeleton-loader>-->

    <ul class="accordions">
        
        <li *ngFor="let location of bookingService.locationList$.value | locationGroup :'city' let i = index;" 
         [ngClass]="(locationCatSelected(location) || currentIndex == i) ? 'active' : ''">
            <h3>

                <button class="flex-container heading-6 accordion" (click)="expand(i)" [attr.id]="getLocationId(location.key)">{{location.key}}
                    <span>
                        <svg class="icon-accordion-open" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                             <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                        <svg class="icon-accordion-close" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>
                      </span>
                </button>
                    
            </h3>
            <div class="panel" [ngClass]="{ 'visible' : currentIndex === i }">
                <ul class="content">
                
                    <li (click)="selectLocation(loc.id)" [ngClass]="locationSelected(loc.id)" class="flex-container" *ngFor="let loc of location.value">
                        <h4>
                            <strong>{{loc.name}}</strong>
                            <span>{{loc.address.line1}}</span>
                            <span>{{loc.address.line2}}</span>
                        </h4>
                        <a (click)="selectLocation(loc.id)" class="btn black small">Book Now</a> 
                        <!-- <div *ngIf="loadingMap[loc.id]" class="loader" style="width: 37px; text-align: center;"><img src="assets/Iphone-spinner.gif"></div>  -->               
                    </li> 
                </ul>
            </div>

        </li>
    </ul>
    
</div>
<!-- <div style="text-align: center; margin-top: 3rem;"><a (click)="skipLocation()" class="btn black small" routerLink="/booking/whoscoming">Skip for now</a></div>
 --></section>
</main>
<!-- <app-footer></app-footer> -->