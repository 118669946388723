import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Observable, forkJoin } from 'rxjs';
import { BookingService } from 'src/app/booking/booking.service';
import { TrackingService } from 'src/app/booking/tracking.service';
import { SharedService } from 'src/app/shared-component/shared.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-modal-service-detail',
  templateUrl: './modal-service-detail.component.html',
  styleUrls: ['./modal-service-detail.component.scss']
})
export class ModalServiceDetailComponent {
  tabs: {
    timeFilters: string;
    guest: any;
    service: string;
  } = {
      timeFilters: '30',
      guest: 'me',
      service: 'Facials 30 Minutes'
    }
    isSameServices: any = false;
  @Input() service:any;
  @Input() client:any;

  cart:any;
  cartSubscription:any;

  constructor(private trackingService:TrackingService, public sharedService:SharedService, private bookingService:BookingService, public serviceModalRef: MdbModalRef<ModalServiceDetailComponent>){
    this.isSameServices = this.sharedService.getLocalStorageItem('isSameService');
    this.cartSubscription = this.bookingService.clientCart$.subscribe((cart:any)=>{
      if(cart){
        this.cart = cart;
      }
    })
    setTimeout(() => {
      this.service.description.replace('/n','<br />');
    }, 1000);
  }

  isBaseServiceAdded(){
    let selected:boolean = false;
    this.cart.selectedItems.map((selectedItem:any)=>{
      if(this.client != 'me'){
        selectedItem.guestId == this.client.id ? selected = true : null;
      }else{
        selectedItem.guestId == null ? selected = true : null;
      }
    })
    return selected;
  }

  canAddHydrafacial(){
    const hydrafcialServices = this.cart.selectedItems.filter((item:any)=>{
      return item.item.name.toLowerCase().includes('hydrafacial');
    });
    return hydrafcialServices.length && this.service.name.toLowerCase().includes('hydrafacial') ? false : true;
  }

  addService(){
    if(this.canAddHydrafacial()){

      // if same service
        // then add in me and remove && add in guest
      // else (different service)
        // then remove old and add new service for user(me/guest)

        if(this.isSameServices != 'false'){
          let removeServicePromises:any = [];
          this.cart.selectedItems.forEach((service:any) => {
            removeServicePromises.push(this.bookingService.removeItemInCart(service.id));
          });

          let addServicePromises:any = []; 
          if(!this.service.selected){
            this.cart.guests.forEach((guest:any) => {
              const guestPayload = {
                id: this.service.id,
                staffId:null,
                guestId: guest.id
              }
              addServicePromises.push(this.bookingService.addItemInCart(guestPayload));
            });
            const mePayload = {
              id: this.service.id,
              staffId:null,
              guestId: null
            }
            addServicePromises.push(this.bookingService.addItemInCart(mePayload));
          }

          const promises1:any = [...removeServicePromises, ...addServicePromises]

          //this.bookingService.ngxUiLoaderService.start();
          forkJoin(promises1).subscribe(()=>{
            this.bookingService.updateCartDetail();
            this.serviceModalRef.close();
            //this.bookingService.ngxUiLoaderService.stop();
          });

        }else{
          let promises:any = [];
          const selectedService = this.cart.selectedItems.filter((item:any)=>{
            if(this.client != 'me'){
              return item.guestId == this.client.id;
            }else{
              return item.guestId == null;
            }
          });
          if(selectedService.length){
            selectedService.forEach((service:any) => {
              promises.push(this.bookingService.removeItemInCart(service.id))
            });
          }

          if(!this.service.selected){
            const payload = {
              id: this.service.id,
              staffId:null,
              guestId: this.client != 'me' ? this.client.id : null
            }
            promises.push(this.bookingService.addItemInCart(payload));
          }
          //this.bookingService.ngxUiLoaderService.start();
          forkJoin(promises).subscribe(()=>{
            this.bookingService.updateCartDetail();
            this.serviceModalRef.close();
            //this.bookingService.ngxUiLoaderService.stop();
          });

        }
      
    }else{
      const title = 'Please select another service.';
      const message = 'Unfortunately, this service cannot be booked for more than one guest at a time.';
      return this.sharedService.showNotification(title, message);
    }
  }

  addAddons(){
    this.serviceModalRef.close({addAddons : true});
  }

  getServiceName(name:string){
    return name.replace('(', '<br/>(');
  }

}
