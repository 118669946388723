import { Pipe, PipeTransform } from '@angular/core';
import { BookingService } from '../booking.service';
import { SharedService } from 'src/app/shared-component/shared.service';

@Pipe({
  name: 'locationGroupSchedule',
})
export class LocationGroupSchedulePipe implements PipeTransform {
  selectedLocation:any;
  constructor(private bookingService: BookingService, private sharedService:SharedService){
    const storedLocation = this.sharedService.getLocalStorageItem('selectedLocation');
      if (storedLocation) {
        this.selectedLocation = storedLocation;
      }
  }
  transform(collection: any[], property: string,activeLocation:Array<any>): any[] {

    // Extract and normalize location IDs from the collection
const locationIDs = collection.map(item => item.node.id.replace('urn:blvd:Location:', ''));

// Find matched IDs between locationIDs and activeLocation
const matchedIDs = locationIDs.filter(id => activeLocation.includes(id));

// Set the 'enabled' property to false for matched items
collection.forEach(item => {
    const normalizedId = item.node.id.replace('urn:blvd:Location:', '');
    if (matchedIDs.includes(normalizedId)) {
        item.node.enabled = true;
    }else{
      item.node.enabled = false;
    }
});

// Get the matched locations
let matchedLocations = collection.map(item => {
    const normalizedId = item.node.id.replace('urn:blvd:Location:', '');
    if (matchedIDs.includes(normalizedId)) {
        return { ...item, enabled: false };
    }
    return item;
});
    matchedLocations = matchedLocations.length?matchedLocations:collection;
    
    const groupedCollectionMain = Object.entries(matchedLocations.reduce((previous: any, current: { node: { address: { city: any; }; }; }) => {
      const city = current.node.address.city;
      if (previous[city]) {
        previous[city].push(current.node);
      } else {
        previous[city] = [current.node];
      }

      return previous;
    }, {})).map(([key, value]) => ({ key, value }));

    let groupedCollection = groupedCollectionMain.map((location: any, index: number) => {
      const isSelected = location.value.some((loc: any) => loc.id === this.selectedLocation);
      location.tabSelected = isSelected;
    
      if (isSelected) {
        this.bookingService.selectedLocationTab = index;
        location.index = index; // Assign the index to the location object
        this.bookingService.selectedLocationTab= index;
      }
      return location;
    });
    
    //this.currentIndex = this.bookingService.selectedLocationTab;
    
    
    if (groupedCollection.length >= 2) {
      const nyIndex = groupedCollection.findIndex(group => group.key === "New York City");
      const dcIndex = groupedCollection.findIndex(group => group.key === "Washington, DC");
      const miamiIndex = groupedCollection.findIndex(group => group.key === "Miami");
  
      if (nyIndex !== -1 && dcIndex !== -1 && miamiIndex !== -1) {
         
          groupedCollection = [
              groupedCollection[nyIndex],   
              groupedCollection[dcIndex],   
              groupedCollection[miamiIndex] 
          ];
      }
  
      groupedCollection.forEach(group => {
          (group.value as any[]).sort((a, b) => a.name.localeCompare(b.name));
      });
  
      console.log("groupedCollection", groupedCollection);
  }
  
   
    const updatedLocationList = groupedCollection.map(location => {
      if (location.key === "New York City") {
        location.key = "NYC";
      } else if (location.key === "Washington, DC") {
        location.key = "DC";
      }
      else{
        location.key = "MIAMI";
      }
      return location;
    });
  this.bookingService.activeLocationByService$.subscribe(res=>{
  });
    return updatedLocationList;
  }
}