import { Pipe, PipeTransform } from '@angular/core';
import { NutritionService } from '../nutrition.service';

@Pipe({
  name: 'filterService'
})
export class FilterServicePipe implements PipeTransform {

  constructor(private nutritionService:NutritionService){}

  transform(categories: Array<any>): Array<any> {
    if(categories?.length){
      let service = categories.filter((availableItem:any)=> {
        return availableItem.name.toLowerCase().includes('nutrition');
      });
      if(service.length){
        service.map((availableService:any)=>{
          availableService.availableItems.map((item:any)=>{
            item.selected = false;
          })
        });
      }
      service = this.isServiceAdded(service);
      return service;
    }else{
      return categories[0];
    }
  }

  isServiceAdded(services:Array<any>){
    let selectedItems = this.nutritionService.clientCart$.value.selectedItems;
    // selectedItems = selectedItems.filter((selectedItem:any)=> {
    //   if(client!= 'me'){
    //     return selectedItem.guestId == client.id
    //   }else{
    //     return selectedItem.guestId == null;
    //   }
    // });
    services.map((availableService:any)=>{
      availableService.availableItems.map((item:any)=>{
        selectedItems.map((selectedService:any)=>{
          item.id == selectedService.item.id ? item.selected = true : null;
        })
      })
    });
    return services;
  }

}
