import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderEstheticianByBio'
})
export class OrderEstheticianByBioPipe implements PipeTransform {

  transform(estheticians: Array<any>): Array<any> {
    if(estheticians.length){
     // console.log("esthetician :: ", estheticians);
      return estheticians.sort((a, b) => {
        let orderA = Number(a?.staff?.bio) || Number(a?.node?.bio);
        let orderB = Number(b?.staff?.bio) || Number(b?.node?.bio);
        orderA == 0 ? orderA = 999 : null;
        orderB == 0 ? orderB = 999 : null;

        if (orderA > orderB) {
          return 1;
        }
        if (orderA < orderB) {
          return -1;
        }
        return 0;
      });
    }else{
      return estheticians;
    }
  }

}

@Pipe({
  name: 'orderEstheticianByName'
})
export class OrderEstheticianByNamePipe implements PipeTransform {

  transform(estheticians: Array<any>): Array<any> {
    if(estheticians.length){
      return estheticians.sort((a, b) => {
        if (a.preferred || a.recent) {
          // If only the first object has the preferred key, put it first
          return -1;
        } else if (b.preferred || b.recent) {
          // If only the second object has the preferred key, put it first
          return 1;
        }else if (a?.displayName < b?.displayName) {
          return -1;
        }else if (a?.displayName > b?.displayName) {
            return 1;
        }else{
          return 0;
        } 
      });
    }else{
      return estheticians;
    }
  }

}
