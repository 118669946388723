<app-header mainTitle="Packages"></app-header>
<!--Main Content-->

<main id="main">
  <section class="congrats gift-card-review">
    <div class="container">
      <h2 class="heading-3">
        Thank You!
        <span>Thank You for purchasing the {{this.cart?.selectedItems[0]?.item?.name}}</span>
      </h2>

      <ul class="grid details buyer"> 
        <!--BUYER NAME-->
        <li>
          <h3 class="eyebrow small" >NAME</h3>
          <div class="cart-info gift-card-info">
            <div class="flex-container">
              <span class="small" style="word-break: break-word;">
                <p style="margin:0">{{packageRecipient.senderName}}</p>
              </span>
            </div>
          </div>
        </li>
        <li>
          <h3 class="eyebrow small" >EMAIL</h3>
          <div class="cart-info gift-card-info" style="padding-bottom: 16px;">
            <div class="flex-container">
              <span class="small" style="word-break: break-word;">
                <p style="margin:0">{{packageRecipient.senderEmail}}</p>
              </span>
            </div>
          </div>
        </li>
      </ul>

      <ul class="grid details gift-voucher" style="margin-bottom:30px;">
        <!--PACKAGE NAME-->
        <li>
          <h3 class="eyebrow small">PACKAGE NAME</h3>
          <div class="cart-info gift-card-info">
            <div class="flex-container">
              <span class="small" style="word-break: break-word;">
                <p style="margin:0">{{this.cart?.selectedItems[0]?.item?.name}}</p>
              </span>
            </div>
          </div>
        </li>
      </ul>

      <ul class="grid details gift-voucher">
          <!--Services Info-->
        <li>
          <div class="cart-info gift-card-info">
            <div class="flex-container">
              <span class="small" style="word-break: break-word;">
                <p [innerHTML]="packageService.removeTextAfterShortdescWithHTML(this.cart?.selectedItems[0]?.item?.description)"></p>
                <!-- <h3 class="eyebrow small">QUANTITY : ONE</h3>
                <h2 class="eyebrow small">YOU CAN REDEEM ON ANY ONE OF BELOW SERVICES:</h2> -->
                <!-- <ul>
                  <li *ngFor="let serviceName of packageServicesName">
                    {{ serviceName }}
                  </li>
                </ul> -->
                <!-- <p>Vouchers expire 90 days after purchase. Only one per customer.
                  Cannot be combined with another offer.</p> -->
               <!-- <p>An exclusive lifestyle package combining fitness, skincare,
and beauty for our Coral Gables community.
We’re excited to come together to treat our community to the
ultimate self-care package.</p> -->
              </span>

              <!-- <span class="cost">{{sharedService.formatPrice(this.cart?.summary?.subtotal) | currency:'USD':'symbol'}}</span> -->
            </div>

           <!--  <div class="flex-container cart-subtotal" *ngIf="(cart?.offers?.length && this.cart?.summary?.discountAmount) || (cart?.summary?.taxAmount && cart?.summary?.taxAmount != 0)">
              <span class="eyebrow small">Subtotal</span>
              <p class="small">{{sharedService.formatPrice(this.cart?.summary?.subtotal) | currency:'USD':'symbol'}}</p>
            </div>

            <div class="flex-container cart-subtotal" *ngIf="this.cart?.summary?.discountAmount">
              <span class="eyebrow small">Discount</span>
              <p class="small">-{{sharedService.formatPrice(this.cart?.summary?.discountAmount) | currency:'USD':'symbol'}}</p>
            </div>

            <div class="flex-container cart-tax" *ngIf="cart?.summary?.taxAmount && cart?.summary?.taxAmount != 0">
              <span class="eyebrow small">Tax</span>
              <p class="small">{{sharedService.formatPrice(this.cart?.summary?.taxAmount) | currency:'USD':'symbol'}}</p>
          </div>
          <div class="flex-container cart-total" *ngIf="cart?.selectedItems?.length">
              <span class="eyebrow small">Total</span>
              <p class="small">{{sharedService.formatPrice(this.cart?.summary?.total) | currency:'USD':'symbol'}}</p>
          </div> -->
          </div>
        </li>

        <!--Details-->
       <!-- 
        <li>
          <h3 class="eyebrow small">Details</h3>

          <div class="cart-info details-info">
            <div class="flex-container recipient">
              <span class="label">To <strong>{{cart?.selectedItems[0].emailFulfillment?.recipientName}}</strong></span>
              <span class="email small">{{cart?.selectedItems[0].emailFulfillment?.recipientEmail}}</span>
            </div>

            <div class="flex-container sender">
              <span class="label">From <strong>{{cart?.selectedItems[0].emailFulfillment?.senderName}}</strong></span>
              <span class="email small">angel@studiosimpati.co</span>
            </div>

            <div class="flex-container message">
              <span class="label">Message</span>
              <span class="message small"
                >{{cart?.selectedItems[0].emailFulfillment?.messageFromSender}}</span
              >
            </div>
          </div>
        </li> -->
      </ul>

      <div class="flex-container">
        <div class="book-view">
          <button class="btn black" (click)="downloadPDF()">Download Package PDF</button>
          <button class="btn black" (click)="clearCartBooking()">Book your facial</button>
          <!-- <button class="btn black" (click)="clearCart()" routerLink="/packages">Purchase Package</button> -->
        </div>
      </div>

      <!--CTAs-->

      <div class="ctas single">
        <div> 
          <h3 class="heading-4 small face-icon">
            <span class="eyebrow small face-icon">ASK AIDA</span>
            Tell us your skincare dreams and we'll show you where to glow.
          </h3>

          <a class="btn-primary" href="https://www.silvermirror.com/product-quiz/">Take the quiz</a>
        </div>
      </div>
    </div>
  </section>
</main>
