import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { SharedService } from 'src/app/shared-component/shared.service';
import { DashboardService } from '../dashboard.service';
import { BookingService } from 'src/app/booking/booking.service';
const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  return control.value.password === control.value.confirmPassword
    ? null
    : { PasswordNoMatch: true };
};
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  accountForm:FormGroup;
  // selectedLocation: any = null;
  // selectedStaff: any = null;
  // passwd:any;
  refLink:any;
  copyURL:boolean=false;
  ngOnInit(): void {
    this._buildForm();
    this.getClientInfo();
  }

  constructor(private authService:AuthService, private dashboardService:DashboardService, 
    private formBuilder:FormBuilder, private sharedService:SharedService,public bookingService:BookingService){
    this.getLocations();
    this.accountForm = formBuilder.group({});
  }

  _buildForm(){
    this.accountForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ["", Validators.compose([Validators.minLength(8), Validators.maxLength(30)])],
      confirmPassword: ["", Validators.compose([Validators.minLength(8), Validators.maxLength(30)])],
      mobilePhone: ['', Validators.required],
      preferredLocation: [''],
      preferredStaff: [''],
    },{ validators: [confirmPasswordValidator]});
  }

  _patchForm(client:any){
    // this.passwd=
    //console.log("Pass",client.customFields[2].textValue);
    this.accountForm.patchValue({
      email: client.email,
      firstName: client.firstName,
      lastName: client.lastName,
      preferredLocation: client.customFields[2]?client.customFields[2].textValue:'',
      preferredStaff: client.customFields[1]?client.customFields[1].textValue:'',
      mobilePhone: this.sharedService.getLast10digits(client.mobilePhone)
    });
    this.accountForm.controls.email.disable();
  }
  copyToClipboard() {
    const fullLink = this.refLink;

    // Copy to clipboard using the Clipboard API
    navigator.clipboard.writeText(fullLink).then(
      () => {
        this.copyURL=true;
      },
      (error) => {
        this.copyURL=false;
      }
    );
  }
  getClientInfo(){
    this.dashboardService.getClientInfo().subscribe((res:any)=>{
      if(!res.errors){
        const client = res.data.clients.edges[0].node;
        console.log("client",client);
        setTimeout(() => {
          this.refLink=client.customFields[0]?.textValue;
          this._patchForm(client);  
        }, 100);
        this.preferredStaff(client.customFields[2]?client.customFields[2].textValue:'', client.customFields[1]?client.customFields[1].textValue:null);
      }else{
        const title = 'Information';
        const message = res.errors[0].message;
        this.sharedService.showNotification(title, message);
      }
    })
  }

  updateClient(){
    const client = this.accountForm.value;
    client.email = this.accountForm.controls['email'].value;
    this.dashboardService.updateClientInfo(client).subscribe((res:any)=>{
      if(!res.errors){
        let user = res.data.updateClient.client;
        user.authId = user.id.replace("urn:blvd:Client:", "");
        localStorage.setItem("AuthUser", JSON.stringify(user));
        this.authService.$AuthUser.next(user);

        const title = 'Info Updated';
        const message = 'Your account information has been updated';
        this.sharedService.showNotification(title, message);
      }else{
        const title = 'Information';
        const message = res.errors[0].message;
        this.sharedService.showNotification(title, message);
      }
    })
  }
  
  getLocations(){
    //if (this.bookingService.locationList$.value.length === 0) {
    this.bookingService.getLocations().subscribe((res: any) => {
      if (!res.errors) {
        this.bookingService.locationList$.next([...res.data.locations.edges]);
        // this.selectedLocation = "1";
      } else {
        this.sharedService.showNotification("Error getting locations: ", res.errors[0].message);
      }
    });
  //}
  }
 
  preferredStaff(location: any | string, preferredStaff?:string) {
    let selectedLocationId: string;
  
    if (typeof location === 'string') {
      selectedLocationId = location;
    } else {
      selectedLocationId = location.target.value;
    }
  
   /*  this.bookingService.getStaffByLocation(selectedLocationId).subscribe((res: any) => {
      if (!res.errors) {
        this.bookingService.staffList$.next(res);
      } else {
        this.sharedService.showNotification("Error", res.errors[0].message);
      }
    }); */

   
      this.bookingService.getStaffByLocation(selectedLocationId).subscribe((res: any) => {
        if (!res.errors) {
          this.bookingService.staffList$.next(res);
          // this.selectedStaff = "1";
          setTimeout(() => {
            preferredStaff ? this.accountForm.patchValue({preferredStaff:preferredStaff}) : null;            
          }, 10);
        } else {
          this.sharedService.showNotification("Error getting staffs: ", res.errors[0].message);
        }
      });
    
  }
  
}
