<!--Skip to main content-->
<a class="accessibility" href="#main">Skip To Content</a>	
<!--Header--> 
<header class="flex-container">
    <a class="logo" href="https://silvermirror.com/"><img [src]="'/assets/svgs/booking-logo.svg'" alt="Book an appointment with Silver Mirror Facial Bar"/></a>
    <h1 [routerLink]=" mainTitle == 'Book an Appointment' ? '/booking' : null" class="heading-6 small">{{mainTitle}}</h1>
    <div class="user-pop-outer">
        <a href="tel:(888) 677-0055" class="data-ui phone">
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16"> 
                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/> 
                </svg>
            </span>
            (888) 677-0055
        </a>
        <div class="user-head">
            <a class="data-ui sign-in menu-item-has-children" href="javascript:void(0)" (click)="menuToggle()"> 
            <span>
            <svg width="8px" height="8px" viewBox="0 0 8 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-1328.000000, -36.000000)" fill="#000000" fill-rule="nonzero">
                        <g transform="translate(1321.000000, 29.000000)">
                            <g transform="translate(7.500000, 7.000000)">
                                <path d="M3.5,4 C4.60468769,4 5.5,3.10453129 5.5,2 C5.5,0.895468712 4.60468769,0 3.5,0 C2.39531255,0 1.5,0.895468712 1.5,2 C1.5,3.10453129 2.39531255,4 3.5,4 Z M4.29218769,4.75 L2.70781255,4.75 C1.21265626,4.75 0,5.9625001 0,7.45781231 C0,7.75687504 0.242500067,7.99953147 0.541562557,7.99953147 L6.45875025,7.99953147 C6.7578125,8 7,7.7578125 7,7.45781231 C7,5.9625001 5.7874999,4.75 4.29218769,4.75 Z"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            </span> 
        </a>
        <ul class="loggedin-menu user-pop" *ngIf="showSubMenu">
            <li routerLink="/booking">Book a Facial</li>
            <li routerLink="/nutrition">Book a Nutrition</li>
            <li routerLink="/membership/selection">Buy Membership</li>
            <li routerLink="/giftcards">Buy Gift Card</li>
            <li routerLink="/packages">Buy Package</li>
            <li *ngIf="user" (click)="logout()">Sign Out</li>
            <li *ngIf="!user" routerLink="/auth/login">Sign In</li>
        </ul>
        </div>
        
    </div>
        
    

</header>

	