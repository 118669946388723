import { Pipe, PipeTransform } from "@angular/core";
import { BookingService } from "../../booking.service";
import { SharedService } from "src/app/shared-component/shared.service";

@Pipe({
  name: "addons",
})
export class AddonsPipe implements PipeTransform {

  constructor(private bookingService: BookingService, private sharedService: SharedService) { }

  transform(selectedItems: Array<any>, client: any, orderingAddons?: any): Array<any> {
    console.log("orderingAddons", orderingAddons);
    if (selectedItems) {
      let clientID = client != 'me' ? client.id : null;
      let addons: any = [];
      let items: any = selectedItems.filter((selectedItem: any) => {
        return selectedItem.guestId == clientID && !selectedItem.item.optionGroups.length;
      });
      console.log("items", items);
      if (items.length) {
        addons = items[0].addons;
      } else {
        addons = items;
      }
      this.isAddonAdded(addons, selectedItems, clientID);
      const orderedNames = orderingAddons
        .map((item: { businessServiceAddon: { serviceAddon: { name: string; }; }; }) => item.businessServiceAddon.serviceAddon.name);

      const orderedAddons = orderedNames.map((name: string) =>
        addons.find((addon: { name: string; }) => addon.name === name)
      ).filter((addon: any) => addon !== undefined);

      const uniqueOrderedAddons = orderedAddons.filter((addon: { name: any; }, index: any, self: any[]) =>
        index === self.findIndex((a) => a.name === addon.name)
      );

      return uniqueOrderedAddons;
    } else {
      return []
    }
  }

  isAddonAdded(addons: Array<any>, selectedItems: Array<any>, clientID: string | null) {

    if (selectedItems && selectedItems.length) {
      const clientaddons = selectedItems.filter(item => (item.guestId == clientID && item.item.optionGroups.length));
      // const clientaddons = clientServices.filter(addon=> !addon?.addons?.length);

      clientaddons.map((clientAddon: any) => {
        addons.map((addon: any) => {
          addon.id.includes(clientAddon.item.id.replace('urn:blvd:Service:', '')) ? addon.selected = true : null;
          addon.id.includes(clientAddon.item.id.replace('urn:blvd:Service:', '')) ? addon.serviceID = clientAddon?.item?.id : null;
        })
      })
    }

  }

  // categorizeAddons(addonsList:any) {
  //   const categories = Array.from(new Set(addonsList.map((option:any) => option.optionGroups[0].name)));

  //   const result = categories.map((category:any) => {
  //     const addons = addonsList.filter((option:any) => option.optionGroups[0].name === category);
  //     addons.sort((a:any,b:any)=> Number(this.sharedService.getServiceOrder(a.description)) - Number(this.sharedService.getServiceOrder(b.description)));
  //     return { category, addons };
  //   }).sort((a:any, b:any) => {
  //         const nameA = a.category.toUpperCase();
  //         const nameB = b.category.toUpperCase();

  //         const orderA = this.categoryOrder.find(cat=> cat.category.toUpperCase() == nameA)?.order;
  //         const orderB = this.categoryOrder.find(cat=> cat.category.toUpperCase() == nameB)?.order;

  //         return Number(orderA) - Number(orderB);
  //       })
  //   return result;
  // }

}
