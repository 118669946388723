import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { SharedService } from "src/app/shared-component/shared.service";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";

const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  return control.value.password === control.value.confirmPassword
    ? null
    : { PasswordNoMatch: true };
};

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent {

  form!: FormGroup;
  loading = false;
  submitted = false;
  joinEmail: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private sharedService: SharedService,
    private _location: Location,
    private router: Router,
  ) {
    this.authService.$AuthUser.subscribe((user) => {
      if (user) {
        this.router.navigateByUrl('/dashboard', { replaceUrl: true });
      }
    });
    if (!history.state.email) {
      this.router.navigateByUrl('/auth/login', { replaceUrl: true });
    }
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: [{ value: history.state.email ? history.state.email : "", disabled: true }, Validators.required],
      password: ["", Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(30)])],
      confirmPassword: ["", Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(30)])],
      phone: ["", Validators.compose([Validators.required, Validators.maxLength(10), Validators.minLength(10)])],
    }, { validators: [confirmPasswordValidator] });
  }

  createAccount() {
    this.submitted = true;
    let formValue = this.form.value;
    formValue.email = this.form.controls['email'].value;
    formValue.joinEmail = this.joinEmail;

    if (this.form.invalid) {
      //const title = 'Invalid Form';
      const title = '';
      const message = 'Please fill the correct information';
      this.sharedService.showNotification(title, message);
      return;
    }

    this.authService.addNewClient(formValue).subscribe((res: any) => {
      if (!res.errors) {
        this.form.reset();
        const title = 'Account Created';
        const message = 'Please login to book an appointment';
        this.sharedService.showNotification(title, message);
        this._location.back();
      }
    });
  }
}
