import { Component, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-services-tabs',
  templateUrl: './services-tabs.component.html',
  styleUrls: ['./services-tabs.component.scss']
})
export class ServicesTabsComponent {

  tab:any = '30';
  @Input() isAddonEnable:boolean = false;
  @Input() reset:any;
  @Input() cart:any;
  @Output() changeTabEvent = new EventEmitter<string>();

  constructor() {
    this.tab = "Facials 30 Minutes";
  }

  ngOnChanges(changes: SimpleChanges){
    changes.reset && changes.reset.currentValue?.event ? this.changeTab(changes.reset.currentValue.currentTab) : null;
  }

  changeTab(tab:string,cat?:any){
    if(tab != 'addon' || (tab=='addon' && this.isAddonEnable)){
      this.tab = tab;
    }
    this.changeTabEvent.emit(tab);
    const element = document.getElementById(cat);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    this.tab = tab;
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.checkSectionInView();
  }

  checkSectionInView() {
    const sections = ['Facials 30 Minutes', 'Facials 50 Minutes', 'Eyebrows', 'Microchanneling', 'Chemical Peels', 'injectables'];
    for (const sectionId of sections) {
      const element = document.getElementById(sectionId);
      if (element && this.isElementInViewport(element)) {
        console.log("sectionId",sectionId);
        this.tab = sectionId;  // Set tab to the section id when it’s in view
        break;
      }
    }
  }

  isElementInViewport(el: HTMLElement): boolean {
    const rect = el.getBoundingClientRect();
    return rect.top >= 0 && rect.top < window.innerHeight / 2;  // Checks if top is near the viewport top
  }

  hasServices(category:string){
    if(this.cart && this.cart.availableCategories){
      const filteredCat = this.cart.availableCategories.filter((cat:any)=>{
        return cat.name.toLowerCase().includes(category.toLowerCase());
      })
      
      if(filteredCat.length){
        return filteredCat[0].availableItems.length
      }else{
        return filteredCat.length;
      }
    }
  }
  }
