import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { SharedService } from "src/app/shared-component/shared.service";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  email: any = "";
  otp: any = "";
  enteredPassword: string = '';
  enteredotp: any = "";
  formType: 'password' | 'otp' | 'email' = 'email';

  emailForm!: FormGroup;
  otpForm!: FormGroup;
  passwordForm!: FormGroup;
  redirectURL: any;
  forgotPassword:boolean=false;

  constructor(
    public authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private _location: Location
  ) {
    this.authService.$AuthUser.subscribe((user) => {
      if (user) {
        this.router.navigateByUrl('/dashboard', { replaceUrl: true });
      }
    });
    this._buildForm();
    authService.$otp.subscribe((otp: string) => {
      this.otp = otp;
    });

    this.setRedirectURL();


    /* console.log("forgotPassword",this.forgotPassword);
    if(this.forgotPassword){
    this.redirectURL = '/dashboard/account'
    }else{
    this.redirectURL = this.router?.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();
  } */
  }
  
  setRedirectURL() {
    if (this.forgotPassword) {
      this.redirectURL = '/dashboard/account';
    } else {
      this.redirectURL = this.router?.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();
    }
  }
  handleForgotPassword() {
    this.forgotPassword = true;
    this.changeForm('otp');
    this.setRedirectURL();
  }
  _buildForm() {
    this.emailForm = this.formBuilder.group({
      email: ["", Validators.compose([Validators.required, Validators.email])]
    });
    this.otpForm = this.formBuilder.group({
      otp: ["", Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(6)])]
    });
    this.passwordForm = this.formBuilder.group({
      password: ["", Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(30)])]
    });
  }

  emailCheck() {
    if (this.emailForm.valid) {
      this.authService.isEmailExist(this.email).subscribe((res: any) => {
        if (res.data.clients.edges.length) {
          this.formType = 'password';
        } else {
          const params: any = {
            state: {
              email: this.email
            }
          }
          this.router.navigateByUrl("/auth/register", params)
        }
      });
    } else {
      const title = 'Invalid Email';
      const message = 'Please enter a correct email address';
      this.sharedService.showNotification(title, message);
    }
  }

  changeForm(type: 'email' | 'password' | 'otp', resendOTP?: boolean | undefined) {

    switch (type) {
      case 'email':
        this.formType = 'email';
        break;
      case 'password':
        this.formType = 'password';
        break
      case 'otp':
        this.formType = 'otp';
        this.authService.getClientByEmail(this.email, false, resendOTP);
        break;

      default:
        break;
    }
  }
  
  checkOTP() {
    if (localStorage.getItem("otp") == this.enteredotp) {
      const email = this.sharedService.getLocalStorageItem('userEmail');
      this.authService.autoLogin(email).subscribe((res: any) => {
        if (!res.erros && res.data.clients.edges.length) {
          const user = res.data.clients.edges[0].node;
          user.authId = user.id.replace("urn:blvd:Client:", "");
          localStorage.setItem("AuthUser", JSON.stringify(user));
          this.sharedService.removeLocalStorageItem('userEmail');
          this.sharedService.removeLocalStorageItem('otp');
          this.authService.$AuthUser.next(user);
          this.authService.$otp.next(null);
          this.router.navigateByUrl(this.redirectURL ? this.redirectURL : '/dashboard', { replaceUrl: true });
        }
      });
    } else {
      const title = 'Invalid OTP';
      const message = 'OTP does not match';
      this.sharedService.showNotification(title, message);
    }
  }

  checkPassword() {
    const returnUrl = this.router.url;
    const payload = {
      email: this.email,
      password: this.enteredPassword
    }
    this.authService.loginWithPassword(payload).subscribe((res: any) => {
      if (res.code == 404) {
        const params: any = {
          state: {
            email: this.email,
            password: this.enteredPassword
          }
        }
        this.router.navigateByUrl("/auth/register", params)
      } else if (!res.error && res.client) {
        const user = res.client.node;
        user.authId = user.id.replace("urn:blvd:Client:", "");
        localStorage.setItem("AuthUser", JSON.stringify(user));
        this.authService.$AuthUser.next(user);
        this.router.navigateByUrl(this.redirectURL ? this.redirectURL : '/dashboard', { replaceUrl: true });
      } else if (res.error) {
        const title = 'Login Failed';
        const message = res.error;
        this.sharedService.showNotification(title, message);
      }
    });
  }
}
