<app-header mainTitle="Book an Appointment"></app-header>
<section class="schedule-flow">
    <div class="container grid">
        <!--Breadcrumbs-->
        <app-breadcrumbs class="breadcrumbs-container"></app-breadcrumbs>
        <app-mobile-menu class="user-head mobile-head"></app-mobile-menu>
        <div class="nutrition-location">
            <div class="grid">
                <p>
                    <b>IMPORTANT:</b> All of our Registered Dietitians can perform consultations for guests located in any of the following states:
                </p>

                <ul class="location-list">
                    <li>Arizona (AZ)</li>
                    <li>California (CA)</li>
                    <li>Colorado (CO)</li>
                    <li>Connecticut (CT)</li>
                    <li>Massachusetts (MA)</li>
                    <li>Michigan (MI)</li>
                    <li>New Jersey (NJ)</li>
                    <li>New York (NY)</li>
                    <li>North Carolina (NC)</li>
                    <li>Pennsylvania (PA)</li>
                    <li>Virginia (VA)</li>
                    <li>Washington (WA)</li>
                </ul>

                <div class="additional-location">
                    <p><b>The following Dietitians have the ability to perform consultations in these additional jurisdictions:</b></p>

                    <ul class="additional-location-list">
                        <li><b>Florida (FL):</b> Catherine, Cayla, Donna</li>
                        <li><b>Illinois (IL):</b> Donna, Lindsey</li>
                        
                    </ul>
                </div>

                <a class="btn" routerLink="/nutrition/schedule">Continue</a>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>