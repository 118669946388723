<ul class="appointment-list" [ngClass]="appointment?.guestsAppointment?.appointments?.length ? 'guest' : '' ">
	<li class="flex-container" (click)="toggleMobileList = !toggleMobileList" [ngClass]="toggleMobileList ? 'active' : '' ">
		<!--Date-->
		
		<div class="date">
			<span class="mobile-only">{{appointment?.startAt | date:'EEE':'EST'}},</span>
			<span class="ui-date month">{{appointment?.startAt | date:'MMM':'EST'}}</span>
			<span class="heading-5 day">{{appointment?.startAt | date:'d':'EST'}}</span>
			<span class="ui-date year">{{appointment?.startAt | date:'y':'EST'}}</span>
			<span class="pill fill small gray">&#64;{{sharedService.removeLastTimezone(appointment?.startAt) | date:'hh:mm a'}}</span>
			<span class="guest-mobile mobile-only">Group</span>
		</div>
		
		<!--Services-->
		
		<div class="services">
			<h3 class="pill">Services (ME)</h3>
			
			<ul class="x-small">
				<li [ngClass]="isAddon(service) ? 'addon' : 'service'" *ngFor="let service of appointment.appointmentServices | sortAppointmentServices">{{service?.service?.name}} <span *ngIf="isAddon(service)" class="pill fill small">Add on</span></li>
				<!-- <li *ngFor="let addon of appointment.appointmentServiceOptions" class="addon">{{addon?.name}} <span class="pill fill small">Add on</span></li> -->
			</ul>
		</div>
		
		<!--Details-->
		
		<div class="details">
			<h3 class="pill">Details</h3>
			
			<ul class="x-small">
				<li class="esthetician">{{appointment.appointmentServices[0]?.staff?.firstName+' '+appointment?.appointmentServices[0]?.staff?.lastName[0]+'.'}}</li>
				<li *ngIf="activeTab == 1" class="location">{{appointment?.location?.address?.city}} - {{appointment?.location?.name}}</li>
				<li>Status - <span class="pill fill small gray">{{appointment?.state}}</span></li>
			</ul>
		</div>
		
		<!--Actions-->
		
		<div class="actions desktop-only">
				<span *ngIf="rebook" class="pill rebook">Rebook Last Treatment</span>
				<a *ngIf="rebook" class="btn black small" (click)="rebookAppointment()">Search Availability</a>
				<a *ngIf="activeTab == 2 && zoomLink && appointmentType=='upcoming'" class="btn black small" target="_blank" [href]="zoomLink">Join Meeting</a>
				<!-- <span class="pill rebook">Quick Rebook</span>
				<a class="btn black small" (click)="rebookAppointment()">Search Availability</a> -->
				
				<span (click)="navigateToReschedule()"><a *ngIf="!zoomLink && canReschedule && appointment?.reschedulable && appointment?.state != 'FINAL' && !appointment?.guestsAppointment" class="btn black small reschedule">Reschedule</a></span>
				<span *ngIf="cancel && appointment?.state != 'FINAL'" (click)="cancelBooking()"><a class="text-link">{{appointment?.guestsAppointment ? 'Cancel Group Appointment' : 'Cancel'}}</a></span>
				<br><br>
				<ul class="actions" *ngIf="appointmentType !='past'">
					<li (click)="toggleCal($event)" class="add-to-cal"><a class="btn small black">Add to Cal</a>
						<ul *ngIf="toggleOptions" class="add-to-cal-menu add-to-cal-pop">
							<li><a target="_blank" (click)="openLink($event, appointment?.calendarLinks?.googleCalendar)" [href]="appointment?.calendarLinks?.googleCalendar">Google Calendar</a></li>
							<li><a target="_blank" (click)="openLink($event, appointment?.calendarLinks?.icsDownload)" [href]="appointment?.calendarLinks?.icsDownload">ics Download</a></li>
							<li><a target="_blank" (click)="openLink($event, appointment?.calendarLinks?.icsDownload)" [href]="appointment?.calendarLinks?.microsoftOutlook">Microsoft Outlook</a></li>
							<li><a target="_blank" (click)="openLink($event, appointment?.calendarLinks?.yahooCalendar)" [href]="appointment?.calendarLinks?.yahooCalendar">Yahoo Calendar</a></li>
						</ul>
					</li>
				</ul>
		</div>

		<!-- With Guset -->
		<ul class="appointment-list" *ngIf="appointment?.guestsAppointment?.appointments?.length">
			<li class="flex-container" *ngFor="let apt of appointment?.guestsAppointment.appointments" (click)="toggleMobileList = !toggleMobileList" [ngClass]="toggleMobileList ? 'active' : '' ">
				<!--Date-->
				<div class="date desktop-only">
				</div>
				
				<!--Services-->
				
				<div class="services">
					<h3 class="pill">Services ({{apt?.client?.name}})</h3>
					
					<ul class="x-small">
						<li [ngClass]="isAddon(service) ? 'addon' : 'service'" *ngFor="let service of apt?.appointmentServices">{{service?.service?.name}} <span *ngIf="isAddon(service)" class="pill fill small">Add on</span></li>
						</ul>
				</div>
				
				<!--Details-->
				
				<div class="details">
					<h3 class="pill">Details</h3>
					
					<ul class="x-small">
						<li class="esthetician">{{apt?.appointmentServices[0]?.staff?.firstName+' '+apt?.appointmentServices[0]?.staff?.lastName[0]+'.'}}</li>
						<li class="location">{{appointment?.location?.address?.city}} - {{appointment?.location?.name}}</li>
						<li>Status - <span class="pill fill small gray">{{appointment?.state}}</span></li>
					</ul>
				</div>
				<div class="actions desktop-only">
				</div>
			</li>
		</ul>
		<div class="actions mobile-only">
			<span *ngIf="rebook" class="pill rebook">Rebook Last Treatment</span>
			<a *ngIf="rebook" class="btn black small" (click)="rebookAppointment()">Search Availability</a>
			<a *ngIf="activeTab == 2 && zoomLink && appointmentType=='upcoming'" class="btn black small" target="_blank" [href]="zoomLink">Join Meeting</a>
			<span (click)="navigateToReschedule()"><a *ngIf="!zoomLink && appointment?.reschedulable && appointment?.state != 'FINAL' && !appointment?.guestsAppointment" class="btn black small reschedule">Reschedule</a></span>
			<span *ngIf="cancel && appointment?.state != 'FINAL'" (click)="cancelBooking()"><a class="text-link">{{appointment?.guestsAppointment ? 'Cancel Group Appointment' : 'Cancel'}}</a></span>
			<br><br>
			<ul class="actions" *ngIf="appointmentType !='past'">
				<li (click)="toggleCal($event)" class="add-to-cal"><a class="btn small black">Add to Cal</a>
					<ul *ngIf="toggleOptions" class="add-to-cal-menu add-to-cal-pop">
						<li><a target="_blank" (click)="openLink($event, appointment?.calendarLinks?.googleCalendar)" [href]="appointment?.calendarLinks?.googleCalendar">Google Calendar</a></li>
						<li><a target="_blank" (click)="openLink($event, appointment?.calendarLinks?.icsDownload)" [href]="appointment?.calendarLinks?.icsDownload">ics Download</a></li>
						<li><a target="_blank" (click)="openLink($event, appointment?.calendarLinks?.microsoftOutlook)" [href]="appointment?.calendarLinks?.microsoftOutlook">Microsoft Outlook</a></li>
						<li><a target="_blank" (click)="openLink($event, appointment?.calendarLinks?.yahooCalendar)" [href]="appointment?.calendarLinks?.yahooCalendar">Yahoo Calendar</a></li>
					</ul>
				</li>
			</ul>
		</div>
	</li>
</ul>