<section class="client-portal account-info">
	<!-- <div class="container grid"> -->
		
		<!--Sidebar-->
		<!-- <?php include('includes/client-portal/sidebar.php'); ?> -->
		
		<!--Content-->
		<!-- <div class="content"> -->
			<div *ngIf="refLink">
				<p><b>Your referral link: </b>
				  <a [href]="refLink" target="_blank">{{ refLink }}</a>
				  &nbsp;&nbsp;<button class="btn" (click)="copyToClipboard()">{{copyURL?"URL copied":"Copy URL"}}</button>
				</p>
				
			  </div>
						  
			<form [formGroup]="accountForm" class="flex-container" (ngSubmit)="updateClient()">
				<div class="column-2">
					<label class="pill" for="first-name">First Name*</label>
					<input formControlName="firstName" required type="text" id="first-name" placeholder="First Name"/>
					<div class="required" *ngIf="accountForm.controls.firstName.invalid && accountForm.controls.firstName.touched">
						<div *ngIf="accountForm.controls.firstName.errors?.required">First Name is required</div>
					</div>
				</div>
				
				<div class="column-2">
					<label class="pill" for="last-name">Last Name*</label>
					<input formControlName="lastName" required type="text" id="last-name" placeholder="Last Name"/>
					<div class="required" *ngIf="accountForm.controls.lastName.invalid && accountForm.controls.lastName.touched">
						<div *ngIf="accountForm.controls.lastName.errors?.required">Last Name is required</div>
					</div>
				</div>
				
				<div class="column-2">
					<label class="pill" for="email-address">Email Address*</label>
					<input formControlName="email" required type="email" id="email-address" placeholder="example@email.com"/>
					<div class="required" *ngIf="accountForm.controls.email.invalid && accountForm.controls.email.touched">
						<div *ngIf="accountForm.controls.email.errors?.required">Email address is required</div>
						<div *ngIf="accountForm.controls.email.errors?.email">Invalid Email address</div>
					</div>
				</div>
				
				<!-- <div class="column-2">
					<label class="pill" for="street-address">Street Address</label>
					<input type="text" id="street-address" placeholder="Enter-Address"/>
				</div>
				
				<div class="column-3">
					<label class="pill" for="city">City</label>
					<input type="text" id="city" placeholder="Enter City"/>
				</div>
				
				<div class="column-3">
					<label class="pill" for="state">State</label>
					
					<select id="state">
						<option>Select State</option>
					</select>
				</div>
				
				<div class="column-3">
					<label class="pill" for="zip">Zip/Postal Code</label>
					<input type="text" id="zip" placeholder="Enter Zip"/>
				</div>
				
				<div class="column-2 help-text">
					<label class="pill" for="display-name">Display Name*</label>
					<input required type="text" id="display-name" placeholder="tamara-1856"/>
					<p class="xx-small">How your name is displayed in the account section and reviews</p>
				</div> -->
				
				<!-- <div class="column-2">
					<label class="pill" for="birthdate">Date of Birth</label>
					<input formControlName="dob" type="text" id="birthdate" placeholder="Enter Birthday (YYYY-MM-DD)"/>
				</div> -->
				
				
				
				<div class="column-2">
					<label class="pill" for="phone-num">Phone Number*</label>
					<input mask= '(000) 000-0000' formControlName="mobilePhone" required type="tel" id="phone-num" placeholder="(555) 555-5555"/>
					<div class="required" *ngIf="accountForm.controls.mobilePhone.invalid && accountForm.controls.mobilePhone.touched">
						<div *ngIf="accountForm.controls.mobilePhone.errors?.required">Phone number is required</div>
					</div>
				</div>
				<div class="column-2">
					<label class="pill" for="pref-location">Preferred Location</label>

					<select id="pref-location" formControlName="preferredLocation" (change)="preferredStaff($event)">
						<option value="">Select Location</option>
						<option [value]="location.node.id" *ngFor="let location of bookingService.locationList$.value | location">{{location?.node?.name}}</option>
					</select>
				</div>

				<div class="column-2">
					<label class="pill" for="pref-location">Preferred Esthetician</label>
					<select id="pref-esthetician" formControlName="preferredStaff">
						<option value="">Select Esthetician</option>
						<option *ngFor="let staff of (bookingService.staffList$ | async | staffShorting:'node.firstName'); let i = index" [value]="staff.node.id">
							{{ staff.node.firstName }} {{ staff.node.lastName[0] }}.
						  </option>
					</select>
				</div>

				<div class="column-2">
					<label class="pill" for="password">Password </label>
					<!-- <input type="hidden" id="pwd" name="pwd" placeholder="pwd" formControlName="pwd"/>  -->
                    <input maxLength="30" minLength="8" type="password" id="password" name="password"  placeholder="******"  formControlName="password"/> 
					<div class="required" *ngIf="accountForm.controls.password.invalid && accountForm.controls.password.touched">
						<div *ngIf="accountForm.controls.password.errors?.required">Password is required</div>
						
					</div>
					<span style="font-size: 12px;
					color: #ccc;">Password length Min. 8 Max. 30 Characters </span>
				</div>

				<div class="column-2">
					<label class="pill" for="confirm-password">Confirm Password</label>
                    <input maxLength="30" minLength="8" type="password" id="confirm-password" name="confirm-password" placeholder="******" formControlName="confirmPassword">
					<div class="required" *ngIf="accountForm.controls.confirmPassword.invalid && accountForm.controls.confirmPassword.touched">
						<div *ngIf="accountForm.controls.confirmPassword.errors?.required">Confirm password is required</div>
					</div>
				</div>
				
				<!-- <div class="column-1 credit-card-options">
					<h3>Payment Method</h3>
					
					<ul id="card-detail-options" class="x-small">
						<li class="active visa">
							<strong>VISA •••• 0458 <span>Expires 11/23</span></strong>
							<span class="pill fill small">Expired</span>
						</li>
					</ul>
				</div> -->
				
				<button [disabled]="accountForm.invalid" class="btn" type="submit">Save Changes</button>
				
			</form>
			
		<!-- </div> -->
		
	<!-- </div> -->
</section>