import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisadleContinueService {
  disableContinueSubject = new BehaviorSubject<boolean>(false);
  disableContinue$ = this.disableContinueSubject.asObservable();

  constructor() { 
    this.disableContinueSubject.next(false);
  }
  setDisableContinue(value: boolean) {
    this.disableContinueSubject.next(value);
  }
}
