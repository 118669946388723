import { Component } from '@angular/core';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-membership-products',
  templateUrl: './membership-products.component.html',
  styleUrls: ['./membership-products.component.scss']
})
export class MembershipProductsComponent {

  products:any;

  constructor(private dashboardService:DashboardService){
    this.getMemberships();
  }

  getMemberships(){
    this.dashboardService.getMemberships().subscribe((res:any)=>{
      this.products = res.data.products;
      this.products.edges.sort((a: any, b: any) => parseInt(a.node.barcode) < parseInt(b.node.barcode) ? -1 : 1);
    })
  }
}
