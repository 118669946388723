import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedService } from 'src/app/shared-component/shared.service';
import { BookingService } from '../booking.service';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {

  @Input() cart: any;
  @Input() isSameService: any;
  @Output() onItemRemoveEvent = new EventEmitter<string>();
  toggleMobileCart: boolean = false;
  toggleEditLocation: boolean = false;
  selectedLocation: any;
  loadingDelete: boolean = false;

  constructor(private trackingService: TrackingService, public bookingService: BookingService, public sharedService: SharedService, public router: Router) {
    this.isSameService = this.sharedService.getLocalStorageItem("isSameService");
    const storedLocation = this.sharedService.getLocalStorageItem('selectedLocation');
    if (storedLocation) {
      this.selectedLocation = storedLocation;
    }
    //if (this.bookingService.locationList$.value.length === 0) {
    // this.bookingService.getLocations().subscribe((res: any) => {
    //   if (!res.errors) {
    //     this.bookingService.locationList$.next([...res.data.locations.edges]);
    //   } else {
    //     this.sharedService.showNotification("Error getting locations: ", res.errors[0].message);
    //   }
    // });
    //}
  }
  deletingServiceId: any;
  removeItem(item: any) {
    this.deletingServiceId = item.id;
    this.loadingDelete = true;
    let isSameService = this.sharedService.getLocalStorageItem("isSameService");

    if (isSameService == 'false' || !isSameService) {
      this.onItemRemoveEvent.emit();
      this.bookingService.removeItemInCart(item.id).subscribe((res: any) => {
        if (!res.errors) {
          item.item.listPrice = item.price;
          item.item.category = this.sharedService.getServiceCategoryName(item, this.cart.availableCategories) == '' ? 'addon' : this.sharedService.getServiceCategoryName(item, this.cart.availableCategories);
          this.trackingService.removeItem(item.item);
          /* const title = 'Success';
          const message = 'Add-on removed successfully';
          this.sharedService.showNotification(title, message); */
          this.bookingService.updateCartDetail();
          this.loadingDelete = false;
          this.deletingServiceId = null;
        } else {
          this.sharedService.showNotification('Errors', res.errors[0].message);
          this.loadingDelete = false;
          this.deletingServiceId = null;

        }
      });
    } else {
      const removeServiceRequests = this.cart.selectedItems.filter((selectedItem: any) => selectedItem.item.id == item.item.id).map((service: any) => {
        item.item.listPrice = item.price;
        item.item.category = this.sharedService.getServiceCategoryName(item, this.cart.availableCategories) == '' ? 'addon' : this.sharedService.getServiceCategoryName(item, this.cart.availableCategories);
        this.trackingService.removeItem(item.item);
        //this.loadingDelete=false;

        return this.bookingService.removeItemInCart(service.id);
      });
      forkJoin(removeServiceRequests).subscribe(res => {
        this.sharedService.setLocalStorageItem('isSameService', 'true');
        this.onItemRemoveEvent.emit();
        /* const title = 'Success';
        const message = 'Service removed successfully';
        this.sharedService.showNotification(title, message); */
        //this.bookingService.updateCartDetail();
        const cartId = this.sharedService.getLocalStorageItem('cartId');
        if (cartId) {
          this.bookingService.getCartDetail().subscribe((res: any) => {
            if (!res.errors) {
              this.bookingService.clientCart$.next(res.data.cart);
              this.loadingDelete = false;
              this.deletingServiceId = null;
            }
          });
        }


      })
      // const title = 'Please enable my guest can have different services.';
      // const message = 'Then you will be able to add/remove services.';
      // this.sharedService.showNotification(title, message);
    }
  }

  // removeModifier(modifier:any, guestId:string){
  //   let isSameService = this.sharedService.getLocalStorageItem("isSameService");
  //   if(isSameService == 'true' || !isSameService){
  //     let selectedItems:any = this.cart.selectedItems.filter((selectedItem:any)=>{
  //       return selectedItem.guestId == guestId;
  //     })
  //     let optionIds:Array<string | null> = this.getSelectedModifiers(selectedItems);
  //     let index = optionIds.indexOf(modifier.id);
  //     optionIds.splice(index,1);

  //     const payload = {
  //       id: selectedItems[0].id,
  //       optionIds: [...optionIds],
  //       staffId:null,
  //       guestId:guestId
  //     }
  //     this.bookingService.addAddonInCart(payload).subscribe((res:any)=>{
  //       if(!res.errors){
  //         const title = 'Add-on removed';
  //         const message = 'REMOVED FROM CART';
  //         this.sharedService.showNotification(title, message);
  //         this.bookingService.updateCartDetail();
  //       }else{
  //         this.sharedService.showNotification('Errors', res.errors[0].message);
  //       }
  //     });
  //   }else{
  //     const title = 'Please enable my guest can have different services.';
  //     const message = 'Then you will be able to add/remove services.';
  //     this.sharedService.showNotification(title, message);
  //   }
  // }

  // getSelectedModifiers(selectedItems:any):Array<string | null>{
  //   if(selectedItems[0].selectedOptions.length){
  //     let ids = selectedItems[0].selectedOptions.map((option:any)=> option.id);
  //     return ids;
  //   }else{
  //     return []
  //   }
  // }

  get getTotalAddedServiceCount() {
    let count = 0;
    if (this.cart && this.cart.selectedItems) {
      let selectedItems = this.cart.selectedItems;
      if (selectedItems && selectedItems.length) {
        selectedItems.map((selectedItem: any) => {
          ++count;
          count = count + selectedItem.selectedOptions.length;
        })
      }
    }
    return count;
  }

  getServicePrice(selectedService: any) {
    let optionsPrice = 0;
    selectedService.selectedOptions.map((option: any) => {
      optionsPrice = optionsPrice + option.priceDelta;
    });
    return selectedService.price - optionsPrice;
  }

  // New function added to get the price with out location
  getServicePriceWithoutLocation(selectedService: any, cart: any): number {
    let selectedServiceId: any;
    if (Array.isArray(selectedService)) {
      selectedServiceId = selectedService[0].item.id;
    } else {
      selectedServiceId = selectedService.item.id;
    }

    const firstTwoItems = cart.availableCategories.slice(0, 2);
    for (let category of firstTwoItems) {
      const selectedItem = category.availableItems.find((item: any) => item.id === selectedServiceId);
      if (selectedItem) {
        return selectedItem.listPrice;
      }
    }
    return 0; // or return a default value if appropriate
  }

  getServicePriceWithoutLocationTotal(): number {
    let totalPrice = 0;
    this.cart.selectedItems.forEach((selectedItem: any) => {
      this.cart.availableCategories.forEach((category: any) => {
        category.availableItems.forEach((availableItem: any) => {
          if (selectedItem.item.id === availableItem.id) {
            totalPrice += availableItem.listPrice;
          }
        });
      });
    });
    return totalPrice;
  }
  // New function added to get the price with out location

  getSelectedStaffVariant(guest: any) {
    let selectedStaff: any;
    if (this.cart && this.cart.selectedItems && this.cart.selectedItems.length) {
      let selectedItem = this.cart.selectedItems.filter((selectedItem: any) => {
        return guest ? selectedItem.guestId == guest.id : selectedItem.guestId == null;
      });
      if (selectedItem[0].selectedStaffVariant) {
        selectedStaff = selectedItem[0].selectedStaffVariant.staff.firstName + ' ' + selectedItem[0].selectedStaffVariant.staff.lastName[0] + '.';
      }
    }
    return selectedStaff;
  }
  showEditLocationLoader: boolean = false;
  editLocation() {
    this.showEditLocationLoader = true;
    this.bookingService.getCartDetail().subscribe((res: any) => {
      if (this.cart && res.data?.cart.selectedItems.length > 0) {
        const message = "If you change the location, might be selected service get removed.<br /> Are you sure you want to change the location?"
        this.sharedService.openConfirmationAlert(message).then((res: any) => {
          if (res) {
            this.showEditLocationLoader = false;
            this.toggleEditLocation = !this.toggleEditLocation;
          }
        });
      } else {
        this.toggleEditLocation = !this.toggleEditLocation;
        this.showEditLocationLoader = false;
      }
    });
  }

}
