import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { NotificationService } from '../../notification.service';
import { Options, ChangeContext } from '@angular-slider/ngx-slider';
import { SharedService } from 'src/app/shared-component/shared.service';
import { CalendarComponent } from "../../shared-component/calendar/calendar.component";
import * as moment from "moment";
import { AuthService } from 'src/app/auth/auth.service';
import { NutritionService } from '../nutrition.service';

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss']
})
export class SchedulingComponent implements OnInit {

  cart:any;
  @ViewChild(CalendarComponent) calendarComponent:any;

  availableDates:BehaviorSubject<any> = new BehaviorSubject([]);
  availableTimes:BehaviorSubject<any> = new BehaviorSubject([]);
  staffVarients:BehaviorSubject<any> = new BehaviorSubject([]);
  staffList:BehaviorSubject<any> = new BehaviorSubject([]); // For staff filter
  selectedTime:any;
  selectedDate:any;
  selectedStaff:any;
  // cartDetail:any = [];
  selectedItems:any = [];
  toggleTimeFilter:boolean = false;
  toggleStaffFilter:boolean = false;
  cacheMonths:any = [];

  minValue: number = 0;
  maxValue: number = 23;
  options: Options = {
    floor: 0,
    ceil: 23,
    step:1,
    noSwitching: true,
    translate: (value: number): string => {
      if(value >= 0 && value <12){
        return value != 0 ? value + 'AM' : '12' + 'AM';
      }else{
        return (value - 12 == 0 ? '12' : value - 12) + 'PM'
      }
    }
  };
  estheticianPref:any = 1;

  ngOnInit(): void {
    if(this.authService.$AuthUser.value){
      this.nutritionService.takeCartOwnership().subscribe((res:any)=>{
        if(!res.errors){
          this.nutritionService.getCartDetail();
        }
      });
    }
    // this.nutritionService.getCartDetail();
    // this.getBookableDates();
    this.nutritionService.clientCart$.subscribe((cart)=>{
      if(cart && cart.id){
        this.cart = cart;
        this.selectedItems = cart.selectedItems;
        // this.ifStaffVariantSelected();
        // this.getBookableDates();
        this.getStaffList();
      }
    })
    this.cart ? this.removeStaff() : null;
  }

  constructor(private changeRef:ChangeDetectorRef, public nutritionService:NutritionService, private router:Router, public sharedService:SharedService, private authService:AuthService){}
  
  getStaffVariantByServiceId(serviceId:string){
    this.cart.map((cart:any)=>{
      const service = cart.availableItems.filter((item:any)=>item.id == serviceId);
      service.length ? this.staffVarients.next(service[0].staffVariants) : null;
      return;
    })
  }

  getBookableDates(staffIDs:any, staffChange?:boolean){
    const locationId = this.cart.location.id;
    let currentMonth = this.calendarComponent?.currentMonth;
    let indexOfCacheMonth = this.cacheMonths?.length ? this.cacheMonths.findIndex((cache:any)=> cache?.isSame(currentMonth)) : -1;
    if(indexOfCacheMonth == -1 || !staffChange){
      let lowerRange = moment(currentMonth).startOf('month').format('YYYY-MM-DD');
      let upperRange = moment(currentMonth).endOf('month').format('YYYY-MM-DD');
      this.nutritionService.getScheduleDates(locationId, lowerRange, upperRange, staffIDs).subscribe((res:any)=>{
        if(!res.errors){
          // const cacheAvailableDates = this.availableDates.value;
          this.cacheMonths.push(currentMonth);
          // this.availableDates.next([...res.data.cartBookableDates, ...cacheAvailableDates]);  // cacheDates are removed because of esthetician filter 8-8-23
          const filteredDates = this.extendScheduleDays([...res.data.cartBookableDates]);
          this.availableDates.next(filteredDates);
        }else{
          alert(res.errors[0].message);
        }
      })
    }
  }

  extendScheduleDays(availableDates:Array<any>){
    if(availableDates.length){
      const filteredDates = availableDates.filter(date => {
        return new Date(date.date).getTime() > new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).getTime();
      });
      return filteredDates;
    }else{
      return availableDates;
    }
  }

  monthChange(ev?:any, staffChange?:boolean){
    let staffIDs = this.staffList.value.map((staff:any)=> 
      {
        if(staff.node.filter){
          const mainServiceId = this.cart.selectedItems.map((item:any)=>{
            if(item.guest == null && item.item.optionGroups.length == 0){
              return item.item.id
            }
          }).filter(Boolean)[0];
          const staffId = staff.node.id

          const staffVariantId = mainServiceId.substring(mainServiceId.lastIndexOf(":")+1) + staffId.substring(staffId.lastIndexOf(":"));
          return staffVariantId;
        }
      }
    ).filter(Boolean);
    this.getBookableDates(staffIDs, staffChange);
  }

  selectDate(ev:any){
    this.selectedDate = ev;
    let staffIDs = this.staffList.value.map((staff:any)=> 
      {
        if(staff.node.filter){
          const mainServiceId = this.cart.selectedItems.map((item:any)=>{
            if(item.guest == null && item.item.optionGroups.length == 0){
              return item.item.id
            }
          }).filter(Boolean)[0];
          const staffId = staff.node.id

          const staffVariantId = mainServiceId.substring(mainServiceId.lastIndexOf(":")+1) + staffId.substring(staffId.lastIndexOf(":"));
          return staffVariantId;
        }
      }
    ).filter(Boolean);
    this.nutritionService.getScheduleTimes(ev.fullDate, staffIDs).subscribe((res:any)=>{
      if(!res.errors){
        this.availableTimes.next(res.data.cartBookableTimes);
        this.staffVarients.next([]);
      }else{
        alert(res.errors[0].message);
      }
    })
  }

  selectTime(time:any){
    time.selected = true;
    this.selectedTime = time;
    const serviceId:string = this.selectedItems[0].id;
    const locationId = this.cart.location.id;
    this.nutritionService.getCartStaffVarients(time.id, serviceId, locationId).subscribe((res:any)=>{
      if(!res.errors){
        this.staffVarients.next(res.data.cartBookableStaffVariants);
        // this.ifStaffVariantSelected();
      }else{
        alert(res.errors[0].message);
      }
    })
  }

  selectStaff(staff:any){
    this.estheticianPref = 2
    if(this.cart.guests.length){
      staff ? staff.staff.selected = true : null;  
      this.selectedStaff = staff?.staff;
    }else{
      staff ? staff.node.selected = true : null;
      this.selectedStaff = staff?.node;
    }
  }

  removeStaff(){
    return new Promise((resolve, reject)=>{
      this.nutritionService.updateItemInCart(this.selectedItems[0].id, null).subscribe(()=>{
        resolve(true);
      })
    });
  }

  // groupObjectsById(array:any) {
  //   const groups:any = {};
    
  //   for (const obj of array) {
  //     if (obj.guestId in groups) {
  //       groups[obj.guestId].data.push(obj);
  //     } else {
  //       groups[obj.guestId] = { guestId: obj.guestId, guest: obj.guest, data: [obj] };
  //     }
  //   }
    
  //   return Object.values(groups);
  // }

  assignStaff(){
    let variantID = '';
    if(!this.selectedStaff){
      this.selectedStaff = this.staffVarients.value[0];
      variantID = this.staffVarients.value[0].id;
    }else{
      this.staffVarients.value.map((staff:any)=>{
        staff.staff.id == this.selectedStaff.id ? variantID = staff.id : null;
      })
    }

    return new Promise((resolve, reject)=>{
      this.nutritionService.updateItemInCart(this.cart.selectedItems[0].id, variantID).subscribe((res:any)=>{
        resolve(true);
      });
    });

    // const remainingStaff = this.staffVarients.value.filter((staff:any)=> staff.id != this.selectedStaff.id);

    // remainingStaff.sort((a:any, b:any) => {
    //   const orderA = Number(a.staff.bio);
    //   const orderB = Number(b.staff.bio);
    //   if (orderA < orderB) {
    //     return -1;
    //   }
    //   if (orderA > orderB) {
    //     return 1;
    //   }
    //   return 0;
    // });

    // const guestsSelectedItems = this.cart.selectedItems.filter((item:any)=> item.guestId != null);
    // const groupSelectedItems = this.groupObjectsById(guestsSelectedItems);
    //   groupSelectedItems.map((item:any, index:number)=>{
    //     // remainingStaff.map((staff:any)=>{
    //       item.data.map((element:any)=>{
    //         requests.push(this.bookingService.updateItemInCart(element.id, remainingStaff[index].id, item.guestId));
    //       })
    //     // });
    //   });
  }

  reserveCart(){
    window.scrollTo(0, 0);
    const bookableTimeId = this.selectedTime?.id;
    if(bookableTimeId){
      const itemId:string = this.selectedItems.length ? this.selectedItems[0].id : "";
      // if(this.selectedStaff?.id){
        this.assignStaff().then(res=>{
          this.nutritionService.reserveCartItems(bookableTimeId).subscribe((res:any)=>{
            if(!res.errors){
              this.router.navigateByUrl('nutrition/review');
            }
          })
        });
        // this.bookingService.updateItemInCart(itemId, this.selectedStaff.id).subscribe((res:any)=>{
        //   if(!res.errors){
        //     this.bookingService.reserveCartItems(bookableTimeId).subscribe((res:any)=>{
        //       if(!res.errors){
        //         this.router.navigateByUrl('booking/review');
        //       }
        //     })
        //   }else{
        //     alert(res.errors[0].message);
        //   }
        // });
      // }else{
      //   this.bookingService.reserveCartItems(bookableTimeId).subscribe((res:any)=>{
      //     if(!res.errors){
      //       this.router.navigateByUrl('booking/review');
      //     }
      //   })
      // }
    }else{
      const title = 'Appointment time not selected';
      const message = 'Please choose an appointment time';
      this.sharedService.showNotification(title, message);
    }
  }

  getStaffList(){
    this.nutritionService.getStaffList().subscribe((res:any)=>{
      if(!res.errors){
        this.staffList.next(res);
    //     let staffIDs = this.staffList.value.map((staff:any)=> 
    //   {
    //     if(staff.node.filter){
    //       const mainServiceId = this.cart.selectedItems.map((item:any)=>{
    //         if(item.guest == null && item.item.optionGroups.length == 0){
    //           return item.item.id
    //         }
    //       }).filter(Boolean)[0];
    //       const staffId = staff.node.id

    //       const staffVariantId = mainServiceId.substring(mainServiceId.lastIndexOf(":")+1) + staffId.substring(staffId.lastIndexOf(":"));
    //       return staffVariantId;
    //     }
    //   }
    // ).filter(Boolean);
    // this.getBookableDates(staffIDs, true);
    // if(this.cart.guests.length){
      this.getBookableDates([], true);
    // }
      }
    })
  }

  getSelectedStaffCount(){
    let staffIDs = this.staffList.value.map((staff:any)=> 
      {
        if(staff.node.filter){
          return staff.node.id
        }
      }
    ).filter(Boolean);
    return staffIDs.length;
  }

  getSelectedStaff(){
    let staff = this.staffList.value.map((staff:any)=> 
      {
        if(staff.node.filter){
          return staff.node
        }
      }
    ).filter(Boolean);
    return staff[0];
  }

  filterStaff(ev:any, staff?:any){
    let staffs = this.staffList.value;
    staffs.map((staff:any)=>{
      staff.node.filter = false;
    });
    if(staff){
      staff.node.filter = true;
      this.staffList.next(staffs);
    }
    this.selectStaff(staff);
    this.selectedDate = null;
    this.availableTimes.next([]);
    this.staffVarients.next([]);
    this.monthChange(null, false);
    this.toggleStaffFilter = false;
  }

  changeTimeRange(changeContext: ChangeContext): void {
    // const min = changeContext.value;
    // const max = changeContext.highValue;
    // const date = new Date()
  }

  clearStaffFilter(selection:boolean){
    let staffs = this.staffList.value;
    staffs.map((staff:any)=>{
      staff.node.filter = selection;
    });
    this.staffList.next(staffs);
    this.getBookableDates([], true)
  }

  clearTimeFilter(){
    this.minValue = 9;
    this.maxValue = 20;
  }

  closeMobileFilter(){
    this.toggleTimeFilter = false;
    this.toggleStaffFilter = false;
  }

  applyEstheticianFilter(){
    this.toggleStaffFilter = false;
    this.monthChange(null, false);
    this.availableTimes.next([]);
    this.staffVarients.next([]);
    this.selectedDate = null;
  }

  changeEstheticianPreference(){
    if(this.estheticianPref == 1){
      let staffVarients = this.staffVarients.value;
      staffVarients.map((staff:any)=>staff.selected = false);
      this.staffVarients.next(staffVarients);
    }
  }

  selectEvent(ev:any){
    const staff = {
      node: ev
    }
    this.filterStaff(null, staff);
    this.changeRef.detectChanges();
  }

  onChangeSearch(ev:any){
    // this.selectedStaff = null;
  }

  get staffData(){
    if(this.staffList.value.length){
      const staffList = this.staffList.value.map((staff:any)=>{
        return staff.node;
      });
      return staffList
    }else{
      return []
    }
  }

}
