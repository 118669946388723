import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-giftcard',
  templateUrl: './giftcard.component.html',
  styleUrls: ['./giftcard.component.scss']
})
export class GiftcardComponent {
  constructor(private titleService:Title){
    this.titleService.setTitle('Gift Cards - Silver Mirror');
  }
}
