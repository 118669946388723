import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SharedService } from 'src/app/shared-component/shared.service';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-memberships',
  templateUrl: './memberships.component.html',
  styleUrls: ['./memberships.component.scss']
})
export class MembershipsComponent {

  $memberships:BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(private dashboardService:DashboardService, private router:Router, private sharedService:SharedService){
    this.getMembershipsList();
  }

  getMembershipsList(){
    this.dashboardService.myMembershipsList().subscribe((res:any)=>{
      if(!res.errors){
        this.$memberships.next(res.data.myMemberships.edges);
        if(!this.$memberships.value.length){
          this.router.navigateByUrl("/dashboard/membership-products");
        }
      }else{
        const title = 'Information';
        const message = res.errors[0].message;
        this.sharedService.showNotification(title, message);
      }
    })
  }

  voucherExpiry(date:string){
    if(date){
      const currentExpiry = new Date(date);
      currentExpiry.setMonth(currentExpiry.getMonth() + 2);
      return currentExpiry;
    }else{
      return null;
    }
  }

  getCreditsTitle(membershipName:string){
    switch (membershipName) {
      case "30-Minute Facial Membership":
        return "30-Minute Facial Credits"
        break;

      case "50-Minute Facial Membership":
        return "50-Minute Facial Credits"
        break;
    
      default:
        return membershipName
        break;
    }
  }
  numberOfActiveMonths(startOn:any,endOn:any){
    const date1 = new Date(startOn);
    const date2 = new Date(endOn);
    const yearDiff = date2.getFullYear() - date1.getFullYear();
    const monthDiff = date2.getMonth() - date1.getMonth();
    const totalMonthsDiff = yearDiff * 12 + monthDiff;
    return totalMonthsDiff;
  }

}
