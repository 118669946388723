import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-giftcard',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent {
  constructor(private titleService:Title){
    this.titleService.setTitle('Packages - Silver Mirror');
  }
}
