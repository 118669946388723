 <app-header mainTitle="Book an Appointment"></app-header>

<section class="congrats">
	<div class="container">
		
		<h2 class="heading-3">Your health is in good hands.</h2>
		
		<ul class="grid details">
		
			<!--Services Info-->
			
			<li>
				<h3 class="eyebrow small">Services</h3>
				
				<div class="cart-info">
					<ul class="small">
						<li *ngIf="cart?.selectedItems?.length">
							<ul class="small" *ngFor="let groupedService of (cart?.selectedItems | orderCartItems)">
								<li class="flex-container user-outer">
									<span class="user">{{groupedService.guest ? groupedService?.guest?.label : 'Me'}} <span class="counter gray">{{groupedService?.data?.length}}</span> <span *ngIf="getSelectedStaffVariant(groupedService.guest)" class="person">{{getSelectedStaffVariant(groupedService.guest)}} </span> </span>
								</li>
								<li class="flex-container" *ngFor="let service of groupedService.data" [ngClass]="service?.item?.optionGroups?.length !=0 ? 'add-on' : ''">
									<span class="item-info">
										<strong>{{service.item.name}}</strong>
										<span class="pill fill small" *ngIf="service?.item?.optionGroups?.length !=0">Add On</span>
										<!-- <span *ngIf="sharedService.getServiceCategoryName(service, cart.availableCategories) != '' " class="pill">{{sharedService.getServiceCategoryName(service, cart.availableCategories)}}</span> -->
									</span>
									
									<!-- <span class="cost">
										{{sharedService.formatPrice(getServicePrice(service)) | currency:'USD':'symbol' : '1.0-0'}}
									</span> -->
								</li>
								<!-- <li class="flex-container add-on" *ngFor="let modifier of service.selectedOptions">
									<span class="item-info">
										<strong>{{modifier?.name}}</strong>
										<span class="pill fill small">Add On</span> 
									</span>
									
									<span class="cost">
										{{sharedService.formatPrice(modifier.priceDelta) | currency:'USD':'symbol' : '1.0-0'}}
									</span>
								</li> -->
							</ul>
						</li>
					</ul>
					<!-- <ul class="small">
						<li class="flex-container" *ngFor="let apt of appointment?.appointmentServices">
							<span class="user">Me <span class="counter gray">{{getServicesCount()}}</span></span>
							<span>
								<strong>{{apt?.service?.name}}</strong>
							</span>
							
							<span class="cost">
								{{sharedService.formatPrice(getServicePrice()) | currency:'USD':'symbol' : '1.0-0'}}
							</span>
						</li>
					</ul> -->
					
					<!-- <div style="margin-bottom: 16px;" class="flex-container cart-total" *ngIf="cart?.summary?.taxAmount">
						<span class="eyebrow small">Tax</span>
						<p class="small">{{sharedService.formatPrice(this.cart?.summary?.taxAmount) | currency:'USD':'symbol'}}</p>
					</div>
					<div style="margin-bottom: 16px;" class="flex-container cart-total" *ngIf="cart?.offers?.length && this.cart?.summary?.discountAmount">
						<span class="eyebrow small">Subtotal</span>
						<p class="small">{{sharedService.formatPrice(this.cart?.summary?.subtotal) | currency:'USD':'symbol'}}</p>
					</div>
					<div style="margin-bottom: 16px;" class="flex-container cart-total" *ngIf="cart?.offers?.length && this.cart?.summary?.discountAmount">
						<span class="eyebrow small">{{cart.offers[0]?.name}}</span>
						<p class="small">-{{sharedService.formatPrice(this.cart?.summary?.discountAmount) | currency:'USD':'symbol'}}</p>
					</div>
					<div class="flex-container cart-total" *ngIf="cart?.selectedItems?.length">
						<span class="eyebrow small">Total</span>
						<p class="small">{{sharedService.formatPrice(this.cart?.summary?.total) | currency:'USD':'symbol'}}</p>
					</div> -->
				</div>
			</li>
		
			<!--Details-->
		
			<li>
				<h3 class="eyebrow small">Details</h3>
				
				<div class="cart-info">
					<ul class="x-small cart-details">
						<li>{{cart?.startTime | date:'EEEE, MMMM d @ h:mm a'}}</li>
						<!-- <li>Esthetician: {{staff?.firstName}}</li> -->
						
						<!-- <li>
							{{appointment?.location?.address.city}} - {{appointment?.location?.name}}
							<address>{{appointment?.location?.address?.line1}}</address>
							<a *ngIf="appointment?.location?.phoneNumber" href="tel:{{appointment?.location?.phoneNumber}}">{{appointment?.location?.phoneNumber}}</a>
						</li> -->
					</ul>
					
					<ul class="actions">
						<li (click)="toggleOptions = !toggleOptions" class="add-to-cal"><a class="btn small black">Add to Cal</a>
							<ul *ngIf="toggleOptions" class="add-to-cal-menu add-to-cal-pop">
								<li><a target="_blank" (click)="openLink($event, appointment?.calendarLinks?.googleCalendar)" [href]="appointment?.calendarLinks?.googleCalendar">Google Calendar</a></li>
								<li><a target="_blank" (click)="openLink($event, appointment?.calendarLinks?.icsDownload)" [href]="appointment?.calendarLinks?.icsDownload">ics Download</a></li>
								<!-- <li><a target="_blank" (click)="openLink($event, appointment?.calendarLinks?.microsoftOffice)" [href]="appointment?.calendarLinks?.microsoftOffice">Microsoft Office</a></li>-->								<li><a target="_blank" (click)="openLink($event, appointment?.calendarLinks?.microsoftOutlook)" [href]="appointment?.calendarLinks?.microsoftOutlook">Microsoft Outlook</a></li>
								<li><a target="_blank" (click)="openLink($event, appointment?.calendarLinks?.yahooCalendar)" [href]="appointment?.calendarLinks?.yahooCalendar">Yahoo Calendar</a></li>
							</ul>
						
						</li>
						<!-- <li (click)="toggleShareOptions = !toggleShareOptions" class="add-to-cal"><a class="btn small black">Share</a>
							<ul *ngIf="toggleShareOptions" class="add-to-cal-menu add-to-cal-pop">
								<li (click)="shareOnFacebook()"><a>Facebook</a></li> 
								<li (click)="shareOnTwitter()"><a>Twitter</a></li>
								<li (click)="shareViaEmail($event)"><a>Email</a></li>
							</ul>
						
						</li> -->
						<!-- <li><a class="btn small black" href="#">Share</a></li> -->
					</ul>
				</div>
			</li>
		</ul>

		<div class="flex-container">
			<div class="book-view">
				<a class="btn black" href="/nutrition">Book An Appointment</a>
				<button class="btn black" (click)="viewAppointment()">View My Appointments</button>
			</div>
		</div>
		
		<!--CTAs-->
		
		<ul class="grid ctas">
			<li>
				<h3 class="heading-6 small clock-icon">
					<span class="eyebrow small">BROWSE OUR SERVICES</span>
					We believe in facials that love your skin, respect your schedule, and provide real results.
				</h3>
				
				<a class="btn-primary" href="https://www.silvermirror.com/services/">View Menu</a>
			</li>
			
			<li>
				<h3 class="heading-6 small face-icon">
					<span class="eyebrow small face-icon">ASK AIDA</span>
					Tell us your skincare dreams and we'll show you where to glow.
				</h3>
				
				<a class="btn-primary" href="https://www.silvermirror.com/product-quiz/">Take the quiz</a>
			</li>

			<li>
				<h3 class="heading-6 small hand-icon">
					<span class="eyebrow small">GIVE A GIFT</span>
					Not sure what to buy for your loved ones? Give the gift of healthy skin with a Silver Mirror gift card!
				</h3>
				
				<a class="btn-primary" href="https://booking.silvermirror.com/">Shop Now</a>
			</li>
		</ul>
		
	</div>
</section>
<app-footer></app-footer>
  
        