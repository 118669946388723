<div class="sidebar-sticky">
	<ul class="small services-tabs desktop-only">
<!-- 		<li (click)="changeTab('facial')" [ngClass]="tab=='facial' ? 'active' : ''"><button>Facials</button></li>
 -->		<!-- <li (click)="changeTab('addon')" [ngClass]="tab=='addon' ? 'active' : ''"><button>Add-ons</button></li> -->
		<li *ngIf="hasServices('Facials 30 Minutes')" (click)="changeTab('30','Facials 30 Minutes')" [ngClass]="tab=='Facials 30 Minutes' ? 'active' : ''"><button>Facials 30 Minutes</button></li>
		<li *ngIf="hasServices('Facials 50 Minutes')" (click)="changeTab('50','Facials 50 Minutes')" [ngClass]="tab=='Facials 50 Minutes' ? 'active' : ''"><button>Facials 50 Minutes</button></li>
		
		<li *ngIf="hasServices('eyebrows')" (click)="changeTab('eyebrows','Eyebrows')" [ngClass]="tab=='Eyebrows' ? 'active' : ''"><button>Eyebrows</button></li>
		<li *ngIf="hasServices('Microchanneling')" (click)="changeTab('microchanneling','Microchanneling')" [ngClass]="tab=='Microchanneling' ? 'active' : ''"><button>Microchanneling</button></li>
		<li *ngIf="hasServices('Chemical Peels')" (click)="changeTab('peels','Chemical Peels')" [ngClass]="tab=='Chemical Peels' ? 'active' : ''"><button>Chemical Peels</button></li>
		<li *ngIf="hasServices('Injectables')" (click)="changeTab('injectables','Injectables')" [ngClass]="tab=='Injectables' ? 'active' : ''"><button>Injectables</button></li>
	</ul>

	<ul class="mobile-only mobile-services-tabs">
<!-- 		<li (click)="changeTab('facial')" [ngClass]="tab=='facial' ? 'active' : ''"><button>Facials</button></li>
 -->		<!-- <li (click)="changeTab('addon')" [ngClass]="tab=='addon' ? 'active' : ''"><button>Add-ons</button></li> -->
 		<!-- <li *ngIf="hasServices('Facials 30 Minutes')" (click)="changeTab('30')" [ngClass]="tab=='30' ? 'active' : ''"><button>Facials 30 Minutes</button></li>
 		<li *ngIf="hasServices('Facials 50 Minutes')" (click)="changeTab('50')" [ngClass]="tab=='50' ? 'active' : ''"><button>Facials 50 Minutes</button></li>
 
		<li *ngIf="hasServices('eyebrows')" (click)="changeTab('eyebrows')" [ngClass]="tab=='eyebrows' ? 'active' : ''"><button>Eyebrows</button></li>
		<li *ngIf="hasServices('Microchanneling')" (click)="changeTab('microchanneling')" [ngClass]="tab=='microchanneling' ? 'active' : ''"><button>Microchanneling</button></li>
		<li *ngIf="hasServices('Chemical Peels')" (click)="changeTab('peels')" [ngClass]="tab=='peels' ? 'active' : ''"><button>Chemical Peels</button></li>
		<li *ngIf="hasServices('Injectables')" (click)="changeTab('injectables')" [ngClass]="tab=='injectables' ? 'active' : ''"><button>Injectables</button></li>
	 -->
	 <li *ngIf="hasServices('Facials 30 Minutes')" (click)="changeTab('30','Facials 30 Minutes')" [ngClass]="tab=='Facials 30 Minutes' ? 'active' : ''"><button>Facials 30 Minutes</button></li>
	 <li *ngIf="hasServices('Facials 50 Minutes')" (click)="changeTab('50','Facials 50 Minutes')" [ngClass]="tab=='Facials 50 Minutes' ? 'active' : ''"><button>Facials 50 Minutes</button></li>
	 
	 <li *ngIf="hasServices('eyebrows')" (click)="changeTab('eyebrows','Eyebrows')" [ngClass]="tab=='Eyebrows' ? 'active' : ''"><button>Eyebrows</button></li>
	 <li *ngIf="hasServices('Microchanneling')" (click)="changeTab('microchanneling','Microchanneling')" [ngClass]="tab=='Microchanneling' ? 'active' : ''"><button>Microchanneling</button></li>
	 <li *ngIf="hasServices('Chemical Peels')" (click)="changeTab('peels','Chemical Peels')" [ngClass]="tab=='Chemical Peels' ? 'active' : ''"><button>Chemical Peels</button></li>
	 <li *ngIf="hasServices('Injectables')" (click)="changeTab('injectables','Injectables')" [ngClass]="tab=='Injectables' ? 'active' : ''"><button>Injectables</button></li>

	
	</ul>
</div>