import { Injectable } from "@angular/core";
import { SharedService } from "../shared-component/shared.service";
import { GiftcardService } from "./giftcard.service";

@Injectable({
  providedIn: "root",
})
export class TrackingService {

  cart: any;

  constructor(private sharedService: SharedService, private giftcardService: GiftcardService) {
    this.giftcardService.clientCart$.subscribe((cart: any) => {
      this.cart = cart;
    })
  }
  /* 
    customizeCategoryName(name:string){
      name.includes('Facials ') ? name = name.replace('Facials ', '') : null;
      return name;
    } */

  addItem(item: any) {
    let data = {
      currency: "USD",
      value: item.listPrice / 100,
      items: [
        {
          item_id: item.id,
          item_name: item?.name,
          affiliation: "",
          coupon: "",
          discount: 0,
          index: 0,
          item_brand: "",
          item_category: this.cart.location.name,
          item_category2: 'Gift Card',
          item_category3: "Gift Card",
          item_category4: "",
          item_category5: "",
          item_list_id: "",
          item_list_name: "",
          item_variant: "",
          location_id:
            this.cart?.location?.id,
          price: item.listPrice / 100,
          quantity: 1,
        },
      ],
    };

    this.injectGTAG("add_to_cart", data);
  }

  removeItem(item: any) {
    let data = {
      currency: "USD",
      value: item.listPrice / 100,
      items: [
        {
          item_id: item.id,
          item_name: item?.name,
          affiliation: "",
          coupon: "",
          discount: 0,
          index: 0,
          item_brand: "",
          item_category: this.cart.location.name,
          item_category2: 'Gift Card',
          item_category3: "Gift Card",
          item_category4: "",
          item_category5: "",
          item_list_id: "",
          item_list_name: "",
          item_variant: "",
          location_id:
            this.cart?.location?.id,
          price: item.listPrice / 100,
          quantity: 1,
        },
      ],
    };

    this.injectGTAG("remove_from_cart", data);
  }

  initiateCheckout() {
    let items: Array<any> = [];
    if (this.cart && this.cart.selectedItems) {
      this.cart.selectedItems.map((item: any) => {
        let data = {
          item_id: item.item.id,
          item_name: item.name,
          affiliation: "",
          coupon: "",
          discount: 0,
          index: 0,
          item_brand: "",
          item_category: this.cart.location.name,
          item_category2: 'Gift Card',
          item_category3: "Gift Card",
          item_category4: "",
          item_category5: "",
          item_list_id: "",
          item_list_name: "",
          item_variant: "",
          location_id:
            this.cart?.location?.id,
          price: item.price / 100,
          quantity: 1,
        };
        items.push(data);
      })
      this.injectGTAG("begin_checkout", {
        currency: "USD",
        value: this.cart.summary.total / 100,
        coupon: "",
        items: items
      })
    }
  }

  purchase() {
    let items: Array<any> = [];
    this.cart.selectedItems.map((item: any) => {
      let data = {
        item_id: item.item.id,
        item_name: item.name,
        affiliation: "",
        coupon: this.cart?.offers[0]?.code ? this.cart?.offers[0]?.code : "",
        discount: this.cart?.summary?.discountAmount,
        index: 0,
        item_brand: "",
        item_category: this.cart.location.name,
        item_category2: "Gift Card",
        item_category3: "Gift Card",
        item_category4: "",
        item_category5: "",
        item_list_id: "",
        item_list_name: "",
        item_variant: "",
        location_id:
          this.cart?.location?.id,
        price: item.price / 100,
        quantity: 1,
      };
      items.push(data);
    })
    this.injectGTAG("purchase", {

      currency: "USD",
      value: this.cart.summary.total / 100,
      coupon: localStorage.getItem("promoCode") ? localStorage.getItem("promoCode") : "",
      items: items
    })
  }
  setUserDetail() { 
    let data = {
      "email": this.cart.clientInformation?.email,
      "phone_number": this.cart.clientInformation?.phoneNumber,
      "address": {
        "first_name": this.cart.clientInformation?.firstName,
        "last_name": this.cart.clientInformation?.lastName,
        "postal_code": '',
        "country": 'US'
      }
    }

    this.injectGTAG("set", {
      user_data: data
    })
  }

  injectGTAG(eventName: string, data: any) {
    (window as any).dataLayer.push({
      event: eventName,
      ecommerce: data
    })
    // (window as any).gtag("event", eventName, data);
  }
}
