import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { GiftcardComponent } from './giftcard.component';
import { GiftcardRoutingModule } from './giftcard.routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedComponentModule } from '../shared-component/shared-component.module';
import { DetailComponent } from './detail/detail.component';
import { ReviewComponent } from './review/review.component';
import { ThanksComponent } from './thanks/thanks.component';



@NgModule({
  declarations: [
    GiftcardComponent,
    DetailComponent,
    ReviewComponent,
    ThanksComponent,
    // EmailDesignComponent,
    // ReviewPurchaseComponent,
    // ThanksComponent,
    // PdfComponent
  ],
  imports: [
      GiftcardRoutingModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      NgxSkeletonLoaderModule,
      CommonModule,
      SharedComponentModule,
      NgxMaskDirective,
      NgxMaskPipe,
    ],
  providers: [provideNgxMask()],
  bootstrap: [GiftcardComponent]
})
export class GiftcardModule { }
