import { Component } from '@angular/core';
import { MembershipService } from '../membership.service';
import { SharedService } from 'src/app/shared-component/shared.service';
import { firstValueFrom } from 'rxjs';
import { TrackingService } from '../tracking.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss']
})
export class CompletedComponent {

  cart: any;

  constructor(private membershipService: MembershipService, private sharedService: SharedService,
    private trackingService: TrackingService, private router: Router) {
    //console.log("membershipService.completeCart$", membershipService.completeCart$.value);
    membershipService.completeCart$.subscribe((cart: any) => {
      if (cart) {
        this.cart = cart;
        this.saveMembershipDetail();
      }
      else {
        this.router.navigateByUrl("/membership", { replaceUrl: true });

      }
    });
    this.injectGTAG(this.cart.id);
  }

  injectGTAG(aptId: string) {
    this.trackingService.setUserDetail(this.cart);
    this.trackingService.purchase(this.cart);
    (window as any).gtag('event', 'conversion', {
      'send_to': 'AW-1056511307/uq4qCNDxjpECEMuq5PcD',
      'value': Number(this.sharedService.formatPrice(this.cart?.summary?.total)),
      'currency': 'USD',
      'transaction_id': aptId
    });
  }

  async saveMembershipDetail() {
    const billDate: any = localStorage.getItem('billDate');
    const payload = {
      "date": new Date().toISOString(),
      "name": this.cart.clientInformation.firstName + ' ' + this.cart.clientInformation.lastName,
      "email": this.cart.clientInformation.email,
      "plan": this.cart.selectedItems[0].item.name,
      "start_date": billDate,
      "location": this.cart.location.name
    }
    const saveMembershipRes: any = await firstValueFrom(this.membershipService.saveMembershipClientDetail(payload));
    if (saveMembershipRes.errors) {
      return this.sharedService.showNotification("Add Payment Method Error", saveMembershipRes.errors[0].message);
    }
    localStorage.removeItem('billDate');
  }

}
