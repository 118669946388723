import { Injectable } from "@angular/core";
import { SharedService } from "../shared-component/shared.service";

@Injectable({
  providedIn: "root",
})
export class TrackingService {

  constructor(private sharedService: SharedService) { }

  initiateCheckout(cart:any){
    let items:Array<any> = [];
    cart.selectedItems.map((item:any)=>{
        let data = {
            item_id: item.item.id,
            item_name: item.item.name,
            affiliation: "",
            coupon: "",
            discount: 0,
            index: 0,
            item_brand: "",
            item_category: cart.location.name,
            item_category2: this.sharedService.getServiceCategoryName(item, cart.availableCategories) == '' ? 'addon' : this.sharedService.getServiceCategoryName(item, cart.availableCategories),
            item_category3: "Memberships",
            item_category4: "",
            item_category5: "",
            item_list_id: "",
            item_list_name: "",
            item_variant: "",
            location_id:
                cart?.location?.id,
            price: item.price / 100,
            quantity: 1,
        };
        items.push(data);
    })
    this.injectGTAG("begin_checkout", {
      currency: "USD",
      value: cart.summary.total / 100,
      coupon: "",
      items: items
    })
  }

  purchase(cart:any){
    let items:Array<any> = [];
    cart.selectedItems.map((item:any)=>{
        let data = {
            item_id: item.item.id,
            item_name: item.item.name,
            affiliation: "",
            coupon: cart?.offers[0]?.code ? cart?.offers[0]?.code : "",
            discount: cart?.summary?.discountAmount ? cart?.summary?.discountAmount / 100 : 0,
            index: 0,
            item_brand: "",
            item_category: cart.location.name,
            item_category2: this.sharedService.getServiceCategoryName(item, cart.availableCategories) == '' ? 'addon' : this.sharedService.getServiceCategoryName(item, cart.availableCategories),
            item_category3: "Memberships",
            item_category4: "",
            item_category5: "",
            item_list_id: "",
            item_list_name: "",
            item_variant: "",
            location_id:
                cart?.location?.id,
            price: item.price / 100,
            quantity: 1,
        };
        items.push(data);
    })
    this.injectGTAG("purchase", {
      
      currency: "USD",
      value: cart.summary.total / 100,
      coupon: cart?.offers[0]?.code ? cart?.offers[0]?.code : "",
      items: items
  })
  }

  setUserDetail(cart:any){
    let data = {
      "email": cart.clientInformation.email, 
      "phone_number": cart.clientInformation.phoneNumber,
      "address": {
      "first_name": cart.clientInformation.firstName,
      "last_name": cart.clientInformation.lastName,
      "postal_code": '',
      "country": 'US'
      }
    }

    this.injectGTAG("set", {
      user_data: data
    })
  }

  injectGTAG(eventName: string, data: any) {
    (window as any).dataLayer.push({
      event: eventName,
      ecommerce: data
    })
    // (window as any).gtag("event", eventName, data);
  }
}