<app-header mainTitle="Book an Appointment"></app-header>

<section class="services-flow" [ngClass]="(cart?.guests?.length && isGuestTabVisible) ? 'has-guests' : '' ">
	<div class="container grid">
		<!--Breadcrumbs-->
        <app-breadcrumbs class="breadcrumbs-container"></app-breadcrumbs>
		<app-mobile-menu class="user-head mobile-head"></app-mobile-menu>
		<div class="content-container">
			<div class="guest-different-services" *ngIf="cart?.guests?.length" (click)="canAllowDifferentService()">
				<input [ngClass]="(!isSameServices && this.getGroupedServices.length == 1) ? 'disabled-input' : ''" 
				[(ngModel)]="!isSameServices" [checked]="!isSameServices"  (change)="showGuest()" id="guest-different" type="checkbox"/>
				<label for="guest-different">MY GUESTS & I WANT DIFFERENT ADD ONS</label>
			</div>
            <app-guest-tabs *ngIf="cart?.guests?.length && isGuestTabVisible" [serviceCount]="getMemberAddedAddonsCount" [guests]="cart.guests" (changeTabEvent)="changeGuestTab($event)"></app-guest-tabs>		
			<!--Mobile Tabs/Filters-->
		 <app-services-addons-tabs [reset]="resetServiceTabs" [client]="tabs.guest" [cart]="cart" (changeTabEvent)="changeServiceTab($event)">
		 </app-services-addons-tabs>
		 <app-addons-list 
		 [addons]="cart.selectedItems | addons:tabs.guest:orderingAddons | filterAddons:tabs.addons:tabs.guest" 
		 [client]="tabs.guest" 
		 [cart]="cart">
	   </app-addons-list>
	   
			   </div>
		<!--Sidebar-->
		
        <aside *ngIf="cart" [ngClass]="(bookingService.mobileCartView | async) ? 'active' : '' " [ngClass] = "!cart?.selectedItems?.length ? 'empty':'' ">
			<div class="sidebar-sticky-desktop-only">
				
				<app-cart [cart]="cart" [isSameService]="isSameServices" (onItemRemoveEvent)="itemRemovedFromCart()"></app-cart>
				<!-- <span (click)="continue()"><a class="btn active desktop-only">Skip / Continue</a></span>
				<span (click)="continue()" *ngIf="!cart?.selectedItems?.length">
					<a class="btn active view-cart mobile-only" aria-current="page" >Skip / Continue</a>
				</span>
				<span (click)="continue()" *ngIf="cart?.selectedItems?.length">
					<a class="btn active view-cart mobile-only" *ngIf="cart?.selectedItems?.length">Skip / Continue</a>
				</span> -->
			</div>
        </aside>
		
	</div>
</section>

<span class="fixed-continue-button" ><a (click)="continue()" class="btn active">Skip / Continue</a></span>
<!-- <app-footer></app-footer> --> 