<div class="inner-box">
    <div class="block">
        <p>Current balance</p>
        <p class="loyalty-number">{{loyaltyData?.client?.pointsBalance}}</p>
        <p class="small">Loyalty Points</p>
    </div>
    <div class="block">
        <p>Total points earned</p>
        <p class="loyalty-number">{{loyaltyData?.client?.pointsEarned}}</p>
        <p class="small">Joined {{loyaltyData?.client?.joinedAt | date:'MMM d, y'}}</p>
    </div>
    <div class="block">
        <p>Total points redeemed</p>
        <p class="loyalty-number">{{loyaltyData?.client?.pointsRedeemed}}</p>
        <p class="small" *ngIf="!loyaltyData?.client?.lastRedeemedAt">No points redeemed yet</p>
        <p class="small" *ngIf="loyaltyData?.client?.lastRedeemedAt">Last Redeemed {{loyaltyData?.client?.lastRedeemedAt | date:'MMM d, y'}}</p>
        <!-- <p>No points redeemed yet</p> -->
    </div>
</div>