<!-- <section class="client-portal membership"> -->
	<!-- <div class="container grid"> -->
		
		<!--Content-->
		<!-- <div class="content"> -->
			<!-- <h1 class="heading-4">Welcome back, Tamara!</h1> -->
			
			<div class="empty-membership">
				<h3 class="heading-6">You don’t have a current membership.</h3>
				
				<ul class="grid" *ngIf="products?.edges">
					<li *ngFor="let product of products?.edges">
						<div class="membership-plan">
							<span class="pill fill blue">{{product?.node?.name}}</span>
							<span class="heading-5">${{product?.node?.unitPrice / 100}}/month</span>
							<span class="price"><s *ngIf="product?.node?.barcode != (product?.node?.unitPrice / 100)">${{product?.node?.barcode}}/month</s></span>
							<p class="xx-small">{{product?.node?.description}}</p>
							
							<!-- <ul class="xx-small">
								<li>Includes one 30-minute Signature Facial per month (normally $99)</li>
								<li>One discounted facial per month</li>
								<li>10% off any additional facials</li>
							</ul> -->
							<span class="btn tertiary"><a href="tel:8886770055">Call (888) 677-0055</a></span>
						</div>
						
						<a class="btn black small" href="/membership/selection/{{product?.node?.name}}">Add to Cart</a>
					</li>

					<!-- <li>
						<div class="membership-plan">
							<span class="pill fill blue">30-Minute Membership</span>
							<span class="heading-5">$79/month</span>
							<span class="price"><s>$99/month</s> $20 Savings</span>
							<p class="xx-small">Includes one 30-minute Signature Facial per month (normally $99)</p>
							<span class="btn tertiary"><a href="tel:8886770055">Call (888) 677-0055</a></span>
						</div>
					</li>
					
					<li>
						<div class="membership-plan">
							<span class="pill fill blue">50-Minute Membership</span>
							<span class="heading-5">$124/month</span>
							<span class="price"><s>$155/month</s> $31 Savings</span>
							<p class="xx-small">Includes the choice of one 50-minute Acne-Fighting, Anti-Aging, Brightening, Sensitive or Seasonal facial per month (Normally $155)</p>
							<span class="btn tertiary"><a href="tel:8886770055">Call (888) 677-0055</a></span>
						</div>
					</li>

					<li>
						<div class="membership-plan">
							<span class="pill fill blue">Premium Membership</span>
							<span class="heading-5">$399/month</span>
							<span class="btn tertiary"><a href="tel:8886770055">Call (888) 677-0055</a></span>
						</div>
					</li> -->
				</ul>
			</div>
			
		<!-- </div> -->
		
	<!-- </div> -->
<!-- </section> -->