import { Pipe, PipeTransform } from '@angular/core';
import { BookingService } from '../booking.service';
import { SharedService } from 'src/app/shared-component/shared.service';

interface Addon {
  optionGroups: Array<{ name: string }>;
  [key: string]: any; // Allow for other properties
}

interface GroupedAddon {
  groupName: string;
  addons: Addon[];
}

@Pipe({
  name: 'filterAddons'
})
export class FilterAddonPipe implements PipeTransform {

  constructor(private bookingService: BookingService, private sharedService: SharedService) { }

  transform(addons: Addon[], filter: number, client: any) {
    if (filter >= 0 && Array.isArray(addons)) {
      return addons;
    } else {
      return [];
    }
  }
}
