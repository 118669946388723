export const GIFTCARD_NAMES = {
    "115":'30-Minute Facial',
    "155":'50-Minute Facial',
    "custom":'Gift Card'
}

export const ADDON_CATEGORIES = [
    "EXFOLIATION ENHANCEMENTS",
    "CUSTOM-FOR-ALL ENHANCEMENTS",
    "YOUTHFUL ENHANCEMENTS",
    "CHEMICAL PEELS",
    "EYEBROWS"
]

export const ADDON_CATEGORIES_ORDER = [
    {
      category: 'EXFOLIATION ENHANCEMENTS',
      order: 1,
    },
    {
      category: 'CUSTOM-FOR-ALL ENHANCEMENTS',
      order: 2,
    },
    {
      category: 'YOUTHFUL ENHANCEMENTS',
      order: 3,
    },
    {
      category: 'CHEMICAL PEELS',
      order: 4,
    },
    {
      category: 'EYEBROWS',
      order: 5,
    }
  ]