<app-header mainTitle="Book an Appointment"></app-header>


<main id="main">

<section class="whos-coming">
	<div class="container grid">
		
		<!--Breadcrumbs-->
        <app-breadcrumbs class="breadcrumbs-container"></app-breadcrumbs>

		<app-mobile-menu class="user-head mobile-head"></app-mobile-menu>
		
		<h2 class="eyebrow">Who should we expect?</h2>
		
		<ul class="grid">
			<li>
			  <button [ngClass]="tab === 'me' ? 'active' : ''" class="eyebrow" (click)="setGuestMe('me')">Just Me</button>
			</li>
			<li>
			  <button [ngClass]="tab === 'guest' && guestCount === 1 ? 'active' : ''" class="eyebrow" (click)="setGuestMe('guest1')">Me &amp; One Guest</button>
			</li>
			<li>
			  <button [ngClass]="tab === 'guest' && guestCount === 2 ? 'active' : ''" class="eyebrow" (click)="setGuestMe('guest2')">Me &amp; Two Guests</button>
			</li>
		  </ul>
		  
		<div class="guest-counter">
			<!-- <div *ngIf="tab == 'guest' ">
				<label class="pill" for="guest-count">How Many Guests?</label>
				<select id="guest-count" [(ngModel)]="guestCount" (change)="selectGuest()" #noOfguestSelected>
					<option [value]="1">1 Guest</option>
					<option [value]="2">2 Guests</option>
				</select>
			</div> -->
			<!-- <input  type="submit" class="btn" value="Continue" (click)="gotoServices()"> -->
		</div>
		
	</div>
</section>
<span class="fixed-continue-button"><input  type="submit" class="btn active" value="Continue" (click)="gotoServices()"></span>
</main>
<!-- <app-footer></app-footer> -->