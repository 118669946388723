import { Component, Input } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Observable, forkJoin } from 'rxjs';
import { BookingService } from 'src/app/booking/booking.service';
import { TrackingService } from 'src/app/booking/tracking.service';
import { SharedService } from 'src/app/shared-component/shared.service';

@Component({
  selector: 'app-modal-addon-detail',
  templateUrl: './modal-addon-detail.component.html',
  styleUrls: ['./modal-addon-detail.component.scss']
})
export class ModalAddonDetailComponent {

  @Input() addon:any;
  @Input() client:any;

  cart:any;
  cartSubscription:any;
  isSameService:any;

  constructor(private trackingService:TrackingService, public sharedService:SharedService, private bookingService:BookingService, public addonModalRef: MdbModalRef<ModalAddonDetailComponent>){
    this.isSameService = this.sharedService.getLocalStorageItem('isSameService');
    this.cartSubscription = this.bookingService.clientCart$.subscribe((cart:any)=>{
      if(cart){
        this.cart = cart;
      }
    });
  }

  addAddon(guestId:string|null){
    const payload = {
      id:this.addon.id,
      staffId:null,
      guestId:guestId
    }
    //this.bookingService.ngxUiLoaderService.start();
    this.bookingService.addItemInCart(payload).subscribe((res:any)=>{
      if(!res.errors){
        this.addon.category = 'addon';
        this.trackingService.addItem(this.addon);
        this.bookingService.getCartDetail().subscribe((res:any)=>{
          if(!res.errors){
            this.bookingService.clientCart$.next(res.data.cart);
          }
          this.addonModalRef.close();
          //this.bookingService.ngxUiLoaderService.stop();
        });
      }else{
        //this.bookingService.ngxUiLoaderService.stop();
        this.sharedService.showNotification('Errors', res.errors[0].message);
      }
    });
  }

  removeAddon(){
    let promises:any = [];
    let selectedAddons:any = [];
    if(this.isSameService != 'false'){
      selectedAddons = this.cart.selectedItems.filter((item:any)=>{
        return item.item.optionGroups.length &&   this.addon.name.toLowerCase() == item.item.name.toLowerCase()
      });
      
    }else{
      selectedAddons = this.cart.selectedItems.filter((item:any)=>{
        if(this.client != 'me'){
          return item.item.optionGroups.length &&   this.addon.name.toLowerCase() == item.item.name.toLowerCase() && item.guestId == this.client.id
        }else{
          return item.item.optionGroups.length &&   this.addon.name.toLowerCase() == item.item.name.toLowerCase() && item.guestId == null
        }
      });
    }

    if(selectedAddons.length){
      selectedAddons.forEach((addon:any) => {
        promises.push(this.bookingService.removeItemInCart(addon.id));
      });
      
      //this.bookingService.ngxUiLoaderService.start();
      forkJoin(promises).subscribe(()=>{
        this.bookingService.getCartDetail().subscribe((res:any)=>{
          if(!res.errors){
            this.bookingService.clientCart$.next(res.data.cart);
          }
          //this.bookingService.ngxUiLoaderService.stop();
          this.addonModalRef.close();
        });
      });
    }
  }

  addAddonForAll(){
    if(!this.addon.selected){
      if(this.canAddAddon){
        if(this.isSameService != 'false'){
          this.addAddon(null);
          let guests = this.cart.guests;
          guests.map((guest:any)=>{
            this.addAddon(guest.id);
          })
        }else{
          this.addAddon(this.client.id)
        }
      }else{
        const title = 'Add-on limit';
        const message = 'You can add up to 4 add-ons per user only!';
        this.sharedService.showNotification(title, message);
      }
    }else{
      this.removeAddon();
    }
  }

  get canAddAddon(){
    const selectedItemsWithoutAddons = this.cart.selectedItems.filter((item: any) => item.addons.length == 0 && !item.guestId);
    const selectedItemsWithoutAddonsGuest = this.cart.selectedItems.filter((item: any) => item.addons.length == 0 && this.client.id == item.guestId);
    const countMe = selectedItemsWithoutAddons.length;
    const countGuest = selectedItemsWithoutAddonsGuest.length;

    if(this.isSameService != "false"){
      return countMe < 4 ? true : false;
    }else{
      return countGuest < 4 ? true : false;
    }
  }
  
}
 