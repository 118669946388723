import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'staffFilter',
})
export class StaffFilterPipe implements PipeTransform {

  transform(value: any[], filterText: string): any[] {
    if (!value) {
      return value;
    }

    // Filter by enable first
    let enabledStaff = value.filter(staff => staff.enable);

    if (!filterText) {
      return enabledStaff;
    }

    const filterTextLower = filterText.toLowerCase();
    
    // Apply the text filter only to enabled staff
    const filteredStaffData = enabledStaff.filter((staff: any) => {
      return staff.firstName.toLowerCase().includes(filterTextLower) ||
        staff.displayName.toLowerCase().includes(filterTextLower);
    });

    return filteredStaffData;
  }

}
