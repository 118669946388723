import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'pastappointments'
})
export class UpcomingAppointmentsPipe implements PipeTransform {

  transform(value: Array<any>, activeTab:number): Array<any> {
    return value.length ? value.filter((val)=> {
      return (new Date(val.node.startAt) < new Date() || val.node.state == 'FINAL') && (activeTab == 1 ? val.node.appointmentServices[0].staff.role.name != 'Dietician' : val.node.appointmentServices[0].staff.role.name == 'Dietician')
    }).sort((a, b) => moment(b.node['startAt']).diff(moment(a.node['startAt']))) : value;
  }

}

@Pipe({
  name: 'upcomingappointments'
})
export class PastAppointmentsPipe implements PipeTransform {

  transform(value: Array<any>, activeTab:number): Array<any> {
    return value.length ? value.filter((val)=> {
      return (new Date(val.node.startAt) > new Date() && val.node.state != 'FINAL') && (activeTab == 1 ? val.node.appointmentServices[0].staff.role.name != 'Dietician' : val.node.appointmentServices[0].staff.role.name == 'Dietician')
  }).sort((a, b) => moment(a.node['startAt']).diff(moment(b.node['startAt']))) : value;
  }

}