import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from "src/environments";
import { BookingService } from '../booking/booking.service';
import { SharedService } from '../shared-component/shared.service';
import { AuthService } from '../auth/auth.service';

const BASE_URL = environment.apiBaseURL; 
const BLVD_PAYMENT_BASE_URL = environment.paymentApiBaseURL

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  public $memberships: BehaviorSubject<any> = new BehaviorSubject([]);
  clientCart$:BehaviorSubject<any> = new BehaviorSubject(null);
  completeCart$:BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient, private bookingService:BookingService, private sharedService:SharedService, private authService:AuthService) { }

  // create cart
  createCart(locationID?:string){
    const cartId = localStorage.getItem('membershipCart');
    if(!cartId){
      const payload = {
        locationID:locationID,
        client_id:this.authService.$AuthUser.value?.authId
      }; 
  
      this.http.post(BASE_URL+'/create_cart', payload).subscribe((res:any)=>{
        if(!res.errors){
          localStorage.setItem('membershipCart', res.data.createCart.cart.id);
          this.getCartDetail();
        }else{
          this.sharedService.showNotification("Error", res.errors[0].message);
        }
      });
    }else{
      if(!this.clientCart$.value){
        this.getCartDetail();
      }
    }
  }

  newCart(locationID?:string){
    const cartId = localStorage.getItem('membershipCart');
    const payload = {
      locationID:locationID,
      client_id:this.authService.$AuthUser.value?.authId
    }; 

    return this.http.post(BASE_URL+'/create_cart', payload);
  }

  cartDetail(){
    const payload = {
      cartID: localStorage.getItem('membershipCart'),
      clientId: this.authService.$AuthUser.value?.authId
    }; 
    return this.http.post(BASE_URL+'/get_cart_detail',payload);
  }

  getCartDetail(){
    const cartId = localStorage.getItem('membershipCart');
    if(cartId){
      this.cartDetail().subscribe((res:any)=>{
        if(!res.errors){
          this.clientCart$.next(res.data.cart);
        }else{
          this.sharedService.showNotification("Error", res.errors[0].message);
        }
      });
    }
  }

  getMemberships() {
    return this.http.get(BASE_URL + "/memberships").subscribe((res: any) => {
      let memberships = res.data.products.edges;
      memberships.sort((a: any, b: any) => parseInt(a.node.barcode) < parseInt(b.node.barcode) ? -1 : 1);

      memberships = memberships.map(
        (m: any) => {
          if (parseInt(m.node.barcode) * 100 != m.node.price) {
            return { node: {...m.node, originalPrice: parseInt(m.node.barcode) * 100 }} // If the barcode differs from the price, treat it as the original price
          }
          return { node: {...m.node, originalPrice: m.node.price }};
        }
      );
      memberships.sort((a:any, b:any) => +a.node.size - +b.node.size);
      this.$memberships.next(memberships);
    });
  }

  addProductToCart(itemID:any){
    const payload = {
      "cartId": this.sharedService.getLocalStorageItem('membershipCart'),
      "itemId": itemID,
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL+'/add_product_to_cart',payload);
  }

  removeItemInCart(itemId:string){
    const payload = {
      "cartId": localStorage.getItem('membershipCart'),
      "itemId": itemId
    }
    return this.http.post(BASE_URL+'/remove_item_in_cart',payload);
  }

  setCartLocation(locationID:string){
    const payload = {
      "cartID": this.sharedService.getLocalStorageItem('membershipCart'),
      "locationID": locationID,
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL+'/set_cart_location',payload);
  }

  selectPaymentMethod(paymentMethodId:string){
    const payload = {
      "cartId":this.sharedService.getLocalStorageItem('membershipCart'),
      "paymentMethodId":paymentMethodId,
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL+ '/select_cart_payment_method',payload);
  }

  generateAgreement(data: {
    firstName: string,
    lastName: string,
    phoneNumber: string,
    email: string,
    membershipChargeDate: string,
    facialTypeChoice: string,
    initials: string,
  }) {
    const payload = {
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phoneNumber,
      email: data.email,
      membership_charge_date: data.membershipChargeDate,
      facial_type_choice: data.facialTypeChoice,
      initials: data.initials,
    };
    return this.http.post(BASE_URL + "/agreement", payload);
  }

  saveMembershipClientDetail(data: {
    "date":string,
    "name":string,
    "email":string,
    "plan":string,
    "start_date":string,
    "location":string
  }) {
    return this.http.post(BASE_URL + "/save_membership_detail", data);
  }

}
