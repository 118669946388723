import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ConfirmationAlertComponent } from '../confirmation-alert/confirmation-alert.component';
import { Router } from '@angular/router';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-location-confirmation-alert',
  templateUrl: './location-confirmation-alert.component.html',
  styleUrl: './location-confirmation-alert.component.scss'
})
export class LocationConfirmationAlertComponent {


  @Input() message:string = 'Are you sure, You do not want to add ADD-ON ?';
  @Output() closeEvent = new EventEmitter<string>();
  @Output() continueEvent = new EventEmitter<string>();
  @Output() closeAndContinueEvent = new EventEmitter<any>(); // Add this line


  constructor(public confirmationModalRef: MdbModalRef<ConfirmationAlertComponent>, 
    private modalService: MdbModalService,private router:Router, public sharedService:SharedService,){}

  close(){
    this.confirmationModalRef.close(false);
  }
  async closeandContinue(){
   /*  //this.bookingService.ngxUiLoaderService.start();
    await this.bookingService.changeLocation(locationId);
    //this.bookingService.ngxUiLoaderService.stop();
    this.confirmationModalRef.close(false);
    await this.router.navigateByUrl('/booking/whoscoming'); */
    this.sharedService.keep_my_cart_and_continue=true;

    this.confirmationModalRef.close(false);
  }

  continue(){
    this.confirmationModalRef.close(true);
  }

}
